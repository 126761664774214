import {
  EstimateCreateRequest,
  EstimateCreateResponse,
  EstimateUpdateRequest,
  EstimateUpdateResponse,
  EstimateUpdateDisplayAmountRequest,
  EstimateUpdateDisplayAmountResponse,
  EstimateUpdateTaxTypeRequest,
  EstimateUpdateTaxTypeResponse,
  EstimateDestroyResponse,
  EstimateResponse,
  ProjectEstimateId,
} from "./estimate.dto";
import { ApiClient } from "../../../ApiClient";
import { ProjectId } from "../project.dto";

export const estimateRepository = {
  index(id: ProjectId): Promise<EstimateResponse[]> {
    return ApiClient.get(`/api/v1/projects/${id}/estimates`).then((res) => res.data);
  },
  create(projectId: ProjectId, body: EstimateCreateRequest): Promise<EstimateCreateResponse> {
    const config = { headers: { "content-type": "multipart/form-data" } };

    const formData = new FormData();
    for (const estimate of body) {
      formData.append("files[]", estimate);
    }

    return ApiClient.post(`/api/v1/projects/${projectId}/estimates`, formData, config).then(
      (res) => res.data,
    );
  },
  update(
    projectId: ProjectId,
    id: ProjectEstimateId,
    body: EstimateUpdateRequest,
  ): Promise<EstimateUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      status_type: body.statusType,
      user_id: body.userId,
    };
    return ApiClient.put(`/api/v1/projects/${projectId}/estimates/${id}`, requestBody, config).then(
      (res) => res.data,
    );
  },
  updateAmount(
    projectId: ProjectId,
    id: ProjectEstimateId,
    body: EstimateUpdateDisplayAmountRequest,
  ): Promise<EstimateUpdateDisplayAmountResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      display_amount: body.displayAmount,
    };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/estimates/${id}/amount`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  updateTaxType(
    projectId: ProjectId,
    id: ProjectEstimateId,
    body: EstimateUpdateTaxTypeRequest,
  ): Promise<EstimateUpdateTaxTypeResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      tax_type: body.taxType,
    };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/estimates/${id}/tax_type`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: ProjectEstimateId): Promise<EstimateDestroyResponse> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/estimates/${id}`).then(
      (res) => res.data,
    );
  },
  excelFilePreview(projectId: ProjectId, id: ProjectEstimateId): Promise<{ url: string }> {
    return ApiClient.get(`/api/v1/projects/${projectId}/estimates/${id}/excel_file_preview`).then(
      (res) => res.data,
    );
  },
};
