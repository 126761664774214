import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { CalendarEvent } from "types/calendarEvent";
import { usersName } from "./usersName";

export const scheduleTitle = (name: string, display_name: string, users: User[]) => {
  // 予定の名前がない場合は、参加者を表示
  if (users.length) {
    return `${display_name} ${name === "" ? usersName(users) : ""}`;
  } else {
    return display_name;
  }
};

export const convertScheduleToEvent = (schedule: ScheduleList): CalendarEvent => {
  return {
    id: schedule.id.toString(),
    resourceIds: !schedule.users.length
      ? ["0"]
      : schedule.users.map((user) => {
          return user.id.toString();
        }),
    title: scheduleTitle(schedule.name, schedule.display_name, schedule.users),
    note: schedule.note,
    start: schedule.start_time,
    end: schedule.end_time,
    textColor: schedule.is_confirmed
      ? theme.palette.grayScale[0]
      : `#${schedule.schedule_type.color_number}`,
    display: "block",
    isConfirmed: schedule.is_confirmed,
    backgroundColor: schedule.is_confirmed
      ? `#${schedule.schedule_type.color_number}`
      : theme.palette.grayScale[0],
    borderColor: `#${schedule.schedule_type.color_number}`,
    isHoliday: schedule.schedule_type.is_holiday,
    recurrenceRule: schedule.recurrence_rule,
  };
};
