import { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "extensions/theme";
import { Daily } from "./daily";
import { Monthly } from "./monthly";

const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-left: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
    & .MuiButtonBase-root.MuiTab-root {
      color: ${theme.palette.primary.main};
      width: 160px;
      border-top: 1px solid ${theme.palette.primary.main};
      border-right: 1px solid ${theme.palette.primary.main};
    }
    & .MuiButtonBase-root.MuiTab-root.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.grayScale[0]};
    }
  }
`;
type TabType = "daily" | "monthly";

export const Attendance = () => {
  const [tab, setTab] = useState<TabType>("daily");

  return (
    <>
      <Box>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: "20px" }}>打刻管理</Typography>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => {
            setTab(newValue);
          }}
        >
          <Tab value="daily" label="日ごと" />
          <Tab value="monthly" label="月ごと" />
        </CustomTabs>

        <Box sx={{ mt: "20px" }}>
          {tab === "daily" && <Daily />}

          {tab === "monthly" && <Monthly />}
        </Box>
      </Box>
    </>
  );
};
