import { useMemo } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Close, ArrowForwardIos, FileDownloadOutlined } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReplayIcon from "@mui/icons-material/Replay";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Dialog, Box, IconButton, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import { selectPhotosPreviewModal } from "store/photos-preview-modal/slice";
import { fileDownload } from "utils/fileDownload";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { theme } from "../../../extensions/theme";

const SIconButton = styled(IconButton)({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grayScale[0],
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

export const PhotoPreviewModal = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectPhotosPreviewModal);
  const classes = styles();

  const currentPhoto: PhotoResponse = useMemo(() => {
    return state.photos[state.currentIndex];
  }, [state.photos, state.currentIndex]);

  const handleClose = () => {
    dispatch(photosPreviewModalOperations.close());
  };

  const handleClickArrow = (num: -1 | 1) => {
    dispatch(photosPreviewModalOperations.updateCurrentIndex(state.currentIndex + num));
  };

  return (
    <Dialog open={state.isOpen} onClose={() => handleClose()} className={classes.modal}>
      {currentPhoto && (
        <>
          <Box className={classes.title}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mr: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "500" }}>
                {currentPhoto.photo_type.value_i18n}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  startIcon={<FileDownloadOutlined />}
                  variant="contained"
                  onClick={() => fileDownload(currentPhoto.file_url, currentPhoto.file_name)}
                  sx={{ height: "34px", borderRadius: "16px", py: 1, px: 2, fontSize: "12px" }}
                >
                  写真をダウンロード
                </Button>
              </Box>
            </Box>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              mt: "8px",
            }}
          >
            <Box sx={{ width: "71px", textAlign: "center" }}>
              {state.currentIndex !== 0 && (
                <IconButton onClick={() => handleClickArrow(-1)}>
                  <ArrowBackIosNewIcon
                    style={{ color: theme.palette.text.primary }}
                    fontSize="large"
                  />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                "& .react-transform-wrapper": { margin: "0 auto" },
              }}
            >
              {currentPhoto && (
                <>
                  {/* https://www.npmjs.com/package/react-zoom-pan-pinch */}
                  <TransformWrapper wheel={{ smoothStep: 0.002 }}>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            zIndex: 1,
                            marginTop: "4px",
                            marginLeft: "4px",
                            gap: "8px",
                          }}
                        >
                          <SIconButton onClick={() => zoomIn()}>
                            <ZoomInIcon />
                          </SIconButton>
                          <SIconButton onClick={() => zoomOut()}>
                            <ZoomOutIcon />
                          </SIconButton>
                          <SIconButton onClick={() => resetTransform()}>
                            <ReplayIcon />
                          </SIconButton>
                        </div>
                        <TransformComponent>
                          <img
                            alt="currentPhoto"
                            src={currentPhoto.file_url}
                            style={{
                              maxWidth: "100%",
                              height: "75vh",
                              userSelect: "none",
                              borderRadius: "4px",
                              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                              objectFit: "contain",
                            }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </>
              )}
            </Box>
            <Box sx={{ width: "71px", textAlign: "center" }}>
              {state.currentIndex !== state.photos.length - 1 && (
                <IconButton onClick={() => handleClickArrow(1)}>
                  <ArrowForwardIos style={{ color: theme.palette.text.primary }} fontSize="large" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {currentPhoto.record_type === "project" && (
              <>
                <Typography fontSize={14} className={classes.name}>
                  {currentPhoto.project_attributes?.name}
                </Typography>
                <Typography fontSize={14} sx={{ color: theme.palette.grayScale[700] }}>
                  {formatDateUtil(new Date(currentPhoto.created_at), DATE_TIME_SLASH_FORMAT)}{" "}
                  アップロード
                </Typography>
              </>
            )}
            {currentPhoto.record_type === "work_report" && (
              <>
                <Typography fontSize={14} className={classes.name}>
                  {currentPhoto.work_report_attributes?.site_name}
                </Typography>
                <Typography fontSize={14} sx={{ color: theme.palette.grayScale[700] }}>
                  {timeRange(
                    "yyyy年MM月dd日 HH:mm",
                    currentPhoto.work_report_attributes?.start_time.toString(),
                    currentPhoto.work_report_attributes?.end_time.toString(),
                  )}
                </Typography>
              </>
            )}
          </Box>
        </>
      )}
    </Dialog>
  );
};
