import {
  ProjectIndexRequest,
  ProjectIndexResponse,
  ProjectCreateResponse,
  ProjectShowResponse,
  ProjectUpdateRequest,
  ProjectUpdateResponse,
  ProjectDestroyResponse,
  ProjectDuplicateResponse,
  ProjectCreateRequest,
  ProjectId,
  ProjectCollection,
  ProjectDownloadRequest,
} from "./project.dto";
import { ApiClient } from "../../ApiClient";

type BillingConditionType =
  | "all_not_yet_bill"
  | "all_billed"
  | "partially_not_yet_bill"
  | "managing_billing_on_another_system"
  | "no_bill"
  | undefined;

export const projectRepository = {
  index(query: ProjectIndexRequest): Promise<ProjectIndexResponse> {
    const params = {
      project_type_ids: query.projectTypeIds,
      project_status_type_ids: query.projectStatusTypeIds,
      start_inquired_date: query.startInquiredDate,
      end_inquired_date: query.endInquiredDate,
      start_ordered_date: query.startOrderedDate,
      end_ordered_date: query.endOrderedDate,
      start_completed_date: query.startCompletedDate,
      end_completed_date: query.endCompletedDate,
      keyword: query.keyword,
      billing_conditions: query.billingConditions as BillingConditionType,
      sort: query.sort,
      direction: query.direction,
      page: query.page || 1,
      limit: query.rowsPerPage,
    };
    return ApiClient.get("/api/v1/projects", { params }).then((res) => res.data);
  },
  create(body: ProjectCreateRequest): Promise<ProjectCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/projects", createFormData(body), config).then((res) => res.data);
  },
  show(id: ProjectId): Promise<ProjectShowResponse> {
    return ApiClient.get(`/api/v1/projects/${id}`).then((res) => res.data);
  },
  update(id: ProjectId, body: ProjectUpdateRequest): Promise<ProjectUpdateResponse> {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return ApiClient.put(`/api/v1/projects/${id}`, createFormData(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ProjectId): Promise<ProjectDestroyResponse> {
    return ApiClient.delete(`/api/v1/projects/${id}`).then((res) => res.data);
  },
  createPhotoUrlKey(id: ProjectId): Promise<string> {
    return ApiClient.post(`/api/v1/projects/${id}/photo_url_key`).then((res) => res.data);
  },
  deletePhotoUrlKey(id: ProjectId): Promise<string> {
    return ApiClient.delete(`/api/v1/projects/${id}/photo_url_key`).then((res) => res.data);
  },
  duplicate(id: ProjectId): Promise<ProjectDuplicateResponse> {
    return ApiClient.post(`/api/v1/projects/${id}/duplicate`).then((res) => res.data);
  },
  download(query: ProjectDownloadRequest): Promise<ProjectCollection[]> {
    const params = {
      project_type_ids: query.projectTypeIds,
      project_status_type_ids: query.projectStatusTypeIds,
      start_inquired_date: query.startInquiredDate,
      end_inquired_date: query.endInquiredDate,
      start_ordered_date: query.startOrderedDate,
      end_ordered_date: query.endOrderedDate,
      start_completed_date: query.startCompletedDate,
      end_completed_date: query.endCompletedDate,
      keyword: query.keyword,
      billing_conditions: query.billingConditions as BillingConditionType,
    };
    return ApiClient.get("/api/v1/projects/all", { params }).then((res) => res.data);
  },
};

const createFormData = (body: ProjectUpdateRequest) => {
  const formData = new FormData();
  if (body.clientId !== undefined) {
    if (body.clientId === 0) {
      formData.append("client_id", "");
    } else {
      formData.append("client_id", body.clientId.toString());
    }
  }
  if (body.buildingId !== undefined) {
    if (body.buildingId === 0) {
      formData.append("building_id", "");
    } else {
      formData.append("building_id", body.buildingId.toString());
    }
  }
  body.billingPrecaution !== undefined &&
    formData.append("billing_precaution", body.billingPrecaution);
  if (body.managerId && body.managerId > 0) {
    formData.append("manager_id", body.managerId.toString());
  }
  if (body.inquiredById && body.inquiredById > 0) {
    formData.append("inquired_by_id", body.inquiredById.toString());
  }
  body.name !== undefined && formData.append("name", body.name);
  body.personName !== undefined && formData.append("person_name", body.personName);
  body.projectTypeId && formData.append("project_type_id", body.projectTypeId.toString());
  body.requester !== undefined && formData.append("requester", body.requester!);
  body.requestedByPersonName !== undefined &&
    formData.append("requested_by_person_name", body.requestedByPersonName!);
  body.projectStatusTypeId &&
    formData.append("project_status_type_id", body.projectStatusTypeId.toString());
  body.postalCode !== undefined && formData.append("postal_code", body.postalCode);
  body.address !== undefined && formData.append("address", body.address);
  body.addressSecond !== undefined && formData.append("address_second", body.addressSecond);
  body.phoneNumber !== undefined && formData.append("phone_number", body.phoneNumber);
  body.phoneNumberSecond !== undefined &&
    formData.append("phone_number_second", body.phoneNumberSecond);
  body.faxNumber !== undefined && formData.append("fax_number", body.faxNumber);
  body.email !== undefined && formData.append("email", body.email);
  body.note !== undefined && formData.append("note", body.note);
  body.reportContent !== undefined && formData.append("report_content", body.reportContent);
  body.inquiredDate !== undefined && formData.append("inquired_date", body.inquiredDate);
  body.orderedDate !== undefined && formData.append("ordered_date", body.orderedDate);
  body.expectedCompleteDate !== undefined &&
    formData.append("expected_complete_date", body.expectedCompleteDate);
  body.completedDate !== undefined && formData.append("completed_date", body.completedDate);
  body.code !== undefined && formData.append("code", body.code.toString());
  body.inquiryNumber !== undefined &&
    formData.append("inquiry_number", body.inquiryNumber.toString());
  body.isManageBillingOnAnotherSystem !== undefined &&
    formData.append(
      "is_manage_billing_on_another_system",
      body.isManageBillingOnAnotherSystem.toString(),
    );
  body.isNoBill !== undefined && formData.append("is_no_bill", body.isNoBill.toString());
  body.isSupportedByMaker !== undefined &&
    formData.append("is_supported_by_maker", body.isSupportedByMaker.toString());
  body.salesAmount !== undefined && formData.append("sales_amount", body.salesAmount.toString());
  body.tax !== undefined && formData.append("sales_tax", body.tax.toString());

  return formData;
};
