import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    addPhotoBox: {
      backgroundColor: theme.palette.grayScale[0],
      cursor: "pointer",
      position: "relative",
      p: "1rem",
      overflow: "hidden",
      width: "215px",
      height: "7.5rem",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      margin: "0 1rem",
      borderRadius: "4px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    dropzone: {
      position: "relative",
      backgroundColor: theme.palette.customPrimary[100],
      color: theme.palette.primary.main,
      "& .MuiIconButton-root": {
        position: "absolute",
        right: 0,
        zIndex: 100,
      },
      width: "100%",
      borderRadius: "4px",
    },
    photoBlock: {
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "1rem",
        width: "22%",
        position: "relative",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        width: "250px",
        position: "relative",
      },
    },
  }),
);
