import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import JSZip from "jszip";

// 画像の一括ダウンロード
export const downloadImages = async (
  sources: { url: string; name: string }[],
  folderName: string,
) => {
  const zip = new JSZip();
  const folder = zip.folder(folderName) || zip;
  const fileNamesCount: { [key: string]: number } = {};

  for (const { url, name } of sources) {
    const blob = await fetchImageAsBlob(url);
    if (blob) {
      const fileName = generateFileName(name, fileNamesCount[name] || 0);
      fileNamesCount[name] = (fileNamesCount[name] || 0) + 1;
      folder.file(fileName, blob);
    }
  }

  await generateAndDownloadZip(zip, folderName);
};

// キーに基づいて分類された画像ソースからZIPファイルを生成してダウンロードする
export const downloadImageAll = async (
  photos: { [key: string]: PhotoResponse[] },
  folderName: string,
) => {
  const zip = new JSZip();
  for (const [key, photoArray] of Object.entries(photos)) {
    const folder = zip.folder(key) || zip;
    const fileNamesCount: { [key: string]: number } = {};

    for (const { file_url, file_name } of photoArray) {
      const blob = await fetchImageAsBlob(file_url);
      if (blob) {
        const fileName = generateFileName(file_name, fileNamesCount[file_name] || 0);
        fileNamesCount[file_name] = (fileNamesCount[file_name] || 0) + 1;
        folder.file(fileName, blob);
      }
    }
  }

  await generateAndDownloadZip(zip, folderName);
};

// 画像をBlobとして取得する共通関数
const fetchImageAsBlob = async (url: string): Promise<Blob | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    return await response.blob();
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

// ファイル名の重複を回避するためのファイル名を生成する関数
const generateFileName = (originalName: string, count: number): string => {
  if (count === 0) return originalName;
  const dotIndex = originalName.lastIndexOf(".");
  if (dotIndex === -1) return `${originalName}(${count})`;
  return `${originalName.substring(0, dotIndex)}(${count})${originalName.substring(dotIndex)}`;
};

// ZIP ファイルの生成とダウンロード
const generateAndDownloadZip = async (zip: JSZip, folderName: string) => {
  const blob = await zip.generateAsync({ type: "blob" });
  const dataUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = dataUrl;
  link.download = `${folderName}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => URL.revokeObjectURL(dataUrl), 1000);
};
