import { ElementRef, useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { materialCostRepository } from "features/cost/api/material_cost.repository";
import {
  MaterialCostId,
  MaterialCostUpdateRequest,
  initialMaterialCostFormRequest,
} from "features/cost/types/material_cost.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
  materialCostId: MaterialCostId;
  name: string;
  state: MaterialCostUpdateRequest;
  fetchIndexKey: string;
  confirmRef: React.RefObject<ElementRef<typeof AsyncConfirmDialog>>;
}
export const MaterialCostEditModal = (props: Props) => {
  const { isOpen, onClose, projectId, materialCostId, name, state, fetchIndexKey, confirmRef } =
    props;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<MaterialCostUpdateRequest>({
    amount: 0,
    note: "",
  });

  useEffect(() => {
    if (!isOpen) return;
    setFormState({
      amount: state.amount,
      note: state.note,
    });
  }, [isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await materialCostRepository.update(projectId, materialCostId, formState);
      handleClose();
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("保存しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    if (!confirmRef.current) return;
    const res = await confirmRef.current.confirm();
    if (res) {
      try {
        await materialCostRepository.destroy(projectId, materialCostId);
        mutate(fetchIndexKey);
        handleClose();
        dispatch(mainOperations.updateSuccessMessage("削除しました"));
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const handleClose = () => {
    setErrorMessage("");
    setFormState(initialMaterialCostFormRequest);
    onClose();
  };

  const isValid = (): boolean => {
    if (!formState.amount) return false;
    return true;
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      title="仕入先の編集"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "160px" }}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            color="error"
            sx={{ width: "160px", ml: "16px" }}
          >
            削除
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "160px", ml: "16px" }}
          >
            保存
          </Button>
        </>
      }
    >
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel
          labelName="仕入先名"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
        />
        <Typography
          sx={{
            fontSize: "14px",
            width: "100%",
            bgcolor: theme.palette.primary.light,
            borderRadius: "4px",
            color: theme.palette.grayScale[700],
            p: "8px",
          }}
        >
          {name}
        </Typography>
      </div>

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel
          labelName="金額"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
          required
          requiredSize="14px"
        />
        <AmountTextField
          name="amount"
          width="100%"
          height="inherit"
          value={formState.amount}
          onChange={handleChange}
          step={100}
          isBgWhite
        />
      </div>
      <div>
        <CustomFormLabel
          labelName="メモ"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
        />
        <TextField
          name="note"
          value={formState.note}
          onChange={handleChange}
          sx={{ width: "100%" }}
          placeholder="メモを入力"
          multiline
          rows={3}
        />
      </div>
    </CustomModal>
  );
};
