import { ChangeEvent, ReactNode, useMemo, useState } from "react";
import { Range } from "react-date-range";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { GridRenderCellParams, GridRowParams, GridColDef } from "@mui/x-data-grid-pro";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { CustomDateRangePicker } from "components/molecules/custom-date-range-picker";
import { SearchTextField } from "components/molecules/search-text-field";
import { User, UserId } from "data-access/repositories/user/user.dto";
import {
  WorkReportId,
  WorkReportIndexResponse,
} from "data-access/repositories/work_report/work_report.dto";
import { theme } from "extensions/theme";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { ALL_USERS_VALUE } from "utils/constant";
import { usersName } from "utils/usersName";

const CustomizedTableHeader = styled(Box)({
  "& .MuiInputBase-root": {
    height: "1.928rem",
    fontSize: "0.857rem",
    width: "13rem",
    color: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.primary.main} `,
    opacity: ".5",
  },
});

interface Props {
  isLoading: boolean;
  setShow: (e: { isOpen: boolean; id: WorkReportId | undefined }) => void;
  page: number;
  setPage: (e: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (e: number) => void;
  keyword: string;
  setKeyword: (e: string) => void;
  data: WorkReportIndexResponse | undefined;
  dateRange: Range;
  dateLabel: string;
  setDateRange: (e: Range) => void;
  userIds: UserId[];
  onChangeForUserIds: (e: SelectChangeEvent<UserId[]>, child: ReactNode) => void;
  onSubmit: () => void;
  fetchIndexKey: string;
  rowsPerPageLocalStorageKey: string;
}

export const WorkReportTable = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();
  const orderLocalStorageKey = "workReportTableColumnOrder";
  const visibilityLocalStorageKey = "workReportTableColumnVisibility";
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const columns = useMemo(() => {
    const defaultHeaders: GridColDef[] = [];
    defaultHeaders.push({
      field: "working_date",
      headerName: "作業日時",
      minWidth: 250,
      sortable: true,
    });
    defaultHeaders.push({
      field: "project_code",
      headerName: "案件番号",
      minWidth: 150,
      sortable: false,
    });
    defaultHeaders.push({
      field: "project_name",
      headerName: "案件名",
      minWidth: 250,
      sortable: false,
    });
    defaultHeaders.push({
      field: "site_name",
      headerName: "作業日報名",
      minWidth: 250,
      sortable: false,
    });
    defaultHeaders.push({
      field: "site_manager",
      headerName: "現場責任者",
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<User>) => <>{params.value?.name}</>,
      sortable: false,
    });
    defaultHeaders.push({
      field: "users",
      headerName: "参加者",
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<User[]>) => (
        <Typography sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
          {usersName(params.value!)}
        </Typography>
      ),
      sortable: false,
    });
    defaultHeaders.push({
      field: "work_status_type_i18n",
      headerName: "作業の進捗",
      minWidth: 130,
      renderCell: (params: GridRenderCellParams) => <StatusTypeLabel statusType={params.value!} />,
      sortable: false,
    });

    const columnOrderItem = localStorage.getItem(orderLocalStorageKey);
    const visibilityItem = localStorage.getItem(visibilityLocalStorageKey);
    visibilityItem && setColumnVisibilityModel(JSON.parse(visibilityItem));

    if (columnOrderItem === null) {
      return defaultHeaders;
    } else {
      const headers: GridColDef[] = [];
      const orderColumns: [{ field: string; hide: boolean }] = JSON.parse(columnOrderItem);
      orderColumns.forEach((column) => {
        const index = defaultHeaders.findIndex((header) => {
          return header.field === column.field;
        });
        if (index > -1) {
          const header = defaultHeaders.splice(index, 1)[0];
          headers.push(header);
        }
      });
      if (defaultHeaders.length > 0) {
        headers.push(...defaultHeaders);
      }
      return headers;
    }
  }, []);

  const handleRowClick = (params: GridRowParams) => {
    props.setShow({ isOpen: true, id: params.id as WorkReportId });
    navigate(`/work-reports/${params.id}`);
  };

  const handleSelectPage = (event: SelectChangeEvent<unknown>) => {
    props.setPage(Number(event.target.value));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    props.setRowsPerPage(Number(event.target.value));
    localStorage.setItem(props.rowsPerPageLocalStorageKey, event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setKeyword(event.target.value as string);
  };

  const isSelectedForUserIds = (id: UserId): UserId | undefined => {
    return props.userIds.find((userId) => userId === id);
  };
  const filteredForUserName = (): string => {
    if (props.userIds[0] === ALL_USERS_VALUE) return "すべての参加者";
    return mainState.users
      .filter((user) => isSelectedForUserIds(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(orderLocalStorageKey, JSON.stringify(columns));
  };

  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(visibilityLocalStorageKey, JSON.stringify(model));
  };

  return (
    <>
      <CustomizedTableHeader sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="flex-start" gap="1rem">
            <CustomDateRangePicker
              sx={{ mb: "0.5rem", mr: "1rem" }}
              dateName="すべての作業日時"
              dateRange={props.dateRange}
              dateRangeLabel={props.dateLabel}
              handleChangeDateRange={props.setDateRange}
            />

            <Select
              id="userIds"
              name="userIds"
              value={
                props.userIds.length === 1 && props.userIds[0] === ALL_USERS_VALUE
                  ? mainState.users.map((user) => user.id)
                  : props.userIds
              }
              onChange={props.onChangeForUserIds}
              onClose={() => mutate(props.fetchIndexKey)}
              multiple
              renderValue={filteredForUserName}
              MenuProps={{
                autoFocus: false,
              }}
              sx={{ minWidth: "10rem", mr: "1rem", mb: "0.5rem" }}
            >
              <MenuItem value={ALL_USERS_VALUE} disabled={props.userIds[0] === ALL_USERS_VALUE}>
                すべての参加者
              </MenuItem>
              {mainState.users.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {isSelectedForUserIds(user.id) || props.userIds[0] === ALL_USERS_VALUE ? (
                      <CheckIcon color="primary" sx={{ mr: 1 }} />
                    ) : (
                      <Box sx={{ px: 1.5, mr: 1 }} />
                    )}
                    {user.name}
                  </MenuItem>
                );
              })}
            </Select>

            <form
              onSubmit={(e) => {
                props.onSubmit();
                e.preventDefault();
              }}
            >
              <SearchTextField
                id="keyword"
                name="keyword"
                value={props.keyword}
                onChange={handleChange}
                onSubmit={props.onSubmit}
              />
            </form>
          </Grid>
        </Box>
      </CustomizedTableHeader>
      <Box sx={{ mt: 2 }}>
        <CustomDataGridPro
          type="workReport"
          columns={columns}
          rows={props.data?.data || []}
          loading={props.isLoading}
          pageInfo={props.data?.pagination || { page: 1, pages: 1, items: 1, count: 1 }}
          currentPage={props.page}
          handleSelectPage={handleSelectPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRowClick={handleRowClick}
          tableHeight="80vh"
          handleColumnOrderChange={handleColumnOrderChange}
          handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Box>
    </>
  );
};
