import { ApiClient } from "data-access/ApiClient";
import { ProjectBillingId } from "data-access/repositories/project/project_billing/project_billing.dto";
import { ProjectBillingIndexResponse, ProjectBillingUpdateResponse } from "./project_billing.dto";

export const projectBillingRepository = {
  index(id: number): Promise<ProjectBillingIndexResponse> {
    return ApiClient.get(`/api/v1/billings/${id}/project_billings`).then((res) => res.data);
  },
  update(
    billing_id: number,
    project_billing_id: ProjectBillingId,
    body: { amount?: number; tax?: number },
  ): Promise<ProjectBillingUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/billings/${billing_id}/project_billings/${project_billing_id}`,
      body,
      config,
    ).then((res) => res.data);
  },
};
