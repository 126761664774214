import { ALL_USERS_VALUE } from "utils/constant";
import {
  WorkReportIndexResponse,
  WorkReportIndexRequest,
  WorkReportShowResponse,
  WorkReportUpdateRequest,
  WorkReportUpdateResponse,
  WorkReportDestroyResponse,
  WorkReportCreateResponse,
  WorkReportId,
  WorkReportCreateRequest,
} from "./work_report.dto";
import { ApiClient } from "../../ApiClient";

export const workReportRepository = {
  index(query: WorkReportIndexRequest): Promise<WorkReportIndexResponse> {
    const params = {
      started_date: query.startedDate,
      ended_date: query.endedDate,
      user_ids: query.userIds?.includes(ALL_USERS_VALUE) ? null : query.userIds,
      keyword: query.keyword,
      page: query.page || 1,
      items: query.rowsPerPage,
    };
    return ApiClient.get("/api/v1/work_reports", { params }).then((res) => res.data);
  },
  create(body: WorkReportCreateRequest): Promise<WorkReportCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/work_reports", buildCreateRequestBody(body), config).then(
      (res) => res.data,
    );
  },
  show(id: WorkReportId): Promise<WorkReportShowResponse> {
    return ApiClient.get(`/api/v1/work_reports/${id}`).then((res) => res.data);
  },
  update(id: WorkReportId, body: WorkReportUpdateRequest): Promise<WorkReportUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/work_reports/${id}`, buildUpdateRequestBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: WorkReportId): Promise<WorkReportDestroyResponse> {
    return ApiClient.delete(`/api/v1/work_reports/${id}`).then((res) => res.data);
  },
  createUrlKey(id: WorkReportId): Promise<string> {
    return ApiClient.post(`/api/v1/work_reports/${id}/url_key`).then((res) => res.data);
  },
  deleteUrlKey(id: WorkReportId): Promise<string> {
    return ApiClient.delete(`/api/v1/work_reports/${id}/url_key`).then((res) => res.data);
  },
  shareUrl(urlKey: string): Promise<WorkReportShowResponse> {
    return ApiClient.get(`/api/v1/work_reports/${urlKey}/share`).then((res) => res.data);
  },
};

const buildCreateRequestBody = (body: WorkReportCreateRequest) => {
  return {
    project_id: body.projectId,
    schedule_id: body.scheduleId,
    start_time: body.startTime,
    end_time: body.endTime,
    content: body.content,
    site_name: body.siteName,
    work_status_type: body.workStatusType,
    site_manager_id: body.siteManagerId === 0 ? null : body.siteManagerId,
    user_ids: body.userIds,
  };
};

const buildUpdateRequestBody = (body: WorkReportUpdateRequest) => {
  return {
    start_time: body.startTime,
    end_time: body.endTime,
    content: body.content,
    site_name: body.siteName,
    work_status_type: body.workStatusType,
    site_manager_id: body.siteManagerId === 0 ? null : body.siteManagerId,
    user_ids: body.userIds,
  };
};
