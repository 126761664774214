import { ApiClient } from "data-access/ApiClient";
import { PhotoResponse } from "../project/photo/photo.dto";

export const photoRepository = {
  index(
    projectUuid: string,
  ): Promise<{ company_name: string; project_name: string; photos: PhotoResponse[] }> {
    return ApiClient.get(`/api/v1/photos/${projectUuid}`).then((res) => res.data);
  },
};
