import { Range } from "react-date-range";
import { Box, Grid } from "@mui/material";
import { CustomDateRangePicker } from "components/molecules/custom-date-range-picker";
import { SearchTextField } from "components/molecules/search-text-field";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { searchResultHeaderOperations } from "store/schedule-search-result-header/operations";
import { selectScheduleSearchResultHeader } from "store/schedule-search-result-header/slice";
import { scheduleSearchResultTableOperations } from "store/schedule-search-result-table/operations";
import { formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";

export const ScheduleSearchResultTableHeader = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const headerState = useAppSelector(selectScheduleSearchResultHeader);

  const handleChangeScheduleDateRange = (range: Range) => {
    dispatch(searchResultHeaderOperations.updateScheduleDateRange(range));
    if (range.startDate && range.endDate) {
      dispatch(
        searchResultHeaderOperations.updateScheduleDateRangeLabel(
          formatDateUtil(range.startDate, "yyyy年MM月dd日") +
            "-" +
            formatDateUtil(range.endDate, "yyyy年MM月dd日"),
        ),
      );
    } else {
      dispatch(searchResultHeaderOperations.updateScheduleDateRangeLabel("すべての期間"));
    }
    dispatch(scheduleSearchResultTableOperations.updateCurrentPage(1));
    dispatch(scheduleSearchResultTableOperations.submit());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchResultHeaderOperations.updateKeyword(e.target.value));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(scheduleSearchResultTableOperations.updateCurrentPage(1));
    dispatch(scheduleSearchResultTableOperations.submit());
  };

  const handleClear = () => {
    dispatch(scheduleSearchResultTableOperations.updateCurrentPage(1));
    dispatch(scheduleSearchResultTableOperations.submit());
  };

  return (
    <Box sx={{ display: "flex" }} className={classes.tableHeader}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="flex-start" alignItems="center" gap={"24px"}>
          <CustomDateRangePicker
            dateName="すべての期間"
            dateRangeLabel={headerState.scheduleDateRangeLabel}
            dateRange={headerState.scheduleDateRange}
            handleChangeDateRange={handleChangeScheduleDateRange}
          />
          <form onSubmit={handleSubmit}>
            <SearchTextField
              id="keyword"
              name="keyword"
              placeholder="予定を検索する"
              width="440px"
              value={headerState.keyword}
              onChange={handleChange}
              onSubmit={handleClear}
            />
          </form>
        </Grid>
      </Box>
    </Box>
  );
};
