import { Dispatch } from "@reduxjs/toolkit";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { photosPreviewModalSlice } from "./slice";
import { AppDispatch } from "../store";

export const photosPreviewModalOperations = {
  updateCurrentIndex:
    (value: number) => (dispatch: Dispatch<{ payload: { value: number }; type: string }>) => {
      dispatch(photosPreviewModalSlice.actions.updateCurrentIndex({ value }));
    },
  setPhotos:
    (photos: PhotoResponse[]) =>
    (dispatch: Dispatch<{ payload: { photos: PhotoResponse[] }; type: string }>) => {
      dispatch(photosPreviewModalSlice.actions.setPhotos({ photos }));
    },
  updateErrorMessage:
    (value: string) =>
    (
      dispatch: Dispatch<{
        payload: {
          value: string;
        };
        type: string;
      }>,
    ) => {
      dispatch(photosPreviewModalSlice.actions.updateErrorMessage({ value }));
    },
  open: () => (dispatch: AppDispatch) => {
    dispatch(photosPreviewModalSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(photosPreviewModalSlice.actions.close());
  },
};
