import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Calendar } from "components/organisms/user-settings/calendar";
import { Project } from "components/organisms/user-settings/project";
import { ChangePasswordTab } from "components/organisms/user-settings/reset-password";
import { Layout } from "components/templates/layout";
import { theme } from "extensions/theme";

const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-left: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
    & .MuiButtonBase-root.MuiTab-root {
      color: ${theme.palette.primary.main};
      width: 200px;
      border-top: 1px solid ${theme.palette.primary.main};
      border-right: 1px solid ${theme.palette.primary.main};
    }
    & .MuiButtonBase-root.MuiTab-root.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.grayScale[0]};
    }
    & .MuiTab-root {
      min-width: 200px;
      width: initial !important;
    }
  }
`;

type TabType = "calendar" | "project" | "changePassword";

const UserSettings = () => {
  const [tab, setTab] = useState<TabType>("calendar");

  return (
    <Layout>
      <Box>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => {
            setTab(newValue);
          }}
        >
          <Tab value="calendar" label="予定" />
          <Tab value="project" label="案件" />
          <Tab value="changePassword" label="パスワード再設定" />
        </CustomTabs>
      </Box>

      {tab === "calendar" && <Calendar />}
      {tab === "project" && <Project />}
      {tab === "changePassword" && <ChangePasswordTab />}
    </Layout>
  );
};

export default UserSettings;
