import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import { PhotoResponseV2, ProjectPhotoId } from "data-access/repositories/project/photo/photo.dto";
import { photoRepository } from "data-access/repositories/project/photo/photo.repository";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { theme } from "extensions/theme";
import { clientServiceReportRepository } from "features/client-service-report/api/client_service_report.repository";
import { clientServiceReportItemRepository } from "features/client-service-report/api/client_service_report_item.repository";
import { clientServiceReportPageRepository } from "features/client-service-report/api/client_service_report_page.repository";
import {
  ClientServiceReportId,
  ClientServiceReportResponse,
  initialClientServiceReportResponse,
} from "features/client-service-report/types/client_service_report.dto";
import {
  ClientServiceReportItemRequest,
  ClientServiceReportItemResponse,
} from "features/client-service-report/types/client_service_report_item.dto";
import { ClientServiceReportPageId } from "features/client-service-report/types/client_service_report_page.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import useSWR, { mutate } from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { FormatPageArea } from "../../page";
import { ConfirmDialog } from "../confirm-dialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clientServiceReportId: ClientServiceReportId;
  fetchIndexKey: string;
}
export const UpdateModal = (props: Props) => {
  const { isOpen, onClose, clientServiceReportId, fetchIndexKey } = props;
  const dispatch = useAppDispatch();
  const projectState = useAppSelector(selectProjectSidebar);
  const [steps, setSteps] = useState<string[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  // 全体描画に使用
  const [clientServiceReport, setClientServiceReport] = useState<ClientServiceReportResponse>(
    initialClientServiceReportResponse,
  );
  /** 全体描画しない時の一時的な状態として使用（Itemのvalue編集時など）
   *
   *  なんかしらの状態を更新する際はまずこちらを更新し、その後必要あらばclientServiceReportに反映する
   *
   *  （clientServiceReportの更新は全体描画を伴うため、毎回行うとパフォーマンスが悪い場面では更新を避ける必要あり）
   */
  const clientServiceReportRef = useRef<ClientServiceReportResponse>(
    initialClientServiceReportResponse,
  );
  const setClientServiceReportRef = (data: ClientServiceReportResponse) => {
    clientServiceReportRef.current = data;
  };

  const { data } = useSWR(
    isOpen ? `/api/v1/client_service_reports/${clientServiceReportId}` : null,
    () => clientServiceReportRepository.show(clientServiceReportId),
  );

  useSWR(
    isOpen ? `/api/v1/projects/${projectState.project.id}/photos` : null,
    () => photoRepository.index(projectState.project.id),
    {
      revalidateOnFocus: false,
    },
  );

  // 編集前の状態を反映
  useEffect(() => {
    if (!data) return;
    setClientServiceReportRef(data);
    setClientServiceReport(data);
  }, [isOpen, data]);

  // ステップバーの設定
  useEffect(() => {
    if (isOpen && data) {
      setSteps(
        data.format.pages.map((page) => {
          return page.excelSheetName;
        }),
      );
    }
  }, [isOpen, data]);

  /** @return Itemの変更を反映したClientServiceReportResponse */
  const setItemToClientServiceReportRef = (newItem: ClientServiceReportItemResponse) => {
    setClientServiceReportRef({
      ...clientServiceReportRef.current,
      formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
        return {
          ...formatPage,
          pages: formatPage.pages.map((page) => ({
            ...page,
            headings: page.headings.map((heading) => ({
              ...heading,
              items: heading.items.map((item) => {
                if (newItem.id === item.id) {
                  return newItem;
                }
                return item;
              }),
            })),
          })),
        };
      }),
    });
  };

  /**
   * TextField系のvalue変更. 変更ごとに呼ばれるため、その度に全体描画を行わないようにする.
   *
   * (currentStepIndex変更時などにclientServiceReportに反映)
   */
  const handleChangeItemValue = (newItem: ClientServiceReportItemResponse) => {
    setItemToClientServiceReportRef(newItem);
  };

  /** 「写真を選択」クリック時、更新するItemを保持 */
  const [selectedPhotoAreaItem, setSelectedPhotoAreaItem] =
    useState<ClientServiceReportItemResponse | null>(null);
  const handlePhotoAreaClick = (item: ClientServiceReportItemResponse) => {
    setSelectedPhotoAreaItem(item);
  };

  const handlePhotoSelect = (photo: PhotoResponseV2): void => {
    if (selectedPhotoAreaItem === null) return;
    setItemToClientServiceReportRef({
      ...selectedPhotoAreaItem,
      value: photo.thumbnailUrl,

      photo: photo.recordType === "project" ? photo : null,
      workReportPhoto: photo.recordType === "work_report" ? photo : null,
    });
    setClientServiceReport(clientServiceReportRef.current);
  };

  const handlePhotoDelete = (selectedItem: ClientServiceReportItemResponse): void => {
    setItemToClientServiceReportRef({
      ...selectedItem,
      value: "",
      itemPhoto: null,
      photo: null,
      workReportPhoto: null,
    });
    setClientServiceReport(clientServiceReportRef.current);
  };

  const handleAddPage = async (formatPageId: number) => {
    setIsLoading(true);
    try {
      const res = await clientServiceReportPageRepository.create(clientServiceReport.id, {
        clientServiceReportFormatPageId: clientServiceReport.formatPages.filter(
          (formatPage) => formatPage.id === formatPageId,
        )[0].id,
      });
      const newReport = {
        ...clientServiceReportRef.current,
        formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
          if (formatPage.id === formatPageId) {
            return { ...formatPage, pages: formatPage.pages.concat(res) };
          }
          return formatPage;
        }),
      };
      setClientServiceReportRef(newReport);
      setClientServiceReport(newReport);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePage = async (pageId: ClientServiceReportPageId) => {
    setIsLoading(true);
    try {
      await clientServiceReportPageRepository.destroy(clientServiceReport.id, pageId);
      const newReport = {
        ...clientServiceReportRef.current,
        formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
          return {
            ...formatPage,
            pages: formatPage.pages.filter((page) => page.id !== pageId),
          };
        }),
      };
      setClientServiceReportRef(newReport);
      setClientServiceReport(newReport);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const converted: ClientServiceReportItemRequest = {
      values: clientServiceReportRef.current.formatPages.flatMap((formatPage) =>
        formatPage.pages.flatMap((page) =>
          page.headings.flatMap((heading) =>
            heading.items.flatMap((item) => {
              let value = item.value;
              // 日付はISO8601形式のため、yyyy-MM-dd形式に変換する
              if (
                typeof value === "string" &&
                value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)
              ) {
                value = formatDateUtil(value, "yyyy-MM-dd");
              }
              return {
                id: item.id,
                value: !item.photo && !item.workReportPhoto ? value : "",
                photoId: item.photo ? (item.photo.id as ProjectPhotoId) : null,
                workReportPhotoId: item.workReportPhoto
                  ? (item.workReportPhoto.id as WorkReportPhotoId)
                  : null,
              };
            }),
          ),
        ),
      ),
    };
    setIsLoading(true);
    try {
      await clientServiceReportItemRepository.bulk(converted);
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("報告書を保存しました"));
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectIndex = (index: number) => {
    // refの状態を描画に反映
    setClientServiceReport(clientServiceReportRef.current);
    setCurrentStepIndex(index);
  };

  const handleClose = () => {
    onClose();
    setCurrentStepIndex(1);
    setClientServiceReportRef(initialClientServiceReportResponse);
    setClientServiceReport(initialClientServiceReportResponse);
    setIsConfirmModal(false);
  };

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmModal}
        handleClose={() => setIsConfirmModal(false)}
        handleYes={handleClose}
      />

      <CustomModal
        open={isOpen}
        onClose={() => setIsConfirmModal(true)}
        title="報告書の編集"
        minHeight="70vh"
        maxWidth="lg"
        footer={
          <div style={{ display: "flex", gap: "16px" }}>
            {currentStepIndex > 1 && (
              <Button
                onClick={() => handleSelectIndex(currentStepIndex - 1)}
                variant="outlined"
                sx={{ width: "184px" }}
              >
                戻る
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => setIsConfirmModal(true)}
              sx={{ width: "184px" }}
            >
              キャンセル
            </Button>
            {currentStepIndex > 0 && currentStepIndex < clientServiceReport.formatPages.length && (
              <Button
                onClick={() => handleSelectIndex(currentStepIndex + 1)}
                variant="contained"
                sx={{ width: "184px" }}
              >
                次へ
              </Button>
            )}
            {currentStepIndex !== 0 &&
              currentStepIndex === clientServiceReport.formatPages.length && (
                <Button onClick={handleSubmit} variant="contained" sx={{ width: "184px" }}>
                  この内容で保存
                </Button>
              )}
          </div>
        }
      >
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: "24px",
          }}
        >
          <Stepper activeStep={currentStepIndex} alternativeLabel sx={{ width: "400px" }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {clientServiceReport.formatPages.map((formatPage, index) => (
          <React.Fragment key={index}>
            {currentStepIndex === index + 1 && (
              <FormatPageArea
                currentStepIndex={currentStepIndex}
                selectedLayoutName={clientServiceReport.format.name}
                formatPage={formatPage}
                onChange={handleChangeItemValue}
                onAddPage={handleAddPage}
                onDelete={handleDeletePage}
                onClick={handlePhotoAreaClick}
                onSelect={handlePhotoSelect}
                onPhotoDelete={handlePhotoDelete}
              />
            )}
          </React.Fragment>
        ))}
      </CustomModal>
    </>
  );
};
