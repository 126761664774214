import {
  GroupIndexResponse,
  GroupCreateResponse,
  GroupRequest,
  GroupUpdateResponse,
  GroupDestroyResponse,
  GroupId,
} from "./group.dto";
import { ApiClient } from "../../ApiClient";

export const groupRepository = {
  index(): Promise<GroupIndexResponse> {
    return ApiClient.get("/api/v1/groups").then((res) => res.data);
  },
  create(body: GroupRequest): Promise<GroupCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/groups", createBody(body), config).then((res) => res.data);
  },
  update(id: GroupId, body: GroupRequest): Promise<GroupUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/groups/${id}`, createBody(body), config).then((res) => res.data);
  },
  destroy(id: GroupId): Promise<GroupDestroyResponse> {
    return ApiClient.delete(`/api/v1/groups/${id}`).then((res) => res.data);
  },
};

const createBody = (body: GroupRequest) => {
  return {
    name: body.name,
    color_number: body.colorNumber,
    display_order_position: body.displayOrderPosition,
  };
};
