import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompanyShowResponse } from "data-access/repositories/company/company.dto";
import { companyRepository } from "data-access/repositories/company/company.repository";
import { CompanyUserIndexResponse } from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { PersonalSettingShowResponse } from "data-access/repositories/personal_setting/personal_setting.dto";
import { personalSettingRepository } from "data-access/repositories/personal_setting/personal_setting.repository";
import { PhotoTypeIndexResponse } from "data-access/repositories/photo_type/photo_type.dto";
import { photoTypeRepository } from "data-access/repositories/photo_type/photo_type.repository";
import { ProjectStatusTypeIndexResponse } from "data-access/repositories/project_status_type/project_status_type.dto";
import { projectStatusTypeRepository } from "data-access/repositories/project_status_type/project_status_type.repository";
import { ProjectTypeIndexResponse } from "data-access/repositories/project_type/project_type.dto";
import { projectTypeRepository } from "data-access/repositories/project_type/project_type.repository";
import { ScheduleType } from "data-access/repositories/schedule_type/schedule_type.dto";
import { scheduleTypeRepository } from "data-access/repositories/schedule_type/schedule_type.repository";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { todoTagTypeRepository } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.repository";
import { UserIndexResponse } from "data-access/repositories/user/user.dto";
import { userRepository } from "data-access/repositories/user/user.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";
import { AuthResponse } from "../../data-access/repositories/auth/auth.dto";
import { authRepository } from "../../data-access/repositories/auth/auth.repository";

export const getAuth = createAsyncThunk<AuthResponse, void, AsyncThunkConfig>(
  "main/getAuth",
  async (_, thunkApi) => {
    try {
      return await authRepository.show();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getCompany = createAsyncThunk<CompanyShowResponse, void, AsyncThunkConfig>(
  "main/getCompany",
  async (_, thunkApi) => {
    try {
      return await companyRepository.show();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectTypes = createAsyncThunk<ProjectTypeIndexResponse, void, AsyncThunkConfig>(
  "main/getProjectTypes",
  async (_, thunkApi) => {
    try {
      return await projectTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getPhotoTypes = createAsyncThunk<PhotoTypeIndexResponse, void, AsyncThunkConfig>(
  "main/getPhotoTypes",
  async (_, thunkApi) => {
    try {
      return await photoTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectStatusTypes = createAsyncThunk<
  ProjectStatusTypeIndexResponse,
  void,
  AsyncThunkConfig
>("main/getProjectStatusTypes", async (_, thunkApi) => {
  try {
    return await projectStatusTypeRepository.index();
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const getUsers = createAsyncThunk<UserIndexResponse, void, AsyncThunkConfig>(
  "main/getUsers",
  async (_, thunkApi) => {
    try {
      return await userRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getCompanyUsers = createAsyncThunk<CompanyUserIndexResponse, void, AsyncThunkConfig>(
  "main/getCompanyUsers",
  async (_, thunkApi) => {
    try {
      return await companyUserRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectTodoTagTypes = createAsyncThunk<TodoTagType[], void, AsyncThunkConfig>(
  "main/getProjectTodoTagTypes",
  async (_, thunkApi) => {
    try {
      return await todoTagTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getScheduleTypes = createAsyncThunk<ScheduleType[], void, AsyncThunkConfig>(
  "main/getScheduleTypes",
  async (_, thunkApi) => {
    try {
      return await scheduleTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getPersonalSetting = createAsyncThunk<
  PersonalSettingShowResponse,
  void,
  AsyncThunkConfig
>("main/getPersonalSetting", async (_, thunkApi) => {
  try {
    return await personalSettingRepository.show();
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});
