import {
  getAuth,
  getCompany,
  getPhotoTypes,
  getProjectTypes,
  getProjectStatusTypes,
  getUsers,
  getProjectTodoTagTypes,
  getPersonalSetting,
  getScheduleTypes,
  getCompanyUsers,
} from "./actions";
import { mainSlice } from "./slice";
import { AppDispatch } from "../store";

export const mainOperations = {
  getAuth: () => (dispatch: AppDispatch) => {
    dispatch(getAuth());
  },
  getCompany: () => (dispatch: AppDispatch) => {
    dispatch(getCompany());
  },
  getProjectTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectTypes());
  },
  getProjectStatusTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectStatusTypes());
  },
  getPhotoTypes: () => (dispatch: AppDispatch) => {
    dispatch(getPhotoTypes());
  },
  getUsers: () => (dispatch: AppDispatch) => {
    dispatch(getUsers());
  },
  getCompanyUsers: () => (dispatch: AppDispatch) => {
    dispatch(getCompanyUsers());
  },
  getProjectTodoTagTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectTodoTagTypes());
  },
  getScheduleTypes: () => (dispatch: AppDispatch) => {
    dispatch(getScheduleTypes());
  },
  getPersonalSetting: () => (dispatch: AppDispatch) => {
    dispatch(getPersonalSetting());
  },
  updateSuccessMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateSuccessMessage({ value }));
  },
  updateErrorMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateErrorMessage({ value }));
  },
  updateIsLoading: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateIsLoading({ value }));
  },
  updateIsCompanySettingToNavBar: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateIsCompanySettingToNavBar({ value }));
  },
};
