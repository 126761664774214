import { Brand } from "../brand";
import { CompanySetting, initialCompanySetting } from "../company_setting/company_setting.dto";

export type CompanyId = Brand<number, "CompanyId">;

export interface Company {
  id: CompanyId;
  name: string;
  address: string;
  phone_number: string;
  fax_number: string;
  company_setting: CompanySetting;
}

export const initialCompany: Company = {
  id: 0 as CompanyId,
  name: "",
  address: "",
  phone_number: "",
  fax_number: "",
  company_setting: initialCompanySetting,
};

export interface CompanyShowResponse extends Company {}
