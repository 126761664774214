import { Brand } from "data-access/repositories/brand";
import { SupplierId } from "./supplier.dto";

export type MaterialCostId = Brand<number, "MaterialCostId">;

export interface MaterialCost {
  id: MaterialCostId;
  amount: number;
  tax: number;
  note: string;
  supplier: {
    id: SupplierId;
    name: string;
    isDefaultCost: boolean;
  };
}

export interface MaterialCostIndexResponse extends Array<MaterialCost> {}

export interface MaterialCostCreateRequest {
  supplierId?: SupplierId;
  supplier?: {
    name?: string;
  };
  amount: number;
  note: string;
}

export interface MaterialCostUpdateRequest {
  amount: number;
  note: string;
}

export const initialMaterialCostFormRequest: MaterialCostUpdateRequest = {
  amount: 0,
  note: "",
};
