import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Edit, OpenInNew } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LinkButton } from "components/button/link-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { DetailTable } from "components/molecules/detail-table";
import { ShareSettingDialog } from "components/molecules/share-setting-dialog";
import { PhotoUpdateRequest } from "data-access/repositories/project/photo/photo.dto";
import {
  PhotoResponse,
  WorkReportPhotoId,
} from "data-access/repositories/work_report/photo/photo.dto";
import { photoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import {
  WorkReport,
  WorkReportId,
  WorkReportUpdateRequest,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import FileDownload from "images/file_download_icon.svg";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import useSWR, { mutate, useSWRConfig } from "swr";
import { downloadImages } from "utils/downloadImages";
import { formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { WorkReportFormModal, WorkReportState } from "./form-modal";

interface Props {
  show: { isOpen: boolean; id: WorkReportId | undefined };
  setShow: (e: { isOpen: boolean; id: WorkReportId | undefined }) => void;
  onDelete: (id: WorkReportId) => void;
  fetchIndexKey?: string;
}

interface CustomTextFormProps {
  label: string;
  isTitle?: boolean;
}

const CustomBox = styled(Box)({
  lineHeight: "14px",
  letterSpacing: "0.2px",
  fontWeight: "500",
  padding: "1rem 0",
});

const CustomHeaderBox = styled(Box)({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.grayScale[0],
  zIndex: 9,
  paddingTop: "16px",
});

const CustomTextForm: React.FC<CustomTextFormProps> = (props: CustomTextFormProps) => {
  const labelColor = props.isTitle ? theme.palette.grayScale[700] : theme.palette.grayScale[900];
  return (
    <CustomFormLabel
      small
      labelName={props.label}
      labelColor={labelColor}
      sx={{ height: "14px", lineHeight: "14px" }}
    />
  );
};

export const WorkReportDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: IndexMutate } = useSWRConfig();

  const handleClose = () => {
    props.setShow({ isOpen: false, id: undefined });
    // 作業日報画面から開いている場合、作業日報画面に戻る（予定や案件からの動線もあるため）
    if (location.pathname.includes("work-reports")) {
      navigate("/work-reports");
    }
  };

  const handleClickDelete = (id: WorkReportId) => {
    props.onDelete(id);
  };

  const handleUpdateSuccess = async (e: string) => {
    dispatch(mainOperations.updateSuccessMessage(e));
    try {
      await IndexMutate(props.fetchIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      <Dialog
        open={props.show.isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxWidth: 600,
          },
        }}
      >
        <Box sx={{ p: "0 1rem 1rem", width: "600px" }}>
          {props.show.id && (
            <Contents
              id={props.show.id}
              setSuccessMessage={handleUpdateSuccess}
              handleClose={handleClose}
              handleClickDelete={handleClickDelete}
              fetchIndexKey={props.fetchIndexKey || ""}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

const Contents = (props: {
  id: WorkReportId;
  setSuccessMessage: (e: string) => void;
  handleClose: () => void;
  handleClickDelete: (id: WorkReportId) => void;
  fetchIndexKey: string;
}) => {
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);
  const fetchKey = `/api/v1/work_reports/${props.id}`;
  const fetchPhotosKey = `/api/v1/work_reports/${props.id}/photos`;
  const photoCountIndexKey = `${fetchPhotosKey}/count_info`;
  const { data, isLoading } = useSWR(fetchKey, () =>
    workReportRepository.show(props.id as WorkReportId),
  );
  const {
    data: photos,
    isLoading: isLoadingPhotos,
    mutate: photosMutate,
  } = useSWR(fetchPhotosKey, () => photoRepository.index(props.id), {
    revalidateOnFocus: false,
  });

  const dispatch = useAppDispatch();

  const [headerPhotoCheckItems, setHeaderPhotoCheckItems] = useState<
    { id: number; checked: boolean; url: string; name: string }[]
  >([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const items: { id: number; checked: boolean; url: string; name: string }[] = [];
    if (photos) {
      for (const photo of photos) {
        items.push({ id: photo.id, checked: false, url: photo.file_url, name: photo.file_name });
      }
      setHeaderPhotoCheckItems(items);
    }
  }, [photos]);

  const bulkChecked = (v: boolean) => {
    const items = headerPhotoCheckItems.map((item) => {
      return {
        ...item,
        checked: v,
      };
    });
    setHeaderPhotoCheckItems(items);
  };

  const clickPhotoCheck = (id: number, value: boolean) => {
    const items = headerPhotoCheckItems.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          checked: value,
        };
      } else {
        return item;
      }
    });
    setHeaderPhotoCheckItems(items);
  };

  const clickDownloadImages = () => {
    const list = headerPhotoCheckItems
      .filter((item) => item.checked)
      .map((item) => {
        return {
          url: item.url,
          name: item.name,
        };
      });
    downloadImages(list, `作業日報写真(${data?.site_name})`);
  };

  const handlePhotoPreviewClick = (photoId: number, recordType: string) => {
    if (!photos) return;

    // 案件と作業日報の写真のidが重複しているときがあるため、recordTypeと合わせて検索する
    const createPhotoInfo = photos.map((photo) => {
      return {
        id: photo.id,
        type: photo.record_type,
      };
    });
    const clickedPhotoInfo = createPhotoInfo.filter(
      (photo) => photo.id === photoId && photo.type === recordType,
    );
    const clickedPhotoIndex = createPhotoInfo.indexOf(clickedPhotoInfo[0]);

    dispatch(photosPreviewModalOperations.open());
    dispatch(photosPreviewModalOperations.updateCurrentIndex(clickedPhotoIndex));
    dispatch(photosPreviewModalOperations.setPhotos(photos));
  };

  const handleUpdate = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.update(
        props.id as WorkReportId,
        body as WorkReportUpdateRequest,
      );
      props.setSuccessMessage("作業日報を更新しました");
      return res;
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      throw error;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    openURLInNewTab(`projects/${data?.project_id}`);
  };

  const initialDefaultState = useMemo(() => {
    return {
      siteName: data?.site_name,
      userIds: data?.users.map((user) => user.id),
      users: data?.users,
      siteManagerId: data?.site_manager ? data.site_manager.id : 0,
      startTime: data?.start_time,
      endTime: data?.end_time,
      content: data?.content,
      workStatusType: data?.work_status_type,
      created_date: data?.created_date,
      created_by: data?.created_by,
    } as WorkReportState;
  }, [data]);

  /**
   * 写真の共有リンクタップ時の処理.
   *
   * @param photo 写真オブジェクト(画像ではないので注意).
   */
  const handleClickImageLinkButton = async (photo: PhotoResponse) => {
    const photoId = photo.id;
    const isSharable = !photo.is_sharable;
    try {
      await photoRepository.update(
        photo.work_report_attributes?.id as WorkReportId,
        photoId as WorkReportPhotoId,
        {
          isSharable: isSharable,
        } as PhotoUpdateRequest,
      );
      photosMutate();
      mutate(photoCountIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading || isLoadingPhotos}
        invisible
      >
        <CircularProgress />
      </Backdrop>
      {data && (
        <ShareSettingDialog
          open={isOpenShareModal}
          onClose={() => setIsOpenShareModal(false)}
          id={data.id}
          resourceType="work_reports"
          photoUrlKey={data.url_key || ""}
        />
      )}

      <CustomHeaderBox>
        <Grid container justifyContent="space-between" sx={{ mb: "8px" }}>
          <Grid item>
            <Button
              startIcon={<Close />}
              onClick={() => props.handleClose()}
              sx={{ color: theme.palette.grayScale[700] }}
            >
              とじる
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setIsOpenShareModal(true)}
              startIcon={
                <LinkIcon
                  size={16}
                  color={data?.url_key ? theme.palette.blue[500] : theme.palette.primary.main}
                />
              }
            >
              <Typography
                sx={{
                  color: data?.url_key ? theme.palette.blue[500] : theme.palette.primary.main,
                  mr: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {data?.url_key ? "リンクで共有中" : "リンクで共有"}
              </Typography>
            </Button>
            <Button
              startIcon={<Edit />}
              color="primary"
              onClick={() => setIsOpen(true)}
              sx={{ mr: "8px" }}
            >
              内容を編集
            </Button>
            <Button
              startIcon={<DeleteIcon size={20} />}
              color="error"
              onClick={() => props.handleClickDelete(props.id)}
            >
              削除
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", alignItems: "center", p: "2px", width: "100%" }}>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  wordBreak: "break-all",
                  flex: 1,
                  mr: "20px",
                }}
              >
                {data?.site_name}
              </Typography>
            </Grid>
            <Grid item>
              <StatusTypeLabel
                statusType={data?.work_status_type_i18n || ""}
                sx={{ width: "80px", mr: "12px", p: "6px 20px" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mt: "26px" }} />
      </CustomHeaderBox>

      <DetailTable
        sx={{ mt: 1 }}
        items={[
          {
            label: <CustomTextForm isTitle label="案件" />,
            value: (
              <Link
                href="#"
                onClick={handleClick}
                underline="none"
                sx={{ display: "flex", alignItems: "center", fontWeight: "500", fontSize: "14px" }}
              >
                {data?.project_name}
                <OpenInNew fontSize="small" color="primary" />
              </Link>
            ),
          },
          {
            label: <CustomTextForm isTitle label="作業日時" />,
            value: (
              <CustomTextForm
                label={timeRange(
                  "yyyy年MM月dd日 HH:mm",
                  data?.start_time.toString(),
                  data?.end_time.toString(),
                )}
              />
            ),
          },
          {
            label: <CustomTextForm isTitle label="現場責任者" />,
            value: (
              <CustomTextForm label={data?.site_manager ? data?.site_manager.name : "未設定"} />
            ),
          },
        ]}
      />

      <CustomBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            参加者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {usersName(data?.users || [])}
          </Typography>
        </div>
      </CustomBox>

      <CustomBox>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
            >
              作業内容・連絡事項
            </Typography>
          </Grid>
          <Box sx={{ ml: "13px", width: "70%" }}>
            <Typography
              sx={{ fontSize: "14px", color: theme.palette.grayScale[900], wordBreak: "break-all" }}
            >
              {data?.content.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </Grid>
      </CustomBox>

      <CustomBox>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item>
            <Typography
              fontWeight="500"
              sx={{
                fontSize: "14px",
                color: theme.palette.grayScale[700],
                height: "35px",
                lineHeight: "30px",
              }}
            >
              現場写真
            </Typography>
          </Grid>
          {!!data?.header_photos && (
            <>
              <Grid item>
                <Box sx={{ ml: "20px" }}>
                  <Button
                    variant="contained"
                    onClick={() => clickDownloadImages()}
                    disabled={headerPhotoCheckItems.filter((item) => item.checked).length === 0}
                    sx={{
                      fontSize: "12px",
                      height: "30px",
                      lineHeight: "30px",
                      borderRadius: "20px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <Box
                        sx={{ color: theme.palette.grayScale[0] }}
                        component="img"
                        src={FileDownload}
                      />
                    }
                  >
                    {headerPhotoCheckItems.filter((item) => item.checked).length}件をダウンロード
                  </Button>
                </Box>
              </Grid>
              <Typography
                fontWeight="500"
                onClick={() => bulkChecked(true)}
                sx={{
                  fontSize: "12px",
                  ml: "1rem",
                  color: theme.palette.primary.main,
                  height: "30px",
                  lineHeight: "30px",
                  cursor: "pointer",
                }}
              >
                すべて選択
              </Typography>
              <Typography
                fontWeight="500"
                onClick={() => bulkChecked(false)}
                sx={{
                  fontSize: "12px",
                  ml: "1rem",
                  height: "30px",
                  lineHeight: "30px",
                  cursor: "pointer",
                }}
              >
                選択を解除
              </Typography>
            </>
          )}
        </Grid>
        <Grid container sx={{ gap: "10px" }}>
          {photos &&
            photos.map((photo) => {
              const item = headerPhotoCheckItems.find((item) => {
                return photo.id === item.id;
              });
              return (
                <Grid item key={photo.id}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "177px",
                      height: "114px",
                      textAlign: "center",
                      border: `1px solid ${theme.palette.grayScale[200]}`,
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt={`photo-${photo.id}`}
                      src={photo.thumbnail_url}
                      style={{ maxWidth: "100%" }}
                      height="100%"
                      loading="lazy"
                      onClick={() => handlePhotoPreviewClick(photo.id, photo.record_type)}
                    />
                    {item && (
                      <Checkbox
                        checked={item.checked}
                        onChange={(e) => clickPhotoCheck(photo.id, e.target.checked)}
                        sx={{
                          position: "absolute",
                          top: "12px",
                          left: "12px",
                          borderRadius: 0,
                          width: "20px",
                          height: "20px",
                          backgroundColor: theme.palette.grayScale[0],
                          color: theme.palette.grayScale[0],
                          border: `1px solid ${theme.palette.grayScale[300]}`,
                          "&.MuiCheckbox-root": {
                            "&:hover": {
                              backgroundColor: theme.palette.grayScale[0],
                            },
                          },
                        }}
                      />
                    )}
                    <LinkButton
                      sx={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                      }}
                      isEnabled={photo.is_sharable}
                      handleClick={() => handleClickImageLinkButton(photo)}
                    />
                    <Typography
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: "4px",
                        left: "4px",
                        backgroundColor: theme.palette.grayScale[0],
                        borderRadius: "4px",
                        p: "0.2rem 0.8rem",
                        fontSize: "11px",
                        border: `1px solid ${theme.palette.grayScale[300]}`,
                      }}
                    >
                      {photo.photo_type.value_i18n}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </CustomBox>

      <DetailTable
        sx={{ minWidth: "35rem" }}
        items={[
          {
            label: <CustomTextForm isTitle label="作成日" />,
            value: (
              <CustomTextForm
                label={formatDateUtil(new Date(data?.created_date as string), "yyyy年MM月dd日")}
              />
            ),
          },
          {
            label: <CustomTextForm isTitle label="作成者" />,
            value: <CustomTextForm label={data?.created_by.name || ""} />,
          },
        ]}
      />

      <WorkReportFormModal
        isCreate={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleUpdate}
        id={props.id as WorkReportId}
        defaultValue={initialDefaultState}
        defaultPhotos={photos}
        fetchIndexKeys={[fetchKey, fetchPhotosKey, props.fetchIndexKey]}
      />
    </>
  );
};
