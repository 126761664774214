import { useEffect, useState } from "react";
import { Range } from "react-date-range";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, Select, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { CustomDateRangePicker } from "components/molecules/custom-date-range-picker";
import { SearchTextField } from "components/molecules/search-text-field";
import { styles } from "components/organisms/billing-table-header/styles";
import { getFilterItem, setFilterItem } from "data-access/local_storage/table_filter/local_storage";
import {
  DefaultBilling,
  DefaultBillingType,
} from "data-access/local_storage/table_filter/local_storage.dto";
import { BillingIndexRequest } from "data-access/repositories/billing/billing.dto";
import { theme } from "extensions/theme";
import { billingTableOperations } from "store/billing-table/operations";
import { selectBillingTable } from "store/billing-table/slice";
import { billingTableHeaderOperations } from "store/billing-table-header/operations";
import { useAppSelector, useAppDispatch } from "store/hooks";
import "react-datepicker/dist/react-datepicker.css";
import { selectMain } from "store/main/slice";
import { formatDateUtil } from "utils/formatDateUtil";

export const BillingTableHeader = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const tableState = useAppSelector(selectBillingTable);
  const mainState = useAppSelector(selectMain);
  const filterItemLocalStorage = getFilterItem("billing");

  const [billingDateRange, setBillingDateRange] = useState<Range>(
    DefaultBilling.billingDateV2.range,
  );
  const [billingDateLabel, setBillingDateLabel] = useState<string>(
    DefaultBilling.billingDateV2.label,
  );
  const [statusType, setStatusType] = useState<string>("all");
  const [depositStatusTypes, setDepositStatusTypes] = useState<string[]>(
    DefaultBilling.depositStatusTypes,
  );
  const [billingFileCreateStatusType, setBillingFileCreateStatusType] = useState<string>(
    DefaultBilling.billingFileCreateStatusType,
  );
  const [warrantyCreateStatusType, setWarrantyCreateStatusType] = useState<string>(
    DefaultBilling.warrantyCreateStatusType,
  );
  const [createdById, setCreatedById] = useState<number>(DefaultBilling.createdById);
  const [keyword, setKeyword] = useState<string>(DefaultBilling.keyword);

  useEffect(() => {
    const filterStorageKeyName = "billingTableFilter";
    const localStorageItem = localStorage.getItem(filterStorageKeyName);

    // 検索条件を常にLocalStorageに保存するため
    if (localStorageItem === null) {
      localStorage.setItem(filterStorageKeyName, JSON.stringify(DefaultBilling));
    } else {
      const filterItem = getFilterItem("billing");
      let isNeedUpdate = false;
      if (!filterItem.billingDateV2) {
        filterItem.billingDateV2 = DefaultBilling.billingDateV2;
        isNeedUpdate = true;
      }
      setBillingDateRange(filterItem.billingDateV2.range);
      setBillingDateLabel(filterItem.billingDateV2.label);
      setStatusType(filterItem.statusType);
      setDepositStatusTypes(filterItem.depositStatusTypes);
      setBillingFileCreateStatusType(filterItem.billingFileCreateStatusType);
      setWarrantyCreateStatusType(filterItem.warrantyCreateStatusType);
      if (!filterItem.createdById) {
        filterItem.createdById = DefaultBilling.createdById;
        isNeedUpdate = true;
      }
      setCreatedById(filterItem.createdById);
      setKeyword(filterItem.keyword);
      if (isNeedUpdate) {
        setFilterItem("billing", filterItem);
      }
    }

    dispatch(billingTableHeaderOperations.getClients({}));
  }, []);

  const saveFilterItem = (item: DefaultBillingType) => {
    setFilterItem("billing", item);
    dispatch(billingTableHeaderOperations.addCountChanges());
  };

  const handleChangeBillingDateRange = (range: Range) => {
    setBillingDateRange(range);
    filterItemLocalStorage.billingDateV2.range = range;
    if (range.startDate && range.endDate) {
      const label =
        "請求日" +
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
        "-" +
        formatDateUtil(range.endDate, "yyyy年MM月dd日");

      setBillingDateLabel(label);
      filterItemLocalStorage.billingDateV2.label = label;
    } else {
      setBillingDateLabel("すべての請求日");
      filterItemLocalStorage.billingDateV2.label = "すべての請求日";
    }
    saveFilterItem(filterItemLocalStorage);
  };

  const handleChanges = (e: SelectChangeEvent<typeof depositStatusTypes>) => {
    const {
      target: { value },
    } = e;
    if (typeof value !== "string") {
      const saveItems: string[] = [];
      if (value.length === 0) {
        saveItems.push("before_deposited");
      } else if (depositStatusTypes.length === 3 && value.length === 2) {
        // 「すべて」状態からの選択
        const removedItem = depositStatusTypes.filter((v) => {
          return !value.includes(v);
        });
        saveItems.push(...removedItem);
      } else if (value.includes("all")) {
        saveItems.push(...DefaultBilling.depositStatusTypes);
      } else {
        saveItems.push(...value);
      }

      if (saveItems.length > 0) {
        setDepositStatusTypes(saveItems);
        filterItemLocalStorage.depositStatusTypes = saveItems;
        setFilterItem("billing", filterItemLocalStorage);
      }
    }
  };

  const handleClose = () => {
    dispatch(billingTableHeaderOperations.addCountChanges());
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    const filterItem = getFilterItem("billing");
    switch (e.target.name) {
      case "billingFileCreateStatusType":
        setBillingFileCreateStatusType(e.target.value as string);
        filterItem.billingFileCreateStatusType = e.target.value as string;
        break;
      case "warrantyCreateStatusType":
        setWarrantyCreateStatusType(e.target.value as string);
        filterItem.warrantyCreateStatusType = e.target.value as string;
        break;
      case "createdById":
        setCreatedById(e.target.value as number);
        filterItem.createdById = e.target.value as number;
        break;
      case "statusType":
        setStatusType(e.target.value as string);
        filterItem.statusType = e.target.value as string;
        break;
    }
    saveFilterItem(filterItem);
  };

  const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const filterItem = getFilterItem("billing");
    setKeyword(e.target.value as string);
    filterItem.keyword = e.target.value as string;
    setFilterItem("billing", filterItem);
  };

  const filterBillingFileCreateStatusType: { id: string; name: string }[] = [
    { id: "all", name: "請求書作成 - すべて" },
    { id: "before_billing_file_created", name: "請求書作成 - 未アップロード" },
    { id: "billing_file_created", name: "請求書作成 - アップロード済み" },
  ];

  const filterWarrantyCreateStatusType: { id: string; name: string }[] = [
    { id: "all", name: "保証書作成 - すべて" },
    { id: "warranty_not_attach", name: "保証書作成 - 付与しない" },
    { id: "before_warranty_created", name: "保証書作成 - 未作成" },
    { id: "warranty_created", name: "保証書作成 - 作成済み" },
  ];

  const filterDepositStatusTypes: { id: string; name: string }[] = [
    { id: "before_deposited", name: "未入金" },
    { id: "partial_deposited", name: "一部入金済み" },
    { id: "all_deposited", name: "すべて入金済み" },
  ];

  const filterStatusTypes: { id: string; name: string }[] = [
    { id: "all", name: "請求書送付 - すべて" },
    { id: "before_billed", name: "請求書送付 - 未送付" },
    { id: "billed", name: "請求書送付 - 送付済み" },
  ];

  const isSelectedDepositStatusType = (id: string) => {
    return filterItemLocalStorage.depositStatusTypes.includes(id);
  };

  const filteredDepositStatusType: string = filterDepositStatusTypes
    .filter((statusType) => isSelectedDepositStatusType(statusType.id))
    .map((statusType) => statusType.name)
    .join(", ");

  const searchQuery = (page?: number) => {
    const filterItem = getFilterItem("billing");
    const query: BillingIndexRequest = {
      statusType: filterItem.statusType,
      billingFileCreateStatusType: filterItem.billingFileCreateStatusType,
      warrantyCreateStatusType: filterItem.warrantyCreateStatusType,
      depositStatusTypes: filterItem.depositStatusTypes,
      page: page ?? tableState.currentPage,
    };
    if (filterItem.createdById !== 0) {
      query.createdById = filterItem.createdById;
    }
    if (filterItem.keyword !== "") {
      query.keyword = filterItem.keyword;
    }
    if (filterItem.billingDateV2.range.startDate) {
      query.startBillingDate = formatDateUtil(
        filterItem.billingDateV2.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.billingDateV2.range.endDate) {
      query.endBillingDate = formatDateUtil(filterItem.billingDateV2.range.endDate, "yyyy-MM-dd");
    }
    return query;
  };

  const handleSubmit = () => {
    dispatch(billingTableOperations.updateCurrentPage(1));
    dispatch(billingTableOperations.index(searchQuery(1)));
  };

  const conditionClear = () => {
    setBillingDateRange(DefaultBilling.billingDateV2.range);
    setBillingDateLabel(DefaultBilling.billingDateV2.label);
    setStatusType(DefaultBilling.statusType);
    setDepositStatusTypes(DefaultBilling.depositStatusTypes);
    setBillingFileCreateStatusType(DefaultBilling.billingFileCreateStatusType);
    setWarrantyCreateStatusType(DefaultBilling.warrantyCreateStatusType);
    setCreatedById(DefaultBilling.createdById);
    setKeyword(DefaultBilling.keyword);
    saveFilterItem(DefaultBilling);
  };

  return (
    <Box sx={{ display: "flex" }} className={classes.tableHeader}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <CustomDateRangePicker
            dateName="すべての請求日"
            dateRangeLabel={billingDateLabel}
            dateRange={billingDateRange}
            handleChangeDateRange={handleChangeBillingDateRange}
            sx={{ mr: "1rem", mb: "0.5rem" }}
          />

          {/* 送付ステータス */}
          {mainState.company.company_setting.is_billing_status_type_use && (
            <Select
              id="statusType"
              name="statusType"
              value={statusType}
              onChange={handleChange}
              sx={{ minWidth: "160px", marginRight: "16px", marginBottom: "8px" }}
            >
              {filterStatusTypes.map((type) => {
                return (
                  <MenuItem value={type.id} key={type.id}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}

          <Select
            id="depositStatusTypes"
            name="depositStatusTypes"
            multiple
            value={depositStatusTypes}
            onChange={handleChanges}
            onClose={handleClose}
            style={{ minWidth: "160px", marginRight: "16px", marginBottom: "8px" }}
            renderValue={() => filteredDepositStatusType}
          >
            <MenuItem
              value="all"
              disabled={
                tableState.isLoading || getFilterItem("billing").depositStatusTypes.length === 3
              }
            >
              すべて
            </MenuItem>
            {filterDepositStatusTypes.map((type) => {
              return (
                <MenuItem
                  key={type.id}
                  value={type.id}
                  sx={{
                    color: isSelectedDepositStatusType(type.id)
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  }}
                >
                  {isSelectedDepositStatusType(type.id) ? (
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                  ) : (
                    <Box sx={{ px: 1.5, mr: 1 }}></Box>
                  )}
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            id="billingFileCreateStatusType"
            name="billingFileCreateStatusType"
            value={billingFileCreateStatusType}
            onChange={handleChange}
            style={{ minWidth: "10rem", marginRight: "1rem", marginBottom: "0.5rem" }}
          >
            {filterBillingFileCreateStatusType.map((type) => {
              return (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>

          {mainState.company.company_setting.is_warranty_use && (
            <Select
              id="warrantyCreateStatusType"
              name="warrantyCreateStatusType"
              value={warrantyCreateStatusType}
              onChange={handleChange}
              style={{ minWidth: "10rem", marginRight: "1rem", marginBottom: "0.5rem" }}
            >
              {filterWarrantyCreateStatusType.map((type) => {
                return (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}

          {/*請求作成者*/}
          <Select
            id="createdById"
            name="createdById"
            value={createdById.toString()}
            onChange={handleChange}
            style={{ minWidth: "10rem", marginRight: "1rem", marginBottom: "0.5rem" }}
          >
            <MenuItem value={0}>すべての請求作成者</MenuItem>
            {mainState.users.map((user) => {
              return (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              );
            })}
          </Select>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <SearchTextField
              id="keyword"
              name="keyword"
              value={keyword}
              onChange={handleChangeKeyword}
              onSubmit={handleSubmit}
              sx={{ minWidth: "10rem", marginRight: "1rem", marginBottom: "0.5rem" }}
            />
          </form>
          <Box>
            <Typography
              fontWeight="400"
              onClick={() => conditionClear()}
              sx={{
                fontSize: "14px",
                mr: "1rem",
                mb: "0.5rem",
                color: theme.palette.grayScale[700],
                cursor: "pointer",
              }}
            >
              検索条件をリセット
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
