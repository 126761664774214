import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ScheduleUpdateRequest,
  ScheduleAssignmentResponse,
  ScheduleUpdateResponse,
  ScheduleDuplicateResponse,
  ScheduleId,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const duplicateGridSchedule = createAsyncThunk<
  ScheduleDuplicateResponse,
  ScheduleId,
  AsyncThunkConfig
>("scheduleRightClickMenu/duplicateGridSchedule", async (id: ScheduleId, thunkApi) => {
  try {
    return await scheduleRepository.duplicate(id);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const duplicateTimelineSchedule = createAsyncThunk<
  ScheduleAssignmentResponse,
  ScheduleId,
  AsyncThunkConfig
>("scheduleRightClickMenu/duplicateTimelineSchedule", async (id: ScheduleId, thunkApi) => {
  try {
    return await scheduleRepository.duplicate(id);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const updateGridSchedule = createAsyncThunk<
  ScheduleUpdateResponse,
  { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
  AsyncThunkConfig
>(
  "scheduleRightClickMenu/updateGridSchedule",
  async (
    thunkArg: { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
    thunkApi,
  ) => {
    try {
      return await scheduleRepository.update(thunkArg.id, thunkArg.with_futures, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);
