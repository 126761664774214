export interface CompanySetting {
  project_code_format: string;
  is_billing_status_type_use: boolean;
  is_copy_project_name_and_code_use: boolean;
  is_printable_report_use: boolean;
  is_warranty_use: boolean;
  schedule_note_template: string;
  work_report_content_template: string;
  project_note_template: string;
}

export interface CompanySettingUpdateRequest {
  scheduleNoteTemplate?: string;
  workReportContentTemplate?: string;
  projectNoteTemplate?: string;
}

export interface CompanySettingUpdateResponse {}

export const initialCompanySetting: CompanySetting = {
  project_code_format: "",
  is_billing_status_type_use: false,
  is_copy_project_name_and_code_use: false,
  is_printable_report_use: false,
  is_warranty_use: false,
  schedule_note_template: "",
  work_report_content_template: "",
  project_note_template: "",
};
