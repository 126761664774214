import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddressFromPostalCodeResponse } from "data-access/repositories/Address/address_from_postal_code.dto";
import { addressRepository } from "data-access/repositories/Address/postal_code.repository";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";
import { PostalCodeFromAddressResponse } from "data-access/repositories/postal_code/postal_code_from_address.dto";
import {
  AttachmentCreateRequest,
  AttachmentUpdateRequest,
  AttachmentUpdateResponse,
  AttachmentDestroyResponse,
  ProjectAttachmentId,
} from "data-access/repositories/project/attachment/attachment.dto";
import { attachmentRepository } from "data-access/repositories/project/attachment/attachment.repository";
import {
  EstimateCreateRequest,
  EstimateCreateResponse,
} from "data-access/repositories/project/estimate/estimate.dto";
import { estimateRepository } from "data-access/repositories/project/estimate/estimate.repository";
import {
  ProjectCreateResponse,
  ProjectCreateRequest,
  ProjectShowResponse,
  ProjectUpdateRequest,
  ProjectUpdateResponse,
  ProjectDuplicateResponse,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  ProjectTodoRequest,
  ProjectTodo,
  ProjectTodoId,
} from "data-access/repositories/project/todo/todo.dto";
import { projectTodoRepository } from "data-access/repositories/project/todo/todo.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const showProject = createAsyncThunk<ProjectShowResponse, ProjectId, AsyncThunkConfig>(
  "projectSidebar/show",
  async (id: ProjectId, thunkApi) => {
    try {
      return await projectRepository.show(id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const showProjectTodo = createAsyncThunk<ProjectTodo[], ProjectId, AsyncThunkConfig>(
  "projectSidebar/showProjectTodo",
  async (id: ProjectId, thunkApi) => {
    try {
      return await projectTodoRepository.index(id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const createProjectTodo = createAsyncThunk<
  void,
  { projectId: ProjectId; body: ProjectTodoRequest },
  AsyncThunkConfig
>(
  "projectSidebar/createProjectTodo",
  async (thunkArg: { projectId: ProjectId; body: ProjectTodoRequest }, thunkApi) => {
    try {
      return await projectTodoRepository.create(thunkArg.projectId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateProjectTodo = createAsyncThunk<
  void,
  { projectId: ProjectId; todoId: ProjectTodoId; body: ProjectTodoRequest },
  AsyncThunkConfig
>(
  "projectSidebar/updateProjectTodo",
  async (
    thunkArg: { projectId: ProjectId; todoId: ProjectTodoId; body: ProjectTodoRequest },
    thunkApi,
  ) => {
    try {
      return await projectTodoRepository.update(thunkArg.projectId, thunkArg.todoId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const deleteProjectTodo = createAsyncThunk<
  void,
  { projectId: ProjectId; todoId: ProjectTodoId },
  AsyncThunkConfig
>(
  "projectSidebar/deleteProjectTodo",
  async (thunkArg: { projectId: ProjectId; todoId: ProjectTodoId }, thunkApi) => {
    try {
      return await projectTodoRepository.destroy(thunkArg.projectId, thunkArg.todoId);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateTodoDone = createAsyncThunk<
  void,
  { projectId: ProjectId; todoId: ProjectTodoId },
  AsyncThunkConfig
>(
  "projectSidebar/updateTodoDone",
  async (thunkArg: { projectId: ProjectId; todoId: ProjectTodoId }, thunkApi) => {
    try {
      return await projectTodoRepository.done(thunkArg.projectId, thunkArg.todoId);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateTodoUndone = createAsyncThunk<
  void,
  { projectId: ProjectId; todoId: ProjectTodoId },
  AsyncThunkConfig
>(
  "projectSidebar/updateTodoUndone",
  async (thunkArg: { projectId: ProjectId; todoId: ProjectTodoId }, thunkApi) => {
    try {
      return await projectTodoRepository.undone(thunkArg.projectId, thunkArg.todoId);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getPostalCode = createAsyncThunk<
  PostalCodeFromAddressResponse,
  string,
  AsyncThunkConfig
>("projectSidebar/getPostalCode", async (address, thunkApi) => {
  try {
    return await postalCodeRepository.index(address);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const getAddress = createAsyncThunk<AddressFromPostalCodeResponse, string, AsyncThunkConfig>(
  "projectSidebar/getAddress",
  async (postalCode, thunkApi) => {
    try {
      return await addressRepository.index(postalCode);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const createProject = createAsyncThunk<
  ProjectCreateResponse,
  { body: ProjectCreateRequest },
  AsyncThunkConfig
>("projectSidebar/create", async (thunkArg: { body: ProjectCreateRequest }, thunkApi) => {
  try {
    return await projectRepository.create(thunkArg.body);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const updateProject = createAsyncThunk<
  ProjectUpdateResponse,
  { id: ProjectId; body: ProjectUpdateRequest },
  AsyncThunkConfig
>(
  "projectSidebar/update",
  async (thunkArg: { id: ProjectId; body: ProjectUpdateRequest }, thunkApi) => {
    try {
      return await projectRepository.update(thunkArg.id, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const duplicateProject = createAsyncThunk<
  ProjectDuplicateResponse,
  ProjectId,
  AsyncThunkConfig
>("projectSidebar/duplicateProject", async (id, thunkApi) => {
  try {
    return await projectRepository.duplicate(id);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const createProjectAttachment = createAsyncThunk<
  void,
  { projectId: ProjectId; body: AttachmentCreateRequest },
  AsyncThunkConfig
>(
  "projectSidebar/createProjectAttachment",
  async (thunkArg: { projectId: ProjectId; body: AttachmentCreateRequest }, thunkApi) => {
    try {
      return await attachmentRepository.create(thunkArg.projectId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateProjectAttachment = createAsyncThunk<
  AttachmentUpdateResponse,
  { projectId: ProjectId; id: ProjectAttachmentId; body: AttachmentUpdateRequest },
  AsyncThunkConfig
>(
  "projectSidebar/updateProjectAttachment",
  async (
    thunkArg: { projectId: ProjectId; id: ProjectAttachmentId; body: AttachmentUpdateRequest },
    thunkApi,
  ) => {
    try {
      return await attachmentRepository.update(thunkArg.projectId, thunkArg.id, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const destroyProjectAttachment = createAsyncThunk<
  AttachmentDestroyResponse,
  { projectId: ProjectId; id: ProjectAttachmentId },
  AsyncThunkConfig
>(
  "projectSidebar/destroyProjectAttachment",
  async (thunkArg: { projectId: ProjectId; id: ProjectAttachmentId }, thunkApi) => {
    try {
      return await attachmentRepository.destroy(thunkArg.projectId, thunkArg.id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const createProjectEstimate = createAsyncThunk<
  EstimateCreateResponse,
  { projectId: ProjectId; body: EstimateCreateRequest },
  AsyncThunkConfig
>(
  "projectSidebar/createProjectEstimate",
  async (thunkArg: { projectId: ProjectId; body: EstimateCreateRequest }, thunkApi) => {
    try {
      return await estimateRepository.create(thunkArg.projectId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);
