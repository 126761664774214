import { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  AccessTime,
  AssignmentOutlined,
  ChevronLeft,
  ChevronRight,
  EventOutlined,
  HandymanOutlined,
  MonetizationOnOutlined,
} from "@mui/icons-material";
import DomainIcon from "@mui/icons-material/Domain";
import GroupsIcon from "@mui/icons-material/Groups";
import { List, Divider, IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { ChartIcon } from "components/icon/chart-icon";
import { CompanySettingsIcon } from "components/icon/company-settings-icon";
import { UserSettingsIcon } from "components/icon/user-settings-icon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { styles } from "./styles";

export const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar = (props: Props) => {
  const { open, setOpen } = props;
  const theme = useTheme();
  const classes = styles();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectMain);
  const navigate = useNavigate();

  const handleDrawerClose = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const current = {
    backgroundColor: theme.palette.customPrimary[50],
    textDecoration: "none",
    width: "100%",
    display: "inline-block",
  };
  const activeMenuText = {
    color: theme.palette.primary.main,
  };
  const inactiveMenuText = {
    color: theme.palette.grayScale[900],
  };

  const handleOpenCompanySettings = () => {
    navigate("/company-settings/schedule-settings");
    setOpen(true);
    dispatch(mainOperations.updateIsCompanySettingToNavBar(true));
  };
  const handleCloseCompanySettings = () => {
    navigate("/projects");
    dispatch(mainOperations.updateIsCompanySettingToNavBar(false));
  };

  return (
    <Drawer variant="permanent" open={open}>
      {!state.isCompanySettingToNavBar ? (
        <>
          <DrawerHeader sx={{ justifyContent: "flex-end" }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <NavLink
              to="/calendar"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <EventOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary="スケジュール"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/projects"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <HandymanOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary="案件"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/buildings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <DomainIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="物件"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/clients"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <GroupsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="顧客"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/billings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon className={classes.listItemIcon}>
                    <MonetizationOnOutlined style={isActive ? activeMenuText : inactiveMenuText} />
                  </ListItemIcon>
                  <ListItemText
                    primary="請求"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/sales-aggregations"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ChartIcon color={isActive ? activeMenuText.color : inactiveMenuText.color} />
                  </ListItemIcon>
                  <ListItemText
                    primary="売上集計"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold", padding: "20px 12px 8px" }}>
              報告書
            </Typography>
            <NavLink
              to="/work-reports"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <AssignmentOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary="作業日報"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold", padding: "20px 12px 8px" }}>
              勤怠
            </Typography>
            <NavLink
              to="/attendances"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <AccessTime />
                  </ListItemIcon>
                  <ListItemText
                    primary="打刻管理"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold", padding: "20px 12px 8px" }}>
              設定
            </Typography>
            <NavLink
              to="/user-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={isActive ? activeMenuText : inactiveMenuText}
                  >
                    <UserSettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="個人設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            {state.permissions.master_write && (
              <div onClick={handleOpenCompanySettings} style={{ cursor: "pointer" }}>
                <ListItem>
                  <ListItemIcon className={classes.listItemIcon} style={inactiveMenuText}>
                    <CompanySettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="会社設定" style={inactiveMenuText} />
                  <ChevronRight />
                </ListItem>
              </div>
            )}
          </List>
        </>
      ) : (
        <>
          <DrawerHeader>
            <IconButton
              onClick={handleCloseCompanySettings}
              sx={{ gap: "20px", flex: 1, justifyContent: "normal" }}
            >
              <ChevronLeft />
              <Typography>もどる</Typography>
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold", padding: "8px 12px" }}>
              会社設定
            </Typography>
            <NavLink
              to="/company-settings/schedule-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="予定設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/company-settings/project-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="案件設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/company-settings/member-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="メンバー設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/company-settings/cost-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="材料・経費設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/company-settings/notice-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="通知設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/company-settings/template-settings"
              className={classes.link}
              style={({ isActive }) => (isActive ? current : {})}
            >
              {({ isActive }) => (
                <ListItem>
                  <ListItemText
                    primary="テンプレート設定"
                    style={isActive ? activeMenuText : inactiveMenuText}
                  />
                </ListItem>
              )}
            </NavLink>
          </List>
        </>
      )}
    </Drawer>
  );
};
