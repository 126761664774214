import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    imagesContainer: {
      backgroundColor: theme.palette.customPrimary[50],
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "5px",
      marginTop: "1rem",
      padding: "1rem",
      "& .MuiIconButton-root": {
        position: "absolute",
        right: 0,
      },
      alignItems: "center",
    },
    imageBox: {
      position: "relative",
      overflow: "hidden",
      margin: "0.7rem",
      borderRadius: "4px",
      height: "201px",
      width: "215px",
      textAlign: "center",
      border: `1px solid ${theme.palette.grayScale[300]}`,
      "&:hover": {
        "& .buttonBox": {
          display: "flex",
        },
      },
    },
    image: {
      width: "215px",
      height: "137px",
      objectFit: "cover",
    },
    dropzone: {
      display: "flex",
      margin: "0.7rem",
      borderRadius: "4px",
      height: "8.5rem",
      width: "208px",
    },
    name: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "2",
      height: "30px",
      textAlign: "left",
    },
    modal: {
      "& .MuiDialogContent-root": {
        border: "none",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      "& .MuiSvgIcon-root": {
        color: theme.palette.grayScale[0],
      },
    },
  }),
);
