import {
  DepositHistoryRequest,
  DepositHistoryCreateResponse,
  DepositHistoryUpdateResponse,
  DepositHistoryDestroyResponse,
  DepositHistoryResponse,
  BillingDepositHistoryId,
} from "./deposit_history.dto";
import { ApiClient } from "../../../ApiClient";
import { BillingId } from "../billing.dto";

export const depositHistoryRepository = {
  show(billingId: BillingId): Promise<DepositHistoryResponse[]> {
    return ApiClient.get(`/api/v1/billings/${billingId}/deposit_histories`).then((res) => res.data);
  },
  create(billingId: BillingId, body: DepositHistoryRequest): Promise<DepositHistoryCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      deposit_date: body.depositDate,
      deposit_amount: body.depositAmount,
    };

    return ApiClient.post(
      `/api/v1/billings/${billingId}/deposit_histories`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  update(
    billingId: BillingId,
    id: BillingDepositHistoryId,
    body: DepositHistoryRequest,
  ): Promise<DepositHistoryUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      deposit_date: body.depositDate,
      deposit_amount: body.depositAmount,
      deposit_method_type: body.depositMethodType,
    };
    return ApiClient.put(
      `/api/v1/billings/${billingId}/deposit_histories/${id}`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  destroy(
    billingId: BillingId,
    id: BillingDepositHistoryId,
  ): Promise<DepositHistoryDestroyResponse> {
    return ApiClient.delete(`/api/v1/billings/${billingId}/deposit_histories/${id}`).then(
      (res) => res.data,
    );
  },
};
