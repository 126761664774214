import { Brand } from "../brand";

export type ProjectTypeId = Brand<number, "ProjectTypeId">;

export interface ProjectType {
  id: ProjectTypeId;
  name: string;
}

export interface ProjectTypeRequest {
  name?: string;
  displayOrderPosition?: number;
}

export interface ProjectTypeCreateResponse {}

export interface ProjectTypeUpdateResponse {}

export interface ProjectTypeDestroyResponse {}

export const initProjectType: ProjectType = {
  id: 0 as ProjectTypeId,
  name: "",
};

export interface ProjectTypeIndexResponse extends Array<ProjectType> {}
