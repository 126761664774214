import { Brand } from "../brand";

export type ProjectStatusTypeId = Brand<number, "ProjectStatusTypeId">;

export interface ProjectStatusType {
  id: ProjectStatusTypeId;
  name: string;
  color_number: string;
  reserved_type: "no_reserved" | "completed" | "order_failed";
}

export interface ProjectStatusTypeRequest {
  name?: string;
  colorNumber?: string;
  displayOrderPosition?: number;
}

export interface ProjectStatusTypeIndexResponse extends Array<ProjectStatusType> {}

export interface ProjectStatusTypeCreateResponse {}

export interface ProjectStatusTypeUpdateResponse {}

export interface ProjectStatusTypeDestroyResponse {}

export const initialProjectStatusType: ProjectStatusType = {
  id: 0 as ProjectStatusTypeId,
  name: "",
  color_number: "",
  reserved_type: "no_reserved",
};
