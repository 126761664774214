import { jaJP } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { jaJP as gridJaJP } from "@mui/x-data-grid/locales";

export type MuiButtonColor =
  | "error"
  | "primary"
  | "inherit"
  | "secondary"
  | "success"
  | "info"
  | "warning";

export const theme = createTheme(
  {
    typography: {
      fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: "#001c5f",
        light: "#CCD2DF",
      },
      secondary: {
        main: "#0B817A",
        light: "#CEE6E4",
      },
      error: {
        main: "#CE4432",
      },
      customPrimary: {
        25: "#F9F9FB",
        50: "#F5F6F9",
        100: "#E5E8EF",
      },
      customSecondary: {
        100: "#E7F2F2",
      },
      grayScale: {
        0: "#fff",
        100: "#F2F2F2",
        200: "#E0E0E0",
        300: "#C2C2C2",
        500: "#9e9e9e",
        700: "#666",
        900: "#222",
      },
      red: {
        100: "#FAECEA",
        200: "#F5DAD6",
        500: "#CE4432",
      },
      blue: {
        100: "#ECF0F9",
        200: "#DBE2F2",
        400: "#829BD6",
        500: "#426DBF",
      },
      teal: {
        400: "#74CFCA",
      },
      green: {
        200: "#D8E9D0",
        500: "#3C8F14",
      },
      moss: {
        500: "#61760D",
      },
      mustard: {
        400: "#E8CD73",
        500: "#C7A021",
      },
      brown: {
        200: "#EDE0CC",
        500: "#A76400",
      },
      chocolate: {
        200: "#E5D8D1",
        500: "#7B3E19",
      },
      magenta: {
        500: "#CF347F",
      },
      purple: {
        500: "#911094",
      },
      lavender: {
        500: "#482FAB",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            height: "3rem",
          },
          outlined: {
            height: "3rem",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          autoComplete: "off",
        },
        styleOverrides: {
          root: {},
        },
        variants: [
          {
            props: {
              margin: "normal",
              size: "small",
              variant: "outlined",
            },
            style: {},
          },
        ],
      },
    },
  },
  jaJP,
  gridJaJP,
);

declare module "@mui/material/styles" {
  interface Palette {
    customPrimary: {
      25: string;
      50: string;
      100: string;
    };
    customSecondary: {
      100: string;
    };
    grayScale: {
      0: string;
      100: string;
      200: string;
      300: string;
      500: string;
      700: string;
      900: string;
    };
    red: {
      100: string;
      200: string;
      500: string;
    };
    blue: {
      100: string;
      200: string;
      400: string;
      500: string;
    };
    teal: {
      400: string;
    };
    green: {
      200: string;
      500: string;
    };
    moss: {
      500: string;
    };
    mustard: {
      400: string;
      500: string;
    };
    brown: {
      200: string;
      500: string;
    };
    chocolate: {
      200: string;
      500: string;
    };
    magenta: {
      500: string;
    };
    purple: {
      500: string;
    };
    lavender: {
      500: string;
    };
  }
  interface PaletteOptions {
    customPrimary?: {
      25?: string;
      50?: string;
      100?: string;
    };
    customSecondary?: {
      100?: string;
    };
    grayScale?: {
      0?: string;
      100?: string;
      200?: string;
      300?: string;
      500?: string;
      700?: string;
      900?: string;
    };
    red?: {
      100?: string;
      200?: string;
      500?: string;
    };
    blue?: {
      100?: string;
      200?: string;
      400?: string;
      500?: string;
    };
    teal?: {
      400?: string;
    };
    green?: {
      200?: string;
      500?: string;
    };
    moss?: {
      500?: string;
    };
    mustard?: {
      400?: string;
      500?: string;
    };
    brown?: {
      200?: string;
      500?: string;
    };
    chocolate?: {
      200?: string;
      500?: string;
    };
    magenta?: {
      500?: string;
    };
    purple?: {
      500?: string;
    };
    lavender?: {
      500?: string;
    };
  }
}
