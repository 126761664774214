import { ElementRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { ActivityBlock } from "components/InfoBlock/activity";
import { ProjectsInfoBlock } from "components/InfoBlock/projects";
import { BuildingSidebarHeader } from "components/organisms/building-sidebar-header";
import { BuildingSidebarInfo } from "components/organisms/building-sidebar-info";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { addressRepository } from "data-access/repositories/Address/postal_code.repository";
import { buildingActivityRepository } from "data-access/repositories/building/activity/activity.repository";
import {
  Building,
  BuildingId,
  BuildingRequest,
} from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { buildingProjectRepository } from "data-access/repositories/building/project/project.repository";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { ACTIVITY_PER_PAGE } from "utils/constant";
import { handleError } from "utils/errorHandler";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isCreateNew: boolean;
  fetchIndexKey: string;
}

export const BuildingSidebar = (props: Props) => {
  const { isOpen, onClose, isCreateNew, fetchIndexKey } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formState, setFormState] = useState<BuildingRequest>({});
  const state: Building = location.state;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangeValue, setIsChangeValue] = useState<boolean>(false);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>("");
  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const [limit, setLimit] = useState<number>(ACTIVITY_PER_PAGE);

  const { data: clients } = useSWR(isOpen ? "/api/v1/clients/all" : null, () =>
    clientRepository.all({ orderBy: "recently_created" }),
  );
  const { data: building, isValidating: isValidatingBuilding } = useSWR(
    state?.id ? `/api/v1/buildings/${state.id}` : null,
    () => buildingRepository.show(state.id),
  );
  const { data: projects, isValidating: isValidatingProject } = useSWR(
    state?.id ? `/api/v1/buildings/${state.id}/projects` : null,
    () => buildingProjectRepository.index(state.id),
  );
  const { data: activities, mutate: activityMutate } = useSWR(
    state?.id ? `/api/v1/projects/${state.id}/activities?limit=${limit}` : null,
    () => buildingActivityRepository.index(state.id, limit),
  );

  useEffect(() => {
    if (building) {
      setFormState({
        clientId: building?.client?.id,
        name: building?.name,
        personName: building?.person_name,
        postalCode: building?.postal_code,
        address: building?.address,
        email: building?.email,
        phoneNumber: building?.phone_number,
        phoneNumberSecond: building?.phone_number_second,
        faxNumber: building?.fax_number,
        note: building?.note,
      });
    }
  }, [building]);

  const handleClose = () => {
    onClose();
    setFormState({});
    setAddressErrorMessage("");
    setPostalCodeErrorMessage("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChangeValue(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
    setPostalCodeErrorMessage("");
    setAddressErrorMessage("");
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isChangeValue) return;
    setIsLoading(true);
    try {
      if (isCreateNew) {
        const res = await buildingRepository.create(formState);
        navigate(`/buildings/${res.id}`, { state: res });
        dispatch(mainOperations.updateSuccessMessage("物件を作成しました"));
      } else {
        await buildingRepository.update(state.id, formState);
        dispatch(mainOperations.updateSuccessMessage("物件を更新しました"));
      }
      mutate(fetchIndexKey);
    } catch (error) {
      if (e.target.name === "postalCode") setFormState({ ...formState, postalCode: "" });
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "エラーが発生しました",
      );
    } finally {
      setIsChangeValue(false);
      setIsLoading(false);
      activityMutate();
    }
  };

  const handleClickSearchAddress = async () => {
    if (!formState.postalCode) return;
    const postalCode = formState.postalCode?.replace(/-/g, "");
    setIsLoading(true);
    try {
      const res = await addressRepository.index(postalCode);
      const address = res.data[0].prefecture + res.data[0].city + res.data[0].town;
      try {
        await buildingRepository.update(state.id, { address, postalCode });
        setFormState({ ...formState, address });
        dispatch(mainOperations.updateSuccessMessage("住所を更新しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    } catch {
      setPostalCodeErrorMessage("入力した郵便番号から住所が見つかりませんでした");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickSearchPostalCode = async () => {
    const address = formState.address;
    if (!address) return;
    setIsLoading(true);
    try {
      const res = await postalCodeRepository.index(address);
      const postalCode = res.data[0].postal_code.replace(/-/g, "");
      try {
        await buildingRepository.update(state.id, { address, postalCode });
        setFormState({ ...formState, postalCode });
        dispatch(mainOperations.updateSuccessMessage("郵便番号を更新しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    } catch (error) {
      setAddressErrorMessage("入力した住所から郵便番号が見つかりませんでした");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: BuildingId) => {
    if (!deleteConfirmRef.current) return;
    const res = await deleteConfirmRef.current.confirm();

    if (res) {
      try {
        await buildingRepository.destroy(id);
        mutate(fetchIndexKey);
        handleClose();
        dispatch(mainOperations.updateSuccessMessage("物件を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  return (
    <DetailSidebar isOpen={isOpen} onClose={handleClose}>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading || isValidatingBuilding || isValidatingProject}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <BuildingSidebarHeader
        onClose={handleClose}
        onChange={handleChange}
        onBlur={handleBlur}
        onDelete={() => handleDelete(state.id)}
        isCreateNew={isCreateNew}
        formState={formState}
        setFormState={setFormState}
        clients={clients || []}
        buildingId={state?.id}
        fetchIndexKey={fetchIndexKey}
      />

      <BuildingSidebarInfo
        onChange={handleChange}
        onBlur={handleBlur}
        onClickSearchAddress={handleClickSearchAddress}
        onClickSearchPostalCode={handleClickSearchPostalCode}
        formState={formState}
        addressErrorMessage={addressErrorMessage}
        postalCodeErrorMessage={postalCodeErrorMessage}
      />

      <Divider sx={{ my: "24px" }} />
      <ProjectsInfoBlock projects={projects || []} />
      <Divider sx={{ my: "12px" }} />
      {!isCreateNew && (
        <ActivityBlock
          activities={activities?.data || []}
          totalCount={activities?.pagination.count || 0}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </DetailSidebar>
  );
};
