import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    dropzone: {
      position: "relative",
      backgroundColor: theme.palette.customPrimary[100],
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.grayScale[700]}`,
      "& .MuiIconButton-root": {
        position: "absolute",
        top: "1.8rem",
        right: "1rem",
      },
      "&. MuiInputBase-root-MuiInput-root-MuiSelect-root:before": {
        border: "none",
      },
    },
    imageBoxBlock: {
      backgroundColor: theme.palette.customPrimary[50],
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "5px",
      marginTop: "1rem",
      padding: "1rem",
      "& .MuiIconButton-root": {
        position: "absolute",
      },
      alignItems: "center",
    },
    name: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "2",
      height: "30px",
      textAlign: "left",
      marginBottom: "4px",
    },
    modal: {
      "& .MuiDialogContent-root": {
        border: "none",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      "& .MuiSvgIcon-root": {
        color: theme.palette.grayScale[0],
      },
    },
  }),
);
