import { ElementRef, useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { LinkButton } from "components/button/link-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import {
  PhotoResponse,
  PhotoUpdateRequest,
  ProjectPhotoId,
} from "data-access/repositories/project/photo/photo.dto";
import {
  photoRepository,
  photoRepository as projectPhotoRepository,
} from "data-access/repositories/project/photo/photo.repository";
import { Project, ProjectId } from "data-access/repositories/project/project.dto";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { photoRepository as workReportPhotoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import { WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { theme } from "extensions/theme";
import FileDownload from "images/file_download_icon.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import useSWR, { mutate } from "swr";
import { downloadImages } from "utils/downloadImages";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { ShareSettingDialog } from "../share-setting-dialog";

interface Props {
  open: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project: Project;
}

export const ProjectPhotosModal = ({ open, handleOpen, project }: Props) => {
  const classes = styles();
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(open)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      className={classes.modal}
    >
      <DialogTitle className={classes.title}>
        写真
        <div>
          <Button
            onClick={() => setIsOpenShareModal(true)}
            sx={{
              backgroundColor: theme.palette.grayScale[0],
              color: theme.palette.primary.main,
              p: "8px 12px",
              mr: "48px",
              "&:hover": {
                backgroundColor: theme.palette.grayScale[0],
              },
            }}
          >
            <LinkIcon
              size={16}
              color={project.photo_url_key ? theme.palette.blue[500] : theme.palette.primary.main}
            />
            <Typography
              sx={{
                color: project.photo_url_key ? theme.palette.blue[500] : theme.palette.primary.main,
                ml: "8px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {project.photo_url_key ? "リンクで共有中" : "リンクで共有"}
            </Typography>
          </Button>
          <IconButton className={classes.closeButton} onClick={() => handleOpen(open)}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <PhotosBlock />
      </DialogContent>

      <ShareSettingDialog
        open={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        id={project.id}
        resourceType="projects"
        photoUrlKey={project.photo_url_key}
      />
    </Dialog>
  );
};

interface CheckedPhoto extends PhotoResponse {
  checked: boolean;
}

const PhotosBlock = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectSidebar);
  const mainState = useAppSelector(selectMain);
  const fetchKey = `/api/v1/projects/${state.id}/photos`;
  const photoCountIndexKey = `${fetchKey}/count_info`;
  const { data: photos } = useSWR(state.id ? `/api/v1/projects/${state.id}/photos` : null, () =>
    photoRepository.index(state.id as ProjectId),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (photos: PhotoResponse[]) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();
    if (!res) return;
    setIsLoading(true);

    try {
      const deletePromises = photos.map((photo) => {
        if (photo.record_type === "project") {
          return projectPhotoRepository.destroy(
            state.id as ProjectId,
            photo.id as ProjectPhotoId,
            "project",
          );
        } else if (photo.record_type === "work_report" && photo.work_report_attributes) {
          return workReportPhotoRepository.destroy(
            photo.work_report_attributes.id,
            photo.id as WorkReportPhotoId,
          );
        }
      });

      await Promise.all(deletePromises);

      mutate(fetchKey);
      dispatch(mainOperations.updateSuccessMessage("写真を削除しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhotoUpload = async (
    files: File[] | FileList | null,
    photoType?: { value: string; valueI18n: string },
  ) => {
    if (files) {
      const requestObj: { photoType: { value: string; valueI18n: string }; files: File[] } = {
        photoType: { value: photoType?.value || "", valueI18n: photoType?.valueI18n || "" },
        files: [],
      };

      for (let i = 0; i < files.length; i++) {
        const targetPhoto = files[i];
        const photo = new File([targetPhoto], targetPhoto.name, {
          lastModified: targetPhoto.lastModified,
        });
        requestObj.files.push(photo);
      }

      setIsLoading(true);
      try {
        const response = await projectPhotoRepository.create(state.id as ProjectId, requestObj);
        const successfulUploads = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;

        mutate(fetchKey);

        const successfulUploadNumber = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        dispatch(
          mainOperations.updateSuccessMessage(
            `${response.length}枚中、${successfulUploadNumber}枚の写真をアップロードしました`,
          ),
        );
        if (successfulUploads === 0) {
          dispatch(mainOperations.updateErrorMessage("全ての写真のアップロードに失敗しました"));
        }
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePhotoPreviewClick = (photoId: number, recordType: string) => {
    if (!photos) return;

    dispatch(photosPreviewModalOperations.open());
    // 案件と作業日報の写真のidが重複しているときがあるため、recordTypeと合わせて検索する
    const createPhotoInfo = photos.map((photo) => {
      return {
        id: photo.id,
        type: photo.record_type,
      };
    });
    const clickedPhotoInfo = createPhotoInfo.filter(
      (photo) => photo.id === photoId && photo.type === recordType,
    );
    const clickedPhotoIndex = createPhotoInfo.indexOf(clickedPhotoInfo[0]);

    dispatch(photosPreviewModalOperations.updateCurrentIndex(clickedPhotoIndex));
    dispatch(photosPreviewModalOperations.setPhotos(photos));
  };

  // 写真を追加・削除したときにチェックボックスの状態を初期化する
  useEffect(() => {
    if (!photos) return;
    setCheckedPreviewPhotos(
      photos
        .filter((photo) => photo.photo_type?.value === "preview")
        .map((photo) => ({ ...photo, checked: false })),
    );
    setCheckedBeforePhotos(
      photos
        .filter((photo) => photo.photo_type?.value === "before")
        .map((photo) => ({ ...photo, checked: false })),
    );
    setCheckedUnderPhotos(
      photos
        .filter((photo) => photo.photo_type?.value === "under")
        .map((photo) => ({ ...photo, checked: false })),
    );
    setCheckedAfterPhotos(
      photos
        .filter((photo) => photo.photo_type?.value === "after")
        .map((photo) => ({ ...photo, checked: false })),
    );
  }, [photos]);

  const [checkedPreviewPhotos, setCheckedPreviewPhotos] = useState<CheckedPhoto[]>(
    photos
      ? photos
          .filter((photo) => photo.photo_type?.value === "preview")
          .map((photo) => ({ ...photo, checked: false }))
      : [],
  );
  const [checkedBeforePhotos, setCheckedBeforePhotos] = useState<CheckedPhoto[]>(
    photos
      ? photos
          .filter((photo) => photo.photo_type?.value === "before")
          .map((photo) => ({ ...photo, checked: false }))
      : [],
  );
  const [checkedUnderPhotos, setCheckedUnderPhotos] = useState<CheckedPhoto[]>(
    photos
      ? photos
          .filter((photo) => photo.photo_type?.value === "under")
          .map((photo) => ({ ...photo, checked: false }))
      : [],
  );
  const [checkedAfterPhotos, setCheckedAfterPhotos] = useState<CheckedPhoto[]>(
    photos
      ? photos
          .filter((photo) => photo.photo_type?.value === "after")
          .map((photo) => ({ ...photo, checked: false }))
      : [],
  );

  const bulkChecked = (v: boolean, photoType: string) => {
    const updateItemsCheckedStatus = (items: CheckedPhoto[]) => {
      return items.map((item) => {
        return {
          ...item,
          checked: v,
        };
      });
    };

    switch (photoType) {
      case "preview":
        setCheckedPreviewPhotos(updateItemsCheckedStatus(checkedPreviewPhotos));
        break;
      case "before":
        setCheckedBeforePhotos(updateItemsCheckedStatus(checkedBeforePhotos));
        break;
      case "under":
        setCheckedUnderPhotos(updateItemsCheckedStatus(checkedUnderPhotos));
        break;
      case "after":
        setCheckedAfterPhotos(updateItemsCheckedStatus(checkedAfterPhotos));
        break;
    }
  };

  const handleDownloadImages = (value: string, photoType: string) => {
    const getCheckedItemsList = (items: CheckedPhoto[]) => {
      return items
        .filter((item) => item.checked)
        .map((item) => {
          return {
            url: item.file_url,
            name: item.file_name,
          };
        });
    };

    let list: { url: string; name: string }[] = [];
    switch (photoType) {
      case "preview":
        list = getCheckedItemsList(checkedPreviewPhotos);
        break;
      case "before":
        list = getCheckedItemsList(checkedBeforePhotos);
        break;
      case "under":
        list = getCheckedItemsList(checkedUnderPhotos);
        break;
      case "after":
        list = getCheckedItemsList(checkedAfterPhotos);
        break;
    }
    downloadImages(list, `${state.project.name}(${value})写真`);
  };

  const handleDeleteImages = (value: string, photoType: string) => {
    const getCheckedItemsList = (items: CheckedPhoto[]): PhotoResponse[] => {
      return items
        .filter((item) => item.checked)
        .map((item) => {
          return {
            id: item.id,
            file_name: item.file_name,
            file_url: item.file_url,
            thumbnail_url: item.thumbnail_url,
            photo_type: item.photo_type,
            created_at: item.created_at,
            record_type: item.record_type,
            record_type_i18n: item.record_type_i18n,
            project_attributes: item.project_attributes,
            work_report_attributes: item.work_report_attributes,
            is_sharable: item.is_sharable,
          };
        });
    };

    let list: PhotoResponse[] = [];
    switch (photoType) {
      case "preview":
        list = getCheckedItemsList(checkedPreviewPhotos);
        break;
      case "before":
        list = getCheckedItemsList(checkedBeforePhotos);
        break;
      case "under":
        list = getCheckedItemsList(checkedUnderPhotos);
        break;
      case "after":
        list = getCheckedItemsList(checkedAfterPhotos);
        break;
    }
    handleDelete(list);
  };

  const clickPhotoCheck = (id: number, value: boolean, photoType: string) => {
    const updatePhotoCheckItems = (items: CheckedPhoto[]) => {
      return items.map((item) => {
        if (id === item.id) {
          return {
            ...item,
            checked: value,
          };
        } else {
          return item;
        }
      });
    };

    switch (photoType) {
      case "preview":
        setCheckedPreviewPhotos(updatePhotoCheckItems(checkedPreviewPhotos));
        break;
      case "before":
        setCheckedBeforePhotos(updatePhotoCheckItems(checkedBeforePhotos));
        break;
      case "under":
        setCheckedUnderPhotos(updatePhotoCheckItems(checkedUnderPhotos));
        break;
      case "after":
        setCheckedAfterPhotos(updatePhotoCheckItems(checkedAfterPhotos));
        break;
    }
  };

  /**
   * 写真の共有リンクタップ時の処理.
   *
   * @param photo 写真オブジェクト(画像ではないので注意).
   */
  const handleClickImageLinkButton = async (photo: PhotoResponse) => {
    const photoId = photo.id;
    const resourceName = photo.project_attributes ? "project" : "work_report";
    const isSharable = !photo.is_sharable;
    try {
      switch (resourceName) {
        case "project":
          await projectPhotoRepository.update(
            photo.project_attributes?.id as ProjectId,
            photoId as ProjectPhotoId,
            {
              isSharable: isSharable,
            } as PhotoUpdateRequest,
          );
          break;
        case "work_report":
          await workReportPhotoRepository.update(
            photo.work_report_attributes?.id as WorkReportId,
            photoId as WorkReportPhotoId,
            {
              isSharable: isSharable,
            } as PhotoUpdateRequest,
          );
          break;
      }
      mutate(fetchKey);
      mutate(photoCountIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const getPhotoCheckItems = (photoTypeValue: string) => {
    switch (photoTypeValue) {
      case "preview":
        return checkedPreviewPhotos.filter((item) => photoTypeValue === item.photo_type.value);
      case "before":
        return checkedBeforePhotos.filter((item) => photoTypeValue === item.photo_type.value);
      case "under":
        return checkedUnderPhotos.filter((item) => photoTypeValue === item.photo_type.value);
      case "after":
        return checkedAfterPhotos.filter((item) => photoTypeValue === item.photo_type.value);
      default:
        return [];
    }
  };

  const getPhotoItemsById = (photoTypeValue: string, photoId: number) => {
    switch (photoTypeValue) {
      case "preview":
        return checkedPreviewPhotos.filter((item) => photoId === item.id);
      case "before":
        return checkedBeforePhotos.filter((item) => photoId === item.id);
      case "under":
        return checkedUnderPhotos.filter((item) => photoId === item.id);
      case "after":
        return checkedAfterPhotos.filter((item) => photoId === item.id);
      default:
        return [];
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />

      {mainState.photoTypes.map((photoType, index) => {
        const item = getPhotoCheckItems(photoType.value);
        const checkedItemCount = item.filter((item) => item.checked).length;
        return (
          <Box sx={{ mb: "2rem" }} key={index}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: "14px",
                  height: "34px",
                  lineHeight: "34px",
                }}
              >
                {photoType.valueI18n}
              </Typography>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Typography
                  fontWeight="500"
                  onClick={() => bulkChecked(true, photoType.value)}
                  sx={{
                    fontSize: "12px",
                    height: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  color={theme.palette.primary.main}
                >
                  すべて選択
                </Typography>
                <Typography
                  fontWeight="500"
                  onClick={() => bulkChecked(false, photoType.value)}
                  sx={{
                    fontSize: "12px",
                    height: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  選択を解除
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleDownloadImages(photoType.valueI18n, photoType.value)}
                    disabled={checkedItemCount === 0}
                    sx={{
                      fontSize: "12px",
                      height: "30px",
                      borderRadius: "20px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <Box
                        sx={{ color: theme.palette.grayScale[0] }}
                        component="img"
                        src={FileDownload}
                      />
                    }
                  >
                    {item.filter((item) => item.checked).length}件をダウンロード
                  </Button>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDeleteImages(photoType.valueI18n, photoType.value)}
                    disabled={checkedItemCount === 0}
                    sx={{
                      fontSize: "12px",
                      height: "30px",
                      borderRadius: "20px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <DeleteIcon
                        size={16}
                        color={
                          checkedItemCount === 0
                            ? theme.palette.grayScale[300]
                            : theme.palette.red[500]
                        }
                      />
                    }
                  >
                    {item.filter((item) => item.checked).length}件を削除
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", flexWrap: "wrap", maxWidth: "750px" }}
              className={classes.imageBoxBlock}
            >
              {photos &&
                photos
                  .filter((photo) => photo.photo_type?.value === photoType.value)
                  .map((photo) => {
                    const item = getPhotoItemsById(photoType.value, photo.id);
                    return (
                      <Box
                        key={photo.id}
                        sx={{
                          position: "relative",
                          overflow: "hidden",
                          margin: "0.7rem",
                          borderRadius: "4px",
                          height: "201px",
                          width: "215px",
                          textAlign: "center",
                          border: `1px solid ${theme.palette.grayScale[300]}`,
                        }}
                      >
                        <Box
                          sx={{
                            borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                            height: "138px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            alt={`photo-${photo.id}`}
                            src={photo.thumbnail_url}
                            loading="lazy"
                            style={{
                              width: "215px",
                              height: "137px",
                              objectFit: "cover",
                            }}
                            onClick={() => handlePhotoPreviewClick(photo.id, photo.record_type)}
                          />
                          {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                          <input
                            type="checkbox"
                            checked={item[0]?.checked}
                            onChange={(e) =>
                              clickPhotoCheck(photo.id, e.target.checked, photo.photo_type.value)
                            }
                            style={{
                              position: "absolute",
                              top: "8px",
                              left: "8px",
                              width: "20px",
                              height: "20px",
                              accentColor: theme.palette.primary.main,
                            }}
                          />
                          <LinkButton
                            sx={{ top: "8px", right: "8px", position: "absolute" }}
                            isEnabled={photo.is_sharable}
                            handleClick={() => handleClickImageLinkButton(photo)}
                          />
                        </Box>
                        <Box sx={{ p: "8px", backgroundColor: theme.palette.grayScale[0] }}>
                          {photo.record_type === "project" && (
                            <>
                              <Typography className={classes.name} fontSize={11} fontWeight="bold">
                                {photo.project_attributes?.name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, textAlign: "left" }}
                              >
                                {formatDateUtil(new Date(photo.created_at), DATE_TIME_SLASH_FORMAT)}{" "}
                                アップロード
                              </Typography>
                            </>
                          )}
                          {photo.record_type === "work_report" && (
                            <>
                              <Typography className={classes.name} fontSize={11} fontWeight="bold">
                                {photo.work_report_attributes?.site_name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, textAlign: "left" }}
                              >
                                {timeRange(
                                  DATE_TIME_SLASH_FORMAT,
                                  photo.work_report_attributes?.start_time.toString(),
                                  photo.work_report_attributes?.end_time.toString(),
                                )}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0.7rem",
                  borderRadius: "4px",
                  height: "8.5rem",
                  width: "13rem",
                }}
              >
                <PhotoDropzone photoType={photoType} onDrop={handlePhotoUpload} />
              </Box>
            </Box>
          </Box>
        );
      })}
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading}
        invisible
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};
