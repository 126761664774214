import { useEffect, useState } from "react";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { photoRepository } from "data-access/repositories/photo/photo.repositories";
import { PhotoResponse } from "data-access/repositories/work_report/photo/photo.dto";
import { theme } from "extensions/theme";
import FileDownload from "images/file_download_icon.svg";
import Logo from "images/logo.svg";
import { ErrorPage404 } from "pages/error/error-page-404";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import useSWR from "swr";
import { downloadImageAll, downloadImages } from "utils/downloadImages";
import { fileDownload } from "utils/fileDownload";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";

export const ShareProjectPhotos = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const pathParts = window.location.pathname.split("/");
  const { data, error } = useSWR(`/api/v1/photos/${pathParts[2]}`, () =>
    photoRepository.index(pathParts[2]),
  );
  const photos = data?.photos;

  // リンクが不正な場合は404ページを表示
  if (error) {
    return (
      <>
        <ErrorPage404 />
      </>
    );
  }
  useEffect(() => {
    dispatch(mainOperations.getPhotoTypes());
  }, []);

  useEffect(() => {
    if (photos) {
      setPreviewPhotoCheckItems(
        photos
          .filter((photo) => photo.photo_type?.value === "preview")
          .map((photo) => ({ ...photo, checked: false })),
      );
      setBeforePhotoCheckItems(
        photos
          .filter((photo) => photo.photo_type?.value === "before")
          .map((photo) => ({ ...photo, checked: false })),
      );
      setUnderPhotoCheckItems(
        photos
          .filter((photo) => photo.photo_type?.value === "under")
          .map((photo) => ({ ...photo, checked: false })),
      );
      setAfterPhotoCheckItems(
        photos
          .filter((photo) => photo.photo_type?.value === "after")
          .map((photo) => ({ ...photo, checked: false })),
      );
    }
  }, [photos]);

  const handlePhotoPreviewClick = (photoId: number, recordType: string) => {
    if (!photos) return;

    dispatch(photosPreviewModalOperations.open());
    // 案件と作業日報の写真のidが重複しているときがあるため、recordTypeと合わせて検索する
    const createPhotoInfo = photos.map((photo) => {
      return {
        id: photo.id,
        type: photo.record_type,
      };
    });

    const clickedPhotoInfo = createPhotoInfo.filter(
      (photo) => photo.id === photoId && photo.type === recordType,
    );
    const clickedPhotoIndex = createPhotoInfo.indexOf(clickedPhotoInfo[0]);

    dispatch(photosPreviewModalOperations.updateCurrentIndex(clickedPhotoIndex));
    dispatch(photosPreviewModalOperations.setPhotos(photos));
  };

  const [previewPhotoCheckItems, setPreviewPhotoCheckItems] = useState<
    (PhotoResponse & { checked: boolean })[]
  >([]);
  const [beforePhotoCheckItems, setBeforePhotoCheckItems] = useState<
    (PhotoResponse & { checked: boolean })[]
  >([]);
  const [underPhotoCheckItems, setUnderPhotoCheckItems] = useState<
    (PhotoResponse & { checked: boolean })[]
  >([]);
  const [afterPhotoCheckItems, setAfterPhotoCheckItems] = useState<
    (PhotoResponse & { checked: boolean })[]
  >([]);

  const bulkChecked = (v: boolean, photoType: string) => {
    switch (photoType) {
      case "preview":
        {
          const items = previewPhotoCheckItems.map((item) => {
            return {
              ...item,
              checked: v,
            };
          });
          setPreviewPhotoCheckItems(items);
        }
        break;
      case "before":
        {
          const items = beforePhotoCheckItems.map((item) => {
            return {
              ...item,
              checked: v,
            };
          });
          setBeforePhotoCheckItems(items);
        }
        break;
      case "under":
        {
          const items = underPhotoCheckItems.map((item) => {
            return {
              ...item,
              checked: v,
            };
          });
          setUnderPhotoCheckItems(items);
        }
        break;
      case "after":
        {
          const items = afterPhotoCheckItems.map((item) => {
            return {
              ...item,
              checked: v,
            };
          });
          setAfterPhotoCheckItems(items);
        }
        break;
    }
  };

  const clickDownloadImages = (value: string, photoType: string) => {
    let list: { url: string; name: string }[] = [];
    switch (photoType) {
      case "preview":
        list = previewPhotoCheckItems
          .filter((item) => item.checked)
          .map((item) => {
            return {
              url: item.file_url,
              name: item.file_name,
            };
          });
        break;
      case "before":
        list = beforePhotoCheckItems
          .filter((item) => item.checked)
          .map((item) => {
            return {
              url: item.file_url,
              name: item.file_name,
            };
          });
        break;
      case "under":
        list = underPhotoCheckItems
          .filter((item) => item.checked)
          .map((item) => {
            return {
              url: item.file_url,
              name: item.file_name,
            };
          });
        break;
      case "after":
        list = afterPhotoCheckItems
          .filter((item) => item.checked)
          .map((item) => {
            return {
              url: item.file_url,
              name: item.file_name,
            };
          });
        break;
      default:
        if (photos) {
          const photosByType = photos.reduce<{ [key: string]: typeof photos }>((acc, photo) => {
            const type = photo.photo_type.value_i18n;
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(photo);
            return acc;
          }, {});
          return downloadImageAll(photosByType, `${data.project_name}(すべての写真)`);
        }
    }
    downloadImages(list, `${data?.project_name}(${value})写真`);
  };

  const clickPhotoCheck = (id: number, value: boolean, photoType: string) => {
    switch (photoType) {
      case "preview":
        {
          const items = previewPhotoCheckItems.map((item) => {
            if (id === item.id) {
              return {
                ...item,
                checked: value,
              };
            } else {
              return item;
            }
          });
          setPreviewPhotoCheckItems(items);
        }
        break;
      case "before":
        {
          const items = beforePhotoCheckItems.map((item) => {
            if (id === item.id) {
              return {
                ...item,
                checked: value,
              };
            } else {
              return item;
            }
          });
          setBeforePhotoCheckItems(items);
        }
        break;
      case "under":
        {
          const items = underPhotoCheckItems.map((item) => {
            if (id === item.id) {
              return {
                ...item,
                checked: value,
              };
            } else {
              return item;
            }
          });
          setUnderPhotoCheckItems(items);
        }
        break;
      case "after":
        {
          const items = afterPhotoCheckItems.map((item) => {
            if (id === item.id) {
              return {
                ...item,
                checked: value,
              };
            } else {
              return item;
            }
          });
          setAfterPhotoCheckItems(items);
        }
        break;
    }
  };

  return (
    <>
      <PhotoPreviewModal />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "4rem",
          backgroundColor: theme.palette.primary.main,
          pl: "1.5rem",
        }}
      >
        <img src={Logo} alt="logo" style={{ width: 150 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "1.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold", pr: "24px" }}>
            {data?.project_name}
          </Typography>
          <Button
            variant="contained"
            onClick={() => clickDownloadImages("すべて", "")}
            sx={{
              fontSize: "0.75rem",
              height: "1.875rem",
              borderRadius: "1.25rem",
              fontWeight: "500",
            }}
            startIcon={
              <Box sx={{ color: theme.palette.grayScale[0] }} component="img" src={FileDownload} />
            }
          >
            一括ダウンロード
          </Button>
        </Box>
        <Box style={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>作成企業:</Typography>
          <Typography sx={{ pl: "0.5rem" }}>{data?.company_name}</Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: "3rem" }} />

      <Box sx={{ p: "0 1.5rem 2rem" }}>
        {mainState.photoTypes.map((photoType, index) => {
          let item: (PhotoResponse & { checked: boolean })[] = [];
          switch (photoType.value) {
            case "preview":
              item = previewPhotoCheckItems.filter((item) => {
                return photoType.value === item.photo_type.value;
              });
              break;
            case "before":
              item = beforePhotoCheckItems.filter((item) => {
                return photoType.value === item.photo_type.value;
              });
              break;
            case "under":
              item = underPhotoCheckItems.filter((item) => {
                return photoType.value === item.photo_type.value;
              });
              break;
            case "after":
              item = afterPhotoCheckItems.filter((item) => {
                return photoType.value === item.photo_type.value;
              });
              break;
          }
          return (
            <Box sx={{ mb: "2rem" }} key={index}>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: "14px",
                    height: "34px",
                    lineHeight: "34px",
                  }}
                >
                  {photoType.valueI18n}
                </Typography>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      onClick={() => clickDownloadImages(photoType.valueI18n, photoType.value)}
                      disabled={item.filter((item) => item.checked).length === 0}
                      sx={{
                        fontSize: "12px",
                        height: "30px",
                        lineHeight: "30px",
                        borderRadius: "20px",
                        fontWeight: "500",
                      }}
                      startIcon={
                        <Box
                          sx={{ color: theme.palette.grayScale[0] }}
                          component="img"
                          src={FileDownload}
                        />
                      }
                    >
                      {item.filter((item) => item.checked).length}件をダウンロード
                    </Button>
                  </Box>
                  <Typography
                    fontWeight="500"
                    onClick={() => bulkChecked(true, photoType.value)}
                    sx={{
                      fontSize: "12px",
                      color: theme.palette.primary.main,
                      height: "100%",
                      lineHeight: "30px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    すべて選択
                  </Typography>
                  <Typography
                    fontWeight="500"
                    onClick={() => bulkChecked(false, photoType.value)}
                    sx={{
                      fontSize: "12px",
                      height: "100%",
                      lineHeight: "30px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    選択を解除
                  </Typography>
                </Box>
              </Box>
              {item.length > 0 ? (
                <>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }} className={classes.imageBoxBlock}>
                    {photos &&
                      photos
                        .filter((photo) => photo.photo_type?.value === photoType.value)
                        .map((photo) => {
                          let item: (PhotoResponse & { checked: boolean })[] = [];
                          switch (photoType.value) {
                            case "preview":
                              item = previewPhotoCheckItems.filter((item) => {
                                return photo.id === item.id;
                              });
                              break;
                            case "before":
                              item = beforePhotoCheckItems.filter((item) => {
                                return photo.id === item.id;
                              });
                              break;
                            case "under":
                              item = underPhotoCheckItems.filter((item) => {
                                return photo.id === item.id;
                              });
                              break;
                            case "after":
                              item = afterPhotoCheckItems.filter((item) => {
                                return photo.id === item.id;
                              });
                              break;
                          }
                          return (
                            <Box
                              key={photo.id}
                              sx={{
                                position: "relative",
                                overflow: "hidden",
                                margin: "0.7rem",
                                borderRadius: "4px",
                                height: "201px",
                                width: "215px",
                                textAlign: "center",
                                border: `1px solid ${theme.palette.grayScale[200]}`,
                              }}
                            >
                              <Box
                                sx={{
                                  borderBottom: `1px solid ${theme.palette.grayScale[200]}`,
                                  height: "138px",
                                }}
                              >
                                <img
                                  alt={`photo-${photo.id}`}
                                  src={photo.thumbnail_url}
                                  loading="lazy"
                                  style={{
                                    width: "215px",
                                    height: "137px",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() =>
                                    handlePhotoPreviewClick(photo.id, photo.record_type)
                                  }
                                />
                                {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                                <input
                                  type="checkbox"
                                  checked={item[0]?.checked}
                                  onChange={(e) =>
                                    clickPhotoCheck(
                                      photo.id,
                                      e.target.checked,
                                      photo.photo_type.value,
                                    )
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "8px",
                                    left: "8px",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    accentColor: theme.palette.primary.main,
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    backgroundColor: theme.palette.grayScale[0],
                                    color: theme.palette.text.primary,
                                    mr: "8px",
                                    border: `1px solid ${theme.palette.grayScale[200]}`,
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    width: "24px",
                                    height: "24px",
                                    top: "5px",
                                  }}
                                  onClick={() => fileDownload(photo.file_url, photo.file_name)}
                                >
                                  <FileDownloadOutlined
                                    sx={{ width: "13.33px", height: "13.33px" }}
                                  />
                                </IconButton>
                              </Box>
                              <Box sx={{ p: "8px" }}>
                                {photo.record_type === "project" && (
                                  <>
                                    <Typography
                                      className={classes.name}
                                      sx={{ mb: "4px", fontSize: "11px" }}
                                    >
                                      {photo.project_attributes?.name}
                                    </Typography>
                                    <Typography
                                      fontSize={10}
                                      sx={{
                                        color: theme.palette.grayScale[700],
                                        textAlign: "left",
                                      }}
                                    >
                                      {formatDateUtil(
                                        new Date(photo.created_at),
                                        DATE_TIME_SLASH_FORMAT,
                                      )}{" "}
                                      アップロード
                                    </Typography>
                                  </>
                                )}
                                {photo.record_type === "work_report" && (
                                  <>
                                    <Typography
                                      className={classes.name}
                                      sx={{ mb: "4px", fontSize: "11px" }}
                                    >
                                      {photo.work_report_attributes?.site_name}
                                    </Typography>
                                    <Typography
                                      fontSize={10}
                                      sx={{
                                        color: theme.palette.grayScale[700],
                                        textAlign: "left",
                                      }}
                                    >
                                      {timeRange(
                                        DATE_TIME_SLASH_FORMAT,
                                        photo.work_report_attributes?.start_time.toString(),
                                        photo.work_report_attributes?.end_time.toString(),
                                      )}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                  </Box>
                </>
              ) : (
                <Typography sx={{ mt: "12px" }}>写真がありません</Typography>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};
