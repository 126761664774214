import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { router } from "routes";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";

export default function Main() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(mainOperations.getAuth());
    dispatch(mainOperations.getCompany());
    dispatch(mainOperations.getProjectTypes());
    dispatch(mainOperations.getPhotoTypes());
    dispatch(mainOperations.getProjectStatusTypes());
    dispatch(mainOperations.getUsers());
    dispatch(mainOperations.getCompanyUsers());
    dispatch(mainOperations.getProjectTodoTagTypes());
    dispatch(mainOperations.getScheduleTypes());
    dispatch(mainOperations.getPersonalSetting());
  }, []);
  return (
    <>
      <CssBaseline />
      <RouterProvider router={router} />
    </>
  );
}
