import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Draggable } from "@fullcalendar/interaction";
import { Box, Typography } from "@mui/material";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { theme } from "extensions/theme";
import { scheduleTitle } from "utils/convertScheduleToCalendarEvent";
import { styles } from "./styles";

interface Props {
  setIsOpen: (value: boolean) => void;
  events: ScheduleList[];
}

export const NoDatedScheduleBlock = ({ events, setIsOpen }: Props) => {
  const navigate = useNavigate();
  const classes = styles();

  useEffect(() => {
    const draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl!, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        const id = eventEl.dataset.id;
        const title = eventEl.dataset.title;
        const isConfirmed = eventEl.dataset.isconfirmed;
        const users = eventEl.dataset.users ? JSON.parse(eventEl.dataset.users) : [];

        return {
          id,
          title,
          extendedProps: {
            isConfirmed,
            users,
          },
          create: true,
        };
      },
    });
  }, []);

  const handleClick = (event: ScheduleList) => {
    navigate(`/calendar/${event.id}`);
    setIsOpen(true);
  };

  return (
    <Box
      id="external-events"
      className={classes.noDateEvents}
      sx={{ position: "fixed", maxHeight: "90vh", overflowY: "auto" }}
    >
      <Typography sx={{ fontWeight: "bold" }}>日付未設定の予定</Typography>
      {events.map((event: ScheduleList) => (
        <div
          className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
          data-id={event.id}
          data-title={event.name}
          data-isconfirmed={event.is_confirmed}
          data-users={JSON.stringify(event.users)}
          key={event.id}
          style={{
            cursor: "pointer",
            padding: "16px 12px",
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.customPrimary[50],
            color: theme.palette.text.primary,
            marginTop: "1rem",
          }}
          onClick={() => handleClick(event)}
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              fontSize: "0.78rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "2",
            }}
          >
            {scheduleTitle(event.name, event.display_name, event.users)}
          </Typography>
        </div>
      ))}
    </Box>
  );
};
