import { Brand } from "../brand";

export type ScheduleTypeId = Brand<number, "ScheduleTypeId">;

export interface ScheduleType {
  id: ScheduleTypeId;
  name: string;
  color_number: string;
  is_default_all_day: boolean;
  is_holiday: boolean;
}

export interface ScheduleTypeRequest {
  name?: string;
  colorNumber?: string;
  displayOrderPosition?: number;
}

export interface ScheduleTypeShowResponse extends ScheduleType {}

export interface ScheduleTypeCreateResponse {}

export interface ScheduleTypeUpdateResponse {}

export interface ScheduleTypeDestroyResponse {}

export interface ScheduleTypeIndexResponse extends Array<ScheduleType> {}

export const initialScheduleType: ScheduleType = {
  id: 0 as ScheduleTypeId,
  name: "",
  color_number: "",
  is_default_all_day: false,
  is_holiday: false,
};
