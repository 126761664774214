import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import { LinkIcon } from "components/icon/link-icon";
import { theme } from "extensions/theme";

interface Props {
  sx?: SxProps<Theme>;
  isEnabled: boolean;
  handleClick: () => void;
}

/** 案件、作業日報などの写真の共有リンクで、共有可否を設定する. */
export const LinkButton = (props: Props) => {
  return (
    <Tooltip title={props.isEnabled ? "リンクで共有" : "非共有"}>
      <IconButton
        sx={{
          ...props.sx,
          width: "25px",
          height: "25px",
          padding: "4px",
          backgroundColor: `${props.isEnabled ? theme.palette.blue[500] : theme.palette.grayScale[0]}`,
          border: `1px solid ${props.isEnabled ? theme.palette.grayScale[0] : theme.palette.grayScale[300]}`,
          "&:hover": {
            backgroundColor: `${props.isEnabled ? theme.palette.blue[500] : theme.palette.grayScale[0]}`,
          },
        }}
        onClick={props.handleClick}
      >
        <LinkIcon
          color={props.isEnabled ? theme.palette.grayScale[0] : theme.palette.grayScale[700]}
          size={20}
        />
      </IconButton>
    </Tooltip>
  );
};
