import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Layout } from "components/templates/layout";
import { theme } from "extensions/theme";
import { Project } from "./project";
import { Schedule } from "./schedule";

const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-left: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
    & .MuiButtonBase-root.MuiTab-root {
      color: ${theme.palette.primary.main};
      width: 10rem;
      border-top: 1px solid ${theme.palette.primary.main};
      border-right: 1px solid ${theme.palette.primary.main};
    }
    & .MuiButtonBase-root.MuiTab-root.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.grayScale[0]};
    }
  }
`;

type TabType = "schedule" | "project";

export const NoticeSetting = () => {
  const [tab, setTab] = useState<TabType>("schedule");

  return (
    <Layout>
      <Typography fontWeight="bold" fontSize="24px" mb="20px">
        通知設定
      </Typography>

      <Box sx={{ mb: 2 }}>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => {
            setTab(newValue);
          }}
        >
          <Tab value="schedule" label="予定" />
          <Tab value="project" label="案件" />
        </CustomTabs>
      </Box>

      {tab === "schedule" && <Schedule />}
      {tab === "project" && <Project />}
    </Layout>
  );
};
