import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProjectIndexResponse,
  ProjectIndexRequest,
  ProjectDestroyResponse,
  ProjectId,
  ProjectCollection,
  ProjectDownloadRequest,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

// createAsyncThunk<`PayloadCreatorの返却値の型`, `PayloadCreatorの第一引数の型`, `PayloadCreatorの第二引数(ThunkAPI)の型`>
export const getIndexProject = createAsyncThunk<
  ProjectIndexResponse,
  ProjectIndexRequest,
  AsyncThunkConfig
>("projectTable/index", async (query, thunkApi) => {
  try {
    return await projectRepository.index(query);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const destroyProject = createAsyncThunk<ProjectDestroyResponse, ProjectId, AsyncThunkConfig>(
  "projectTable/destroyProject",
  async (id, thunkApi) => {
    try {
      return await projectRepository.destroy(id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const downloadProject = createAsyncThunk<
  ProjectCollection[],
  ProjectDownloadRequest,
  AsyncThunkConfig
>("projectTable/downloadProject", async (query, thunkApi) => {
  try {
    return await projectRepository.download(query);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});
