export interface Token {
  id: number;
  name: string;
  email: string;
  company_id: number;
  company_name: string;
  permissions: Permissions;
}

export interface Permissions {
  master_write: boolean;
}

export const initialPermissions: Permissions = {
  master_write: false,
};

export interface TokenCreateRequest extends Token {}
