import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import type { RootState } from "../store";

interface PhotosPreviewModalState {
  isOpen: boolean;
  errorMessage: string;
  currentIndex: number;
  photos: PhotoResponse[];
}

const initialState: PhotosPreviewModalState = {
  isOpen: false,
  errorMessage: "",
  currentIndex: 0,
  photos: [],
};

export const photosPreviewModalSlice = createSlice({
  name: "photosPreviewModal",
  initialState,
  reducers: {
    updateCurrentIndex: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      currentIndex: action.payload.value,
    }),
    setPhotos: (state, action: PayloadAction<{ photos: PhotoResponse[] }>) => ({
      ...state,
      photos: action.payload.photos,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: () => {},
});

export const selectPhotosPreviewModal = (state: RootState) => state.photosPreviewModal;
export default photosPreviewModalSlice.reducer;
