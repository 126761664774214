import { useEffect } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { FileDropzone } from "components/molecules/file-dropzone";
import { PdfFileBlock } from "components/molecules/pdf-file-block";
import { BillingInvoiceId } from "data-access/repositories/billing/invoice/invoice.dto";
import { invoiceRepository } from "data-access/repositories/billing/invoice/invoice.repository";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { EXCEL_EXTENSION, PDF_EXTENSION } from "utils/constant";
import { isContainExtensions } from "utils/isContainExtensions";

export const BillingSidebarInvoicesBlock = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBillingSidebar);

  useEffect(() => {
    state.uploadedInvoices.length > 0 &&
      dispatch(
        billingSidebarOperations.createInvoice(state.billing.id, {
          files: state.uploadedInvoices,
        }),
      );
  }, [state.uploadedInvoices]);

  useEffect(() => {
    if (state.billing.id > 0) {
      dispatch(billingSidebarOperations.showInvoice(state.billing.id));
    }
  }, [state.billing.id, state.isInvoiceSubmitted]);

  useEffect(() => {
    if (state.isInvoiceSubmitted) {
      dispatch(billingSidebarOperations.showBilling(state.billing.id));
    }
  }, [state.isInvoiceSubmitted]);

  const handlePdfFileUpload = (files: File[]) => {
    dispatch(billingSidebarOperations.setUploadedInvoices(files));
  };

  const handleDeleteInvoiceClick = (id: number) => {
    dispatch(
      deleteConfirmDialogOperations.setObject({
        id,
        type: "invoice",
      }),
    );
    dispatch(deleteConfirmDialogOperations.open());
  };

  const handleClickPreview = async (file: { id: number; name: string; url: string }) => {
    if (isContainExtensions(file.name, [PDF_EXTENSION])) {
      window.open(file.url);
      return;
    }
    if (isContainExtensions(file.name, EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      const result = await invoiceRepository.excelFilePreview(
        state.billing.id,
        file.id as BillingInvoiceId,
      );
      window.open(result.url, "_blank");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClickDownloadBillingTemplate = () => {
    dispatch(
      billingSidebarOperations.downloadTemplateBilling(
        state.billing.id,
        `【請求書】${state.billing.code} ${state.billing.subject}.xlsx`,
      ),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomFormLabel labelName="請求書" sx={{ mb: 0 }} />
        <div style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
          <PrimaryButton
            name="請求書Excelをダウンロード"
            onClick={handleClickDownloadBillingTemplate}
            disabled={!state.billing.billing_date || state.isLoading}
            sx={{ textTransform: "none", ml: 0 }}
          />
          {!state.billing.billing_date && (
            <Tooltip title="請求書Excelをダウンロードするには、請求日を入力してください">
              <ErrorIcon color="error" />
            </Tooltip>
          )}
        </div>
      </div>

      {state.invoices.map((invoice) => {
        return (
          <PdfFileBlock
            key={invoice.id}
            pdfFile={{
              id: invoice.id,
              name: invoice.file_name,
              url: invoice.file_url,
              uploadedDate: invoice.created_date,
            }}
            onDelete={handleDeleteInvoiceClick}
            handleClickPreview={handleClickPreview}
            availableExtensions={[PDF_EXTENSION, ...EXCEL_EXTENSION]}
          />
        );
      })}
      <FileDropzone onDrop={handlePdfFileUpload} />
    </div>
  );
};
