import { makeStyles } from "@mui/styles";
import { theme } from "../../../extensions/theme";

export const useStyles = makeStyles(() => ({
  icon: {
    minWidth: "26px",
    height: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: "8px",
    backgroundColor: (props: { isRead: boolean }) =>
      props.isRead ? theme.palette.customPrimary[100] : theme.palette.primary.main,
  },
  title: {
    fontSize: "12px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: (props: { isRead: boolean }) =>
      props.isRead ? "bold !important" : "500 !important",
    color: (props: { isRead: boolean }) => (props.isRead ? theme.palette.primary.main : "inherit"),
  },
}));
