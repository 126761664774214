import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  PhotoCountResponse,
  PhotoCreateRequest,
  PhotoCreateResponse,
  PhotoDestroyResponse,
  PhotoResponse,
  PhotoUpdateRequest,
  ProjectPhotoId,
} from "./photo.dto";
import { ProjectId } from "../project.dto";

export const photoRepository = {
  async create(projectId: ProjectId, body: PhotoCreateRequest): Promise<PhotoCreateResponse[]> {
    return Promise.all(
      body.files.map(async (file) => {
        try {
          const preSignedUrlResponse = await ApiClient.get(
            `/api/v1/projects/${projectId}/photos/put_presigned_url?file_name=${encodeURIComponent(
              file.name,
            )}`,
          );

          const preSignedUrlData = preSignedUrlResponse.data;
          if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
            throw new Error("Failed to get presigned URL");
          }

          const uploadResponse = await fetch(preSignedUrlData.url, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
            },
          });

          if (uploadResponse.status !== 200) {
            throw new Error("Failed to upload file");
          }

          await ApiClient.post(`/api/v1/projects/${projectId}/photos`, {
            file_name: file.name,
            object_key: preSignedUrlData.object_key,
            photo_type: body.photoType.value,
          });
          return { upload_status: "success" };
        } catch (error) {
          return { upload_status: "fail" };
        }
      }),
    );
  },
  index(projectId: ProjectId): Promise<PhotoResponse[]> {
    return ApiClient.get(`/api/v1/projects/${projectId}/photos`).then((res) => res.data);
  },
  update(projectId: ProjectId, id: ProjectPhotoId, body: PhotoUpdateRequest): Promise<any> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = convertKeysToSnakeCase(body);
    return ApiClient.put(`/api/v1/projects/${projectId}/photos/${id}`, requestBody, config).then(
      (res) => res.data,
    );
  },
  destroy(
    projectId: ProjectId,
    id: ProjectPhotoId,
    recordType: string,
  ): Promise<PhotoDestroyResponse> {
    const params = { record_type: recordType };
    return ApiClient.delete(`/api/v1/projects/${projectId}/photos/${id}`, { params }).then(
      (res) => res.data,
    );
  },
  countInfo(projectId: ProjectId): Promise<PhotoCountResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/photos/count_info`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};
