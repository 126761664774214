import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialPermissions, Permissions } from "data-access/cookie/token.dto";
import { CompanyShowResponse, initialCompany } from "data-access/repositories/company/company.dto";
import { CompanyUser } from "data-access/repositories/company_user/company_user.dto";
import {
  initialPersonalSetting,
  PersonalSetting,
} from "data-access/repositories/personal_setting/personal_setting.dto";
import { PhotoType } from "data-access/repositories/photo_type/photo_type.dto";
import { ProjectStatusType } from "data-access/repositories/project_status_type/project_status_type.dto";
import { ProjectType } from "data-access/repositories/project_type/project_type.dto";
import { ScheduleType } from "data-access/repositories/schedule_type/schedule_type.dto";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { User } from "data-access/repositories/user/user.dto";
import {
  getCompany,
  getPhotoTypes,
  getProjectTypes,
  getProjectStatusTypes,
  getUsers,
  getProjectTodoTagTypes,
  getPersonalSetting,
  getAuth,
  getScheduleTypes,
  getCompanyUsers,
} from "./actions";
import type { RootState } from "../store";

export interface mainState {
  isLoading: boolean;
  isCompanySettingToNavBar: boolean;
  successMessage: string;
  errorMessage: string;
  permissions: Permissions;
  company: CompanyShowResponse;
  projectTypes: ProjectType[];
  projectStatusTypes: ProjectStatusType[];
  photoTypes: { value: string; valueI18n: string }[];
  users: User[];
  companyUsers: CompanyUser[];
  projectTodoTagTypes: TodoTagType[];
  scheduleTypes: ScheduleType[];
  personalSetting: PersonalSetting;
}

const initialState: mainState = {
  isLoading: false,
  isCompanySettingToNavBar: false,
  successMessage: "",
  errorMessage: "",
  permissions: initialPermissions,
  company: initialCompany,
  projectTypes: [],
  projectStatusTypes: [],
  photoTypes: [],
  users: [],
  companyUsers: [],
  projectTodoTagTypes: [],
  scheduleTypes: [],
  personalSetting: initialPersonalSetting,
};

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    updateIsLoading: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isLoading: action.payload.value,
    }),
    updateIsCompanySettingToNavBar: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isCompanySettingToNavBar: action.payload.value,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAuth.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAuth.fulfilled, (state, action) => {
      state.permissions = action.payload.data.permissions;
      state.isLoading = false;
    });
    builder.addCase(getAuth.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.company = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectTypes.fulfilled, (state, action) => {
      state.projectTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getProjectTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectStatusTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectStatusTypes.fulfilled, (state, action) => {
      state.projectStatusTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getProjectStatusTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getPhotoTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPhotoTypes.fulfilled, (state, action) => {
      state.photoTypes = action.payload.map((photoType: PhotoType) => {
        return {
          value: photoType.value,
          valueI18n: photoType.value_i18n,
        };
      });
      state.isLoading = false;
    });
    builder.addCase(getPhotoTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getCompanyUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.companyUsers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCompanyUsers.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectTodoTagTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectTodoTagTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectTodoTagTypes = action.payload;
    });
    builder.addCase(getProjectTodoTagTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getScheduleTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScheduleTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.scheduleTypes = action.payload;
    });
    builder.addCase(getScheduleTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getPersonalSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPersonalSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.personalSetting = action.payload;
    });
    builder.addCase(getPersonalSetting.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectMain = (state: RootState) => state.main;
export default mainSlice.reducer;
