import React from "react";
import { HandymanOutlined } from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { NoticeLog } from "data-access/repositories/notice/log/logs.dto";
import { theme } from "extensions/theme";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { useStyles } from "./styles";

interface Props {
  data: NoticeLog;
}
export const NoticeLogBlock = ({ data }: Props) => {
  const classes = useStyles({ isRead: data.isRead });

  const handleClick = () => {
    switch (data.noticeType) {
      case "schedules":
      case "confirmed_schedule_changes":
      case "confirmed_schedule_creations":
      case "confirmed_schedule_assignment_changes":
        openURLInNewTab(`calendar/${data.payload.scheduleId}`);
        break;
      case "next_day_schedule_summaries":
        openURLInNewTab("calendar/");
        break;
      case "project_status_changes":
        openURLInNewTab(`projects/${data.payload.projectId}`);
        break;
      case "confirmed_schedule_deletions":
        break;
    }
  };

  return (
    <MenuItem sx={{ alignItems: "start", p: "8px" }} onClick={handleClick}>
      <Box className={classes.icon}>
        {data.noticeType === "project_status_changes" ? (
          <HandymanOutlined
            fontSize="small"
            style={{
              color: data.isRead ? theme.palette.primary.main : theme.palette.grayScale[0],
            }}
          />
        ) : (
          <EventIcon
            fontSize="small"
            style={{
              color: data.isRead ? theme.palette.primary.main : theme.palette.grayScale[0],
            }}
          />
        )}
      </Box>
      <Stack sx={{ width: "192px" }} spacing={1}>
        <Typography className={classes.title}>{data.title}</Typography>
        <Typography
          fontWeight="500"
          fontSize="12px"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.body.split("\n").map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
        <Typography fontWeight="bold" fontSize="11px" color={theme.palette.grayScale[700]}>
          {data.relativeTime}
        </Typography>
      </Stack>
    </MenuItem>
  );
};
