import { configureStore } from "@reduxjs/toolkit";
import billingSidebarReducer from "./billing-sidebar/slice";
import billingTableReducer from "./billing-table/slice";
import billingTableHeaderReducer from "./billing-table-header/slice";
import calendarReducer from "./calendar/slice";
import deleteConfirmDialogReducer from "./delete-confirm-dialog/slice";
import discardChangesConfirmDialogReducer from "./discard-changes-confirm-dialog/slice";
import imagePreviewModalReducer from "./image-preview-modal/slice";
import mainReducer from "./main/slice";
import photosPreviewModalReducer from "./photos-preview-modal/slice";
import projectSidebarReducer from "./project-sidebar/slice";
import projectTableReducer from "./project-table/slice";
import projectTableHeaderReducer from "./project-table-header/slice";
import reportContentPrintModalReducer from "./report-content-print-modal/slice";
import scheduleDeleteConfirmDialogReducer from "./schedule-delete-confirm-dialog/slice";
import scheduleRightClickMenuReducer from "./schedule-right-click-menu/slice";
import scheduleSearchResultHeaderReducer from "./schedule-search-result-header/slice";
import scheduleSearchResultTableReducer from "./schedule-search-result-table/slice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    calendar: calendarReducer,
    billingTable: billingTableReducer,
    billingTableHeader: billingTableHeaderReducer,
    billingSidebar: billingSidebarReducer,
    deleteConfirmDialog: deleteConfirmDialogReducer,
    imagePreviewModal: imagePreviewModalReducer,
    scheduleDeleteConfirmDialog: scheduleDeleteConfirmDialogReducer,
    scheduleRightClickMenu: scheduleRightClickMenuReducer,
    discardChangesConfirmDialog: discardChangesConfirmDialogReducer,
    projectTable: projectTableReducer,
    projectTableHeader: projectTableHeaderReducer,
    photosPreviewModal: photosPreviewModalReducer,
    projectSidebar: projectSidebarReducer,
    scheduleSearchResultHeader: scheduleSearchResultHeaderReducer,
    scheduleSearchResultTable: scheduleSearchResultTableReducer,
    reportContentPrintModal: reportContentPrintModalReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
