import { Brand } from "data-access/repositories/brand";
import { SubscriberType } from "../index.dto";

export type NoticeConfirmedScheduleChangeId = Brand<number, "NoticeConfirmedScheduleChangeId">;

export interface NoticeConfirmedScheduleChangeIndexResponse extends NoticeConfirmedScheduleChange {}

export interface NoticeConfirmedScheduleChange {
  id: NoticeConfirmedScheduleChangeId;
  subscriberTypes: SubscriberType[];
  isEnable: boolean;
}

export interface NoticeConfirmedScheduleChangeUpdateRequest {
  subscriberTypes?: SubscriberType[];
  isEnable?: boolean;
}

export const initialNoticeConfirmedScheduleChange: NoticeConfirmedScheduleChange = {
  id: 0 as NoticeConfirmedScheduleChangeId,
  subscriberTypes: [],
  isEnable: false,
};
