import {
  ScheduleTypeIndexResponse,
  ScheduleTypeShowResponse,
  ScheduleTypeDestroyResponse,
  ScheduleTypeRequest,
  ScheduleTypeCreateResponse,
  ScheduleTypeUpdateResponse,
  ScheduleTypeId,
} from "./schedule_type.dto";
import { ApiClient } from "../../ApiClient";

export const scheduleTypeRepository = {
  index(): Promise<ScheduleTypeIndexResponse> {
    return ApiClient.get("/api/v1/schedule_types").then((res) => res.data);
  },
  create(body: ScheduleTypeRequest): Promise<ScheduleTypeCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/schedule_types", createBody(body), config).then(
      (res) => res.data,
    );
  },
  show(id: ScheduleTypeId): Promise<ScheduleTypeShowResponse> {
    return ApiClient.get(`/api/v1/schedule_types/${id}`).then((res) => res.data);
  },
  update(id: ScheduleTypeId, body: ScheduleTypeRequest): Promise<ScheduleTypeUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/schedule_types/${id}`, createBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ScheduleTypeId): Promise<ScheduleTypeDestroyResponse> {
    return ApiClient.delete(`/api/v1/schedule_types/${id}`).then((res) => res.data);
  },
};

const createBody = (body: ScheduleTypeRequest) => {
  return {
    name: body.name,
    color_number: body.colorNumber,
    display_order_position: body.displayOrderPosition,
  };
};
