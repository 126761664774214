import React from "react";
import "./index.css";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createRoutesFromChildren,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { theme } from "extensions/theme";
import Main from "pages/main";
import SignInForm from "pages/signIn";
import { createRoot } from "react-dom/client";
import { noAuthenticationRouter } from "routes/noAuthentication";
import { store } from "store/store";
import { setProjectTableFilter, setWorkReportTableFilter } from "utils/setLocalStorageInit";

LicenseInfo.setLicenseKey(
  "ffa2c93504ee8136b33935785f9b90f5Tz04MDg3MyxFPTE3MzQ2ODQyNzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://763a81e1b1da4a6c999fbe2f12492bcb@o1217158.ingest.sentry.io/6549249",
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (event.extra && typeof event.extra.status === "number" && event.extra.status < 500) {
        return null;
      }
      return event;
    },
  });
}

const cookie = cookieRepository.get();
const container = document.getElementById("root");
const root = createRoot(container!);

const isPublicRoute = () => {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const parts = window.location.pathname.split("/");
  return parts[1] === "photos" || (parts[1] === "work-reports" && uuidPattern.test(parts[2]));
};

// LocalStorageRepository実装後に削除する
setProjectTableFilter();
setWorkReportTableFilter();

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {isPublicRoute() ? (
        <RouterProvider router={noAuthenticationRouter} />
      ) : cookie ? (
        <Main />
      ) : (
        <SignInForm />
      )}
    </Provider>
  </ThemeProvider>,
);
