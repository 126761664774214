import { Brand } from "../brand";

export type UserId = Brand<number, "UserId">;

export interface User {
  id: UserId;
  name: string;
  is_deactivate: boolean;
}

export interface UserIndexResponse extends Array<User> {}

export const initialUser = {
  id: 0 as UserId,
  name: "",
  is_deactivate: false,
};
