import { useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { photoRepository as projectPhotoRepository } from "data-access/repositories/project/photo/photo.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { useSWRConfig } from "swr";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (photo: PhotoResponse) => void;
}
export const PhotoSelectModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const classes = styles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="paper"
      className={classes.modal}
    >
      <DialogTitle className={classes.title}>
        写真を選択
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <PhotosBlock {...props} />
      </DialogContent>
    </Dialog>
  );
};

const PhotosBlock = (props: Props) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectSidebar);
  const mainState = useAppSelector(selectMain);
  const fetchKey = `/api/v1/projects/${state.id}/photos`;
  const { cache, mutate } = useSWRConfig();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const photos: PhotoResponse[] = useMemo(() => {
    return cache.get(fetchKey)?.data;
  }, [cache.get(fetchKey)?.data]);

  const handlePhotoUpload = async (
    files: File[] | FileList | null,
    photoType?: { value: string; valueI18n: string },
  ) => {
    if (files) {
      const requestObj: { photoType: { value: string; valueI18n: string }; files: File[] } = {
        photoType: { value: photoType?.value || "", valueI18n: photoType?.valueI18n || "" },
        files: [],
      };

      for (let i = 0; i < files.length; i++) {
        const targetPhoto = files[i];
        const photo = new File([targetPhoto], targetPhoto.name, {
          lastModified: targetPhoto.lastModified,
        });
        requestObj.files.push(photo);
      }

      setIsLoading(true);
      try {
        const response = await projectPhotoRepository.create(state.id as ProjectId, requestObj);
        const successfulUploads = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;

        mutate(fetchKey);

        const successfulUploadNumber = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        dispatch(
          mainOperations.updateSuccessMessage(
            `${response.length}枚中、${successfulUploadNumber}枚の写真をアップロードしました`,
          ),
        );
        if (successfulUploads === 0) {
          dispatch(mainOperations.updateErrorMessage("全ての写真のアップロードに失敗しました"));
        }
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePhotoPreviewClick = (photoId: number, recordType: string) => {
    if (!photos) return;

    dispatch(photosPreviewModalOperations.open());
    // 案件と作業日報の写真のidが重複しているときがあるため、recordTypeと合わせて検索する
    const createPhotoInfo = photos.map((photo) => {
      return {
        id: photo.id,
        type: photo.record_type,
      };
    });
    const clickedPhotoInfo = createPhotoInfo.filter(
      (photo) => photo.id === photoId && photo.type === recordType,
    );
    const clickedPhotoIndex = createPhotoInfo.indexOf(clickedPhotoInfo[0]);

    dispatch(photosPreviewModalOperations.updateCurrentIndex(clickedPhotoIndex));
    dispatch(photosPreviewModalOperations.setPhotos(photos));
  };

  return (
    <>
      {mainState.photoTypes.map((photoType, index) => {
        return (
          <Box sx={{ mb: "32px" }} key={index}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                height: "34px",
                lineHeight: "34px",
                fontWeight: "500",
              }}
            >
              {photoType.valueI18n}
            </Typography>
            <Box
              sx={{ display: "flex", flexWrap: "wrap", maxWidth: "750px" }}
              className={classes.imagesContainer}
            >
              {photos &&
                photos
                  .filter((photo) => photo.photo_type?.value === photoType.value)
                  .map((photo) => {
                    return (
                      <Box key={photo.id} className={classes.imageBox}>
                        <Box
                          className="buttonBox"
                          sx={{
                            display: "none",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0,0,0,0.6)",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => props.onSelect(photo)}
                            sx={{ width: "80%", mb: "16px" }}
                          >
                            この写真を選択
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => handlePhotoPreviewClick(photo.id, photo.record_type)}
                            sx={{
                              width: "80%",
                              backgroundColor: theme.palette.grayScale[0],
                              "&:hover": {
                                backgroundColor: theme.palette.grayScale[200],
                              },
                            }}
                          >
                            プレビュー
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                            height: "138px",
                          }}
                        >
                          <img
                            alt={`photo-${photo.id}`}
                            src={photo.thumbnail_url}
                            loading="lazy"
                            className={classes.image}
                          />
                        </Box>
                        <Box sx={{ p: "8px", backgroundColor: theme.palette.grayScale[0] }}>
                          {photo.record_type === "project" && (
                            <>
                              <Typography className={classes.name} fontSize={11} fontWeight="bold">
                                {photo.project_attributes?.name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, textAlign: "left" }}
                              >
                                {formatDateUtil(new Date(photo.created_at), DATE_TIME_SLASH_FORMAT)}{" "}
                                アップロード
                              </Typography>
                            </>
                          )}
                          {photo.record_type === "work_report" && (
                            <>
                              <Typography className={classes.name} fontSize={11} fontWeight="bold">
                                {photo.work_report_attributes?.site_name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, textAlign: "left" }}
                              >
                                {timeRange(
                                  DATE_TIME_SLASH_FORMAT,
                                  photo.work_report_attributes?.start_time.toString(),
                                  photo.work_report_attributes?.end_time.toString(),
                                )}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              <Box className={classes.dropzone}>
                <PhotoDropzone photoType={photoType} onDrop={handlePhotoUpload} />
              </Box>
            </Box>
          </Box>
        );
      })}
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading}
        invisible
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};
