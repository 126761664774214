import { Brand } from "data-access/repositories/brand";

export type SupplierId = Brand<number, "SupplierId">;

export interface Supplier {
  id: SupplierId;
  name: string;
  isDefaultCost: boolean;
}

export interface SupplierIndexResponse extends Array<Supplier> {}

export interface SupplierRequest {
  name?: string;
  isDefaultCost?: boolean;
  displayOrderPosition?: number;
}

export const initialSupplierRequest: SupplierRequest = {
  name: "",
  isDefaultCost: false,
  displayOrderPosition: 0,
};
