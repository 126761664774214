import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { autoPlacement, offset } from "@floating-ui/dom";
import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { MemberAutocomplete } from "components/atoms/member-autocomplete";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { PhotoType } from "data-access/repositories/photo_type/photo_type.dto";
import { photoTypeRepository } from "data-access/repositories/photo_type/photo_type.repository";
import { User } from "data-access/repositories/user/user.dto";
import {
  Photo,
  PhotoResponse,
  WorkReportPhotoId,
} from "data-access/repositories/work_report/photo/photo.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import useSWR, { useSWRConfig } from "swr";
import { handleError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";
import { photoRepository } from "../../../data-access/repositories/work_report/photo/photo.repository";
import {
  WorkReport,
  WorkReportId,
} from "../../../data-access/repositories/work_report/work_report.dto";
import { generateRandom12DigitNumber } from "../../../utils/generateRandomNumber";
import { ConfirmDialog } from "../../molecules/confirm-dialog";

const CustomTextTitleBox = styled(Box)({
  lineHeight: "14px",
  letterSpacing: "0.2px",
  marginBottom: "18px",
  fontWeight: "500",
});

const CustomImageBlockBox = styled(Box)({
  backgroundColor: theme.palette.customPrimary[50],
  border: `1px solid ${theme.palette.grayScale[300]}`,
  borderRadius: "5px",
  marginTop: "1rem",
  padding: "1rem",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: 0,
  },
  alignItems: "center",
});

const CustomPhotoBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  margin: "0.7rem",
  borderRadius: "4px",
  height: "8.5rem",
  width: "13rem",
  textAlign: "center",
  border: `1px solid ${theme.palette.grayScale[300]}`,
});

const CustomIconButton = styled(IconButton)({
  m: "5px",
  backgroundColor: theme.palette.grayScale[0],
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grayScale[300]}`,
  width: "24px",
  height: "24px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

const PopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -200, crossAxis: 250 }),
];

interface Props {
  isCreate: boolean;
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  handleSubmit: (body: WorkReportState) => Promise<WorkReport>;
  id?: WorkReportId;
  defaultValue: WorkReportState;
  defaultPhotos?: PhotoResponse[];
  fetchIndexKeys: string[];
}

// TODO: 作業日報のリファクタリング時にdtoとs統一する
export interface WorkReportState {
  siteName: string;
  userIds: number[];
  users: User[];
  siteManagerId: number;
  startTime: string;
  endTime: string;
  content: string;
  workStatusType: string;
  created_date: string;
  created_by: User | undefined;
}

export const WorkReportFormModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const activeUsers = mainState.users.filter((user) => !user.is_deactivate);

  const { data: photoTypes } = useSWR("/api/v1/photo_types", photoTypeRepository.index);
  const { mutate: IndexMutate } = useSWRConfig();
  const initialStartTime = useMemo(() => {
    const now = new Date();
    now.setHours(8);
    now.setMinutes(0);
    return now;
  }, []);
  const initialEndTime = useMemo(() => {
    const now = new Date();
    now.setHours(17);
    now.setMinutes(0);
    return now;
  }, []);
  // TODO: 作業日報のリファクタリング時にdtoとs統一する
  const initialState: WorkReportState = {
    siteName: "",
    users: [],
    userIds: [],
    siteManagerId: 0,
    startTime: initialStartTime.toString(),
    endTime: initialEndTime.toString(),
    content: "",
    workStatusType: "in_progress",
    created_date: "",
    created_by: undefined,
  };
  const [state, setState] = useState<WorkReportState>(initialState);
  const [displayPhotos, setDisplayPhotos] = useState<PhotoResponse[]>([]);
  const [uploadPhotos, setUploadPhotos] = useState<Photo[]>([]);
  const [deletePhotoIds, setDeletePhotoIds] = useState<number[]>([]);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (props.isCreate) {
      setState({
        ...state,
        siteName: props.defaultValue.siteName || "",
        siteManagerId: props.defaultValue.siteManagerId || 0,
        userIds: props.defaultValue.userIds || [],
        users: props.defaultValue.users || [], // userIdsのみの設定では反映されないので注意
        startTime: props.defaultValue.startTime || initialStartTime.toString(),
        endTime: props.defaultValue.endTime || initialEndTime.toString(),
        content: mainState.company.company_setting.work_report_content_template,
      });
    } else {
      setState({
        siteName: props.defaultValue.siteName,
        users: props.defaultValue.users || [],
        userIds: props.defaultValue.userIds,
        siteManagerId: props.defaultValue.siteManagerId || 0,
        startTime: props.defaultValue.startTime || initialStartTime.toString(),
        endTime: props.defaultValue.endTime || initialEndTime.toString(),
        content: props.defaultValue.content || "",
        workStatusType: props.defaultValue.workStatusType || "in_progress",
        created_date: props.defaultValue.created_date,
        created_by: props.defaultValue.created_by,
      });
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!props.defaultPhotos) return;
    setDisplayPhotos(props.defaultPhotos);
  }, [props.isOpen, props.defaultPhotos]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    setIsChange(true);
    switch (e.target.name) {
      case "siteName":
        setState({ ...state, siteName: e.target.value as string });
        break;
      case "siteManagerId":
        setState({ ...state, siteManagerId: e.target.value as number });
        break;
      case "content":
        setState({ ...state, content: e.target.value as string });
        break;
    }
  };

  const handleChangeUsers = (e: React.SyntheticEvent<Element, Event>, value: User[]) => {
    const userIds = value.map((user: User) => user.id);
    setState({ ...state, users: value, userIds });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime":
        // datePickerは日付を選択した時にeventが存在する、時間を選択したときはeventが存在しない
        // ミニカレンダーの時間を選択 ||  ミニカレンダーの日付を選択（キーボード入力の時は更新させないため）
        if (e === undefined || e.type === "click") {
          setState({ ...state, startTime: date.toString() });
        }
        break;
      case "endTime":
        if (e === undefined || e.type === "click") {
          setState({ ...state, endTime: date.toString() });
        }
        break;
    }
  };

  const handleBlurDateTime = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsChange(true);
    switch (e.target.name) {
      case "startTime":
        setState({ ...state, startTime: e.target.value.toString() });
        break;
      case "endTime":
        setState({ ...state, endTime: e.target.value.toString() });
        break;
    }
  };

  const organizedPhotoTypes = useMemo(() => {
    return photoTypes?.map((photoType: PhotoType) => {
      return {
        value: photoType.value,
        valueI18n: photoType.value_i18n,
      };
    });
  }, [photoTypes]);

  const handlePhotoPreviewClick = (targetPhotoId: number) => {
    dispatch(photosPreviewModalOperations.open());
    dispatch(
      photosPreviewModalOperations.updateCurrentIndex(getCurrentIndexOfTargetPhoto(targetPhotoId)),
    );
    dispatch(
      photosPreviewModalOperations.setPhotos(
        displayPhotos.map((photo) => {
          return {
            ...photo,
            id: photo.id,
          };
        }),
      ),
    );
  };

  const getCurrentIndexOfTargetPhoto = (targetId: number): number => {
    let currentIndex = 0;
    for (let i = 0; i < displayPhotos.length; i++) {
      if (displayPhotos[i].id === targetId) {
        currentIndex = i;
      }
    }
    return currentIndex;
  };

  const handleClickPhotoDelete = (id: number) => {
    setIsChange(true);
    const isPhotoAdded = uploadPhotos.filter((v) => v.id === id).length > 0;
    if (isPhotoAdded) {
      displayPhotos.map((v) => v.id === id && window.URL.revokeObjectURL(v.file_url));
    } else {
      setDeletePhotoIds((currentIds) => [...currentIds, id]);
    }
    setDisplayPhotos((currentPhotos) => currentPhotos.filter((v) => v.id !== id));
    setUploadPhotos(uploadPhotos.filter((v) => v.id !== id));
  };

  const handlePhotoUpload = (
    files: File[] | FileList | null,
    photoType?: { value: string; valueI18n: string },
  ) => {
    if (!files || !photoType) return;

    const photosData: PhotoResponse[] = [];
    const newUploadPhotos: Photo[] = [];
    for (let i = 0; i < files.length; i++) {
      const photo = buildPhoto(files[i], photoType);
      photosData.push(photo);
      newUploadPhotos.push({
        id: photo.id,
        file: files[i],
        type: photoType.value,
        local_url: photo.file_url,
      });
    }
    setUploadPhotos((currentPhotos) => [...currentPhotos, ...newUploadPhotos]);
    setDisplayPhotos((currentPhotos) => [...currentPhotos, ...photosData]);
  };

  const buildPhoto = (
    file: File,
    photoType: { value: string; valueI18n: string },
  ): PhotoResponse => {
    const localFilePath = window.URL.createObjectURL(file);
    const date = new Date();
    return {
      id: generateRandom12DigitNumber() as WorkReportPhotoId,
      file_name: file.name,
      file_url: localFilePath,
      thumbnail_url: localFilePath,
      is_sharable: true,
      photo_type: {
        value: photoType.value,
        value_i18n: photoType.valueI18n,
      },
      created_at: date.toISOString(),
      record_type: "work_report",
      record_type_i18n: "作業日報",
      project_attributes: null,
      work_report_attributes: {
        id: props.id as WorkReportId,
        site_name: props.defaultValue?.siteName || "",
        start_time: props.defaultValue?.startTime || "",
        end_time: props.defaultValue?.endTime || "",
      },
    };
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      const res = await props.handleSubmit(state);
      if (deletePhotoIds.length > 0 && res.id) {
        await photoRepository.bulkDestroy(
          props.id as WorkReportId,
          deletePhotoIds as WorkReportPhotoId[],
        );
        setDeletePhotoIds([]);
      }
      if (uploadPhotos.length > 0 && res.id) {
        await photoRepository.create(res.id, uploadPhotos);
        // メモリ上のオブジェクトURLを解放するため
        uploadPhotos.map((v) => window.URL.revokeObjectURL(v.local_url));
        setUploadPhotos([]);
      }
      for (let i = 0; i < props.fetchIndexKeys.length; i++) {
        IndexMutate(props.fetchIndexKeys[i]);
      }
      handleClose();
    } catch (error) {
      handleError(error, setErrorMessage, "保存に失敗しました");
    } finally {
      setIsSubmit(false);
    }
  };

  const handleClear = () => {
    setIsChange(true);
    setState({ ...state, siteName: "" });
  };

  const handleClose = () => {
    initializeState();
    setErrorMessage("");
  };

  const handleYes = () => {
    initializeState();
  };

  const initializeState = () => {
    setDisplayPhotos([]);
    setUploadPhotos([]);
    setDeletePhotoIds([]);
    setIsChange(false);
    setIsDeleteModalOpen(false);
    setState(initialState);
    props.setIsOpen(false);
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    errorMessage !== "" && scrollBottomRef?.current?.scrollIntoView();
  }, [errorMessage]);

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleYes}
        handleNo={() => setIsDeleteModalOpen(false)}
        content={
          props.isCreate
            ? "作成をキャンセルします。よろしいですか？"
            : "編集されていませんが破棄してよろしいですか？"
        }
        yesButtonText={props.isCreate ? "作成をキャンセル" : "編集せず破棄"}
        noButtonText="戻る"
        yesButtonColor="error"
      />

      <CustomModal
        title={`作業日報の${props.isCreate ? "作成" : "編集"}`}
        open={props.isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        footer={
          <Fragment>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "10rem" }}
            >
              キャンセル
            </Button>
            <Button
              disabled={isSubmit}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存する
            </Button>
          </Fragment>
        }
      >
        <Box>
          {/*タイトル*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業日報名</Typography>
            </CustomTextTitleBox>
            <TextField
              id="siteName"
              name="siteName"
              value={state.siteName}
              sx={{ width: "100%" }}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<Clear fontSize="small" />}
                      onClick={handleClear}
                      color="primary"
                      sx={{ p: 0, minWidth: "auto" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/*参加者*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>参加者</Typography>
            </CustomTextTitleBox>
            <MemberAutocomplete
              users={state.users || []}
              activeUsers={activeUsers || []}
              siteManagerId={state.siteManagerId}
              onChange={handleChangeUsers}
            />
          </Box>

          {/* 現場責任者*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>現場責任者</Typography>
            </CustomTextTitleBox>
            <Select
              id="siteManagerId"
              name="siteManagerId"
              value={state.siteManagerId.toString()}
              onChange={handleChange}
            >
              <MenuItem value={0}>未設定</MenuItem>
              {state.users?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/*開始日時*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>開始日時</Typography>
            </CustomTextTitleBox>
            <CustomDatePicker
              id="startTime"
              name="startTime"
              date={state.startTime ? new Date(state.startTime) : null}
              onChange={handleChangeDateTime}
              onBlur={handleBlurDateTime}
              dateFormat={DATE_TIME_SLASH_FORMAT}
              showTimeSelect
              timeIntervals={15}
              popperPlacement="right-start"
              popperModifiers={PopperPosition}
            />
          </Box>

          {/*終了日時*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>終了日時</Typography>
            </CustomTextTitleBox>
            <CustomDatePicker
              id="endTime"
              name="endTime"
              date={state.endTime ? new Date(state.endTime) : null}
              onChange={handleChangeDateTime}
              onBlur={handleBlurDateTime}
              dateFormat={DATE_TIME_SLASH_FORMAT}
              showTimeSelect
              timeIntervals={15}
              popperPlacement="right-start"
              popperModifiers={PopperPosition}
            />
          </Box>

          <Divider sx={{ mt: 8, mb: 8 }} />

          {/*作業内容・連絡事項*/}
          <Box sx={{ mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業内容・連絡事項</Typography>
            </CustomTextTitleBox>
            <TextField
              id="content"
              name="content"
              multiline
              fullWidth
              rows={4}
              value={state.content}
              onChange={handleChange}
            />
          </Box>

          {/*写真*/}
          <Box sx={{ mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>写真</Typography>
            </CustomTextTitleBox>
            <>
              {organizedPhotoTypes?.map((photoType, index) => {
                return (
                  <Box sx={{ mt: 2 }} key={index}>
                    <CustomFormLabel small labelName={photoType.valueI18n} />
                    <CustomImageBlockBox
                      sx={{ display: "flex", flexWrap: "wrap", maxWidth: "750px" }}
                    >
                      {displayPhotos
                        .filter(
                          (photo: PhotoResponse) => photo.photo_type.value === photoType.value,
                        )
                        .map((photo: PhotoResponse) => {
                          return (
                            <CustomPhotoBox key={photo.id}>
                              <img
                                alt={`photo-${photo.id}`}
                                src={photo.thumbnail_url}
                                style={{ maxWidth: "100%" }}
                                height="100%"
                                loading="lazy"
                                onClick={() => handlePhotoPreviewClick(photo.id)}
                              />
                              <CustomIconButton onClick={() => handleClickPhotoDelete(photo.id)}>
                                <Clear fontSize="small" />
                              </CustomIconButton>
                            </CustomPhotoBox>
                          );
                        })}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "0.7rem",
                          borderRadius: "4px",
                          height: "8.5rem",
                          width: "13rem",
                        }}
                      >
                        <PhotoDropzone photoType={photoType} onDrop={handlePhotoUpload} />
                      </Box>
                    </CustomImageBlockBox>
                  </Box>
                );
              })}
            </>
          </Box>

          <Divider sx={{ mt: 8, mb: 8 }} />

          {/*作業の進捗*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業の進捗</Typography>
            </CustomTextTitleBox>
            <FormControl>
              <RadioGroup
                name="workStatusType"
                row
                sx={{
                  "& .MuiFormControlLabel-root": {
                    border: `1px solid ${theme.palette.grayScale[700]}`,
                    borderRadius: "5px",
                    padding: "0.5rem 3rem 0.5rem 0",
                    marginLeft: "0.1rem",
                  },
                }}
              >
                <FormControlLabel
                  control={<Radio checked={state.workStatusType === "in_progress"} />}
                  label="継続"
                  onClick={() => {
                    setIsChange(true);
                    setState({ ...state, workStatusType: "in_progress" });
                  }}
                />
                <FormControlLabel
                  control={<Radio checked={state.workStatusType === "done"} />}
                  label="完了"
                  onClick={() => {
                    setIsChange(true);
                    setState({ ...state, workStatusType: "done" });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};
