import { ApiClient } from "data-access/ApiClient";
import {
  PhotoCountResponse,
  PhotoUpdateRequest,
} from "data-access/repositories/project/photo/photo.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  PhotoDestroyResponse,
  PhotoBulkDestroyResponse,
  PhotoCreateRequest,
  PhotoIndexResponse,
  WorkReportPhotoId,
} from "./photo.dto";
import { WorkReportId } from "../work_report.dto";

export const photoRepository = {
  index(workReportId: WorkReportId): Promise<PhotoIndexResponse> {
    return ApiClient.get(`/api/v1/work_reports/${workReportId}/photos`).then((res) => res.data);
  },
  update(
    workReportId: WorkReportId,
    id: WorkReportPhotoId,
    body: PhotoUpdateRequest,
  ): Promise<any> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = convertKeysToSnakeCase(body);
    return ApiClient.put(
      `/api/v1/work_reports/${workReportId}/photos/${id}`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  async create(workReportId: WorkReportId, body: PhotoCreateRequest) {
    await Promise.all(
      body.map(async (photo) => {
        const preSignedUrlResponse = await ApiClient.get(
          `/api/v1/work_reports/${workReportId}/photos/put_presigned_url?file_name=${encodeURIComponent(
            photo.file.name,
          )}`,
        );

        const preSignedUrlData = preSignedUrlResponse.data;
        if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
          throw new Error("Failed to get presigned URL");
        }

        const uploadResponse = await fetch(preSignedUrlData.url, {
          method: "PUT",
          body: photo.file,
          headers: {
            "Content-Type": photo.file.type,
          },
        });

        if (uploadResponse.status !== 200) {
          throw new Error("Failed to upload file");
        }

        await ApiClient.post(`/api/v1/work_reports/${workReportId}/photos`, {
          file_name: photo.file.name,
          object_key: preSignedUrlData.object_key,
          photo_type: photo.type,
        });
      }),
    );
  },
  destroy(workReportId: WorkReportId, id: WorkReportPhotoId): Promise<PhotoDestroyResponse> {
    return ApiClient.delete(`/api/v1/work_reports/${workReportId}/photos/${id}`).then(
      (res) => res.data,
    );
  },
  bulkDestroy(
    workReportId: string,
    photoIds: WorkReportPhotoId[],
  ): Promise<PhotoBulkDestroyResponse> {
    const config = { headers: { "content-type": "application/json" } };

    return ApiClient.post(
      `/api/v1/work_reports/${workReportId}/photos/bulk_destroy`,
      { ids: photoIds },
      config,
    ).then((res) => res.data);
  },
  countInfo(workReportId: WorkReportId): Promise<PhotoCountResponse> {
    return ApiClient.get(`/api/v1/work_reports/${workReportId}/photos/count_info`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};
