import {
  ClientIndexRequest,
  ClientRequest,
  ClientCreateResponse,
  ClientShowResponse,
  ClientUpdateResponse,
  ClientDestroyResponse,
  ClientIndexResponse,
  ClientId,
  Client,
} from "./client.dto";
import { ApiClient } from "../../ApiClient";

export const clientRepository = {
  index(query: ClientIndexRequest): Promise<ClientIndexResponse> {
    const params = {
      ids: query.ids,
      keyword: query.keyword,
      page: query.page,
      limit: query.limit,
    };
    return ApiClient.get("/api/v1/clients", { params }).then((res) => res.data);
  },
  all(query: ClientIndexRequest): Promise<Client[]> {
    const params = {
      order_by: query.orderBy,
    };
    return ApiClient.get("/api/v1/clients/all", { params }).then((res) => res.data);
  },
  create(body: ClientRequest): Promise<ClientCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/clients", createBody(body), config).then((res) => res.data);
  },
  show(id: ClientId): Promise<ClientShowResponse> {
    return ApiClient.get(`/api/v1/clients/${id}`).then((res) => res.data);
  },
  update(id: ClientId, body: ClientRequest): Promise<ClientUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/clients/${id}`, createBody(body), config).then((res) => res.data);
  },
  destroy(id: ClientId): Promise<ClientDestroyResponse> {
    return ApiClient.delete(`/api/v1/clients/${id}`).then((res) => res.data);
  },
};

const createBody = (body: ClientRequest) => {
  return {
    code: body.code,
    name: body.name,
    honorific_title: body.honorificTitle,
    name_kana: body.nameKana,
    postal_code: body.postalCode,
    address: body.address,
    address_second: body.addressSecond,
    billing_person_name: body.billingPersonName,
    billing_person_position: body.billingPersonPosition,
    billing_person_section: body.billingPersonSection,
    phone_number: body.phoneNumber,
    phone_number_second: body.phoneNumberSecond,
    fax_number: body.faxNumber,
    email: body.email,
    note: body.note,
    billing_closing_date_type: body.billingClosingDateType,
    deposit_method_type: body.depositMethodType,
    deposit_month_type: body.depositMonthType,
    deposit_date_type: body.depositDateType,
  };
};
