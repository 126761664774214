import Axios, { AxiosResponse, AxiosInstance } from "axios";
import { getBaseUrl } from "utils/getBaseUrl";
import { logout } from "utils/logout";
import { authRepository } from "./repositories/auth/auth.repository";

const axiosInstance: AxiosInstance = Axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json,application/octet-stream,application/pdf",
    "X-Requested-With": "XMLHttpRequest",
  },
  responseType: "json",
  withCredentials: true,
});

// リクエストのインターセプターを設定
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
const requestQueue: (() => void)[] = [];
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    // ログイン画面でのエラーはそのまま返す
    if (error.response && error.response.status === 401) {
      const retryOriginalRequest = new Promise((resolve) => {
        requestQueue.push(() => {
          resolve(axiosInstance(error.config));
        });
      });

      if (!isRefreshing) {
        // アクセストークンの更新プロセスが実行中でない場合
        isRefreshing = true;
        try {
          await refreshAccessToken();
          // アクセストークンを更新したら、キュー内のリクエストを再試行
          while (requestQueue.length > 0) {
            const retry = requestQueue.shift();
            retry && retry();
          }
        } finally {
          isRefreshing = false;
        }
      }

      return retryOriginalRequest;
    }
    if (error.response?.status === 502) {
      await logout();
    }
    if (error.response?.status === 500) {
      location.href = "/error";
    }
    return Promise.reject(error);
  },
);

// アクセストークンを更新する関数
const refreshAccessToken = async (): Promise<void> => {
  // 成功したら新しいアクセストークンを返す。失敗もしくはリフレッシュトークない場合はログアウト
  try {
    await authRepository.update();
  } catch (error) {
    await logout();
  }
};

export const ApiClient = axiosInstance;
