import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ScheduleUpdateRequest,
  ScheduleUpdateResponse,
  ScheduleDestroyResponse,
  ScheduleId,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const update = createAsyncThunk<
  ScheduleUpdateResponse,
  { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
  AsyncThunkConfig
>(
  "calendar/update",
  async (
    thunkArg: { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
    thunkApi,
  ) => {
    try {
      return await scheduleRepository.update(thunkArg.id, thunkArg.with_futures, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateGridSchedule = createAsyncThunk<
  ScheduleUpdateResponse,
  { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
  AsyncThunkConfig
>(
  "calendar/updateGridSchedule",
  async (
    thunkArg: { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
    thunkApi,
  ) => {
    try {
      return await scheduleRepository.update(thunkArg.id, thunkArg.with_futures, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const dropGridSchedule = createAsyncThunk<
  ScheduleUpdateResponse,
  { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
  AsyncThunkConfig
>(
  "calendar/dropGridSchedule",
  async (
    thunkArg: { id: ScheduleId; with_futures: boolean; body: ScheduleUpdateRequest },
    thunkApi,
  ) => {
    try {
      return await scheduleRepository.update(thunkArg.id, thunkArg.with_futures, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const destroy = createAsyncThunk<
  ScheduleDestroyResponse,
  { id: ScheduleId; with_futures: boolean },
  AsyncThunkConfig
>("calendar/destroy", async (thunkArg: { id: ScheduleId; with_futures: boolean }, thunkApi) => {
  try {
    return await scheduleRepository.destroy(thunkArg.id, thunkArg.with_futures);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});
