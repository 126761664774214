import React from "react";
import { NoteAltOutlined } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Client } from "data-access/repositories/client/client.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  sx?: object;
  client: Client | undefined;
  isDeselectButtonVisible?: boolean;
  isEditable?: boolean;
}

export const ClientBlock = ({
  isDeselectButtonVisible = true,
  isEditable = true,
  client,
}: Props) => {
  const dispatch = useAppDispatch();
  const projectDetailState = useAppSelector(selectProjectSidebar);

  const handleClickClientEdit = () => {
    openURLInNewTab(`clients/${client?.id}`);
  };

  const handleUnselectClient = () => {
    dispatch(
      projectSidebarOperations.updateProject(projectDetailState.project.id, {
        clientId: 0,
      }),
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.customPrimary[50],
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          p: "12px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold", mb: "6px" }}>
              {client?.name}
            </Typography>
            {client?.address ? (
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                〒{client?.postal_code} {client?.address} {client?.address_second}
              </Typography>
            ) : (
              <Typography
                component="span"
                sx={{ fontSize: "12px", fontWeight: "500" }}
                color="error"
              >
                未設定
              </Typography>
            )}
          </div>

          <div>
            {isEditable && (
              <IconButton onClick={handleClickClientEdit}>
                <NoteAltOutlined color="primary" fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    ml: "0.2rem",
                    color: theme.palette.primary.main,
                  }}
                  align="center"
                >
                  編集
                </Typography>
              </IconButton>
            )}
            {isDeselectButtonVisible && (
              <IconButton onClick={handleUnselectClient}>
                <HighlightOffIcon color="error" fontSize="small" />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    ml: "0.2rem",
                    color: theme.palette.error.main,
                  }}
                  align="center"
                >
                  選択解除
                </Typography>
              </IconButton>
            )}
          </div>
        </Box>
        <Divider sx={{ my: "12px" }} />
        <div>
          <div style={{ display: "flex", marginBottom: "12px", gap: "24px" }}>
            <div>
              <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
                請求締日
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                {client?.billing_closing_date_type_i18n}
              </Typography>
            </div>
            <div>
              <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
                入金方法
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                {client?.deposit_method_type_i18n}
              </Typography>
            </div>
            <div>
              <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
                電話番号
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                {client?.phone_number}
              </Typography>
            </div>
            <div>
              <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
                メールアドレス
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>{client?.email}</Typography>
            </div>
          </div>

          <div>
            <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
              注意事項
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
              {client?.note.split("\n").map((str: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {str}
                    <br />
                  </React.Fragment>
                );
              })}
            </Typography>
          </div>
        </div>
      </Box>
    </>
  );
};
