import { ObjectKeys } from "types/objectKeys";
import { AttachmentResponse } from "./attachment/attachment.dto";
import { EstimateResponse } from "./estimate/estimate.dto";
import { PhotoResponse } from "./photo/photo.dto";
import { ProjectBillingId } from "./project_billing/project_billing.dto";
import { Brand } from "../brand";
import { Building } from "../building/building.dto";
import { Client, initialClientResponse } from "../client/client.dto";
import { PageInfo } from "../page_info/page_info.dto";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
  initialProjectStatusType,
} from "../project_status_type/project_status_type.dto";
import { ProjectType, initProjectType, ProjectTypeId } from "../project_type/project_type.dto";
import { initialUser, User } from "../user/user.dto";

export type ProjectId = Brand<number, "ProjectId">;

// 案件一覧で利用
export interface ProjectCollection {
  id: ProjectId;
  code: string;
  name: string;
  address: string;
  address_second: string;
  estimate_user_names: string;
  client: Client;
  inquiry_number: string;
  inquired_by?: User;
  manager?: User;
  inquired_date?: Date;
  ordered_date?: Date;
  expected_complete_date?: Date;
  completed_date?: Date;
  sales_amount: number;
  sales_tax: number;
  is_no_bill: boolean;
  billing_condition: string;
  billing_precaution: string;
  building: Building | null;
  is_supported_by_maker: boolean;
  note: string;
  person_name: string;
  project_type: {
    id: ProjectTypeId;
    name: string;
  };
  project_status_type: {
    id: ProjectStatusTypeId;
    name: string;
    color_number: string;
  };
  updated_at: Date;
  todos: {
    tag_type: {
      value: string;
      value_i18n: string;
    };
    content: string;
    is_done: boolean;
  }[];
  project_billings: {
    id: ProjectBillingId;
    amount: number;
    tax: number;
  }[];
}

export interface Project extends ObjectKeys {
  // 共通
  id: ProjectId;
  client: Client;
  billing_precaution: string;
  project_type: ProjectType;
  project_status_type: ProjectStatusType;
  requested_by_person_name: string;
  inquiry_number: string;
  is_manage_billing_on_another_system: boolean;
  code: string;
  name: string;
  is_no_bill: boolean;
  postal_code: string;
  address: string;
  address_second: string;
  email: string;
  phone_number: string;
  phone_number_second: string;
  fax_number: string;
  note: string;
  report_content: string;
  inquired_date: string;
  ordered_date?: string;
  expected_complete_date?: string;
  completed_date?: string;
  header_estimates: EstimateResponse[];
  photo_info: {
    first_three_photos: PhotoResponse[];
    total_count: number;
  };
  attachment_types: AttachmentTypes[];
  manager: User;
  inquired_by: User;
  is_supported_by_maker: boolean;
  sales_amount: number;
  sales_tax: number;
  total_cost_amount: number;
  decided_estimates_total_amount: number;
  photo_url_key: string;
  person_name: string;
}

export interface ProjectIndexRequest {
  projectTypeIds?: number[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
  sort?: string;
  direction?: "desc" | "asc";
  page?: number;
  rowsPerPage?: number;
}

export interface ProjectIndexResponse {
  data: ProjectCollection[];
  pagination: PageInfo;
}

export interface ProjectCreateResponse extends Project {}

export interface ProjectCreateRequest extends ObjectKeys {
  clientId?: number;
  managerId?: number;
  inquiredById?: number;
  inquiryNumber?: string;
  isManageBillingOnAnotherSystem?: boolean;
  isNoBill?: boolean;
  name?: string;
  code?: string;
  projectTypeId?: number;
  requester?: string;
  requestedByPersonName?: string;
  projectStatusTypeId?: ProjectStatusTypeId;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  phoneNumber?: string;
  phoneNumberSecond?: string;
  faxNumber?: string;
  email?: string;
  billingPrecaution?: string;
  note?: string;
  reportContent?: string;
  inquiredDate?: string;
  orderedDate?: string;
  expectedCompleteDate?: string;
  completedDate?: string;
  isSupportedByMaker?: boolean;
  salesAmount?: number;
  personName?: string;
}

export interface ProjectShowResponse extends Project {}

export interface ProjectUpdateRequest extends ObjectKeys {
  clientId?: number;
  buildingId?: number;
  managerId?: number;
  inquiredById?: number;
  inquiryNumber?: string;
  isManageBillingOnAnotherSystem?: boolean;
  isNoBill?: boolean;
  name?: string;
  code?: string;
  projectTypeId?: number;
  requester?: string;
  requestedByPersonName?: string;
  projectStatusTypeId?: ProjectStatusTypeId;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  phoneNumber?: string;
  phoneNumberSecond?: string;
  faxNumber?: string;
  email?: string;
  billingPrecaution?: string;
  note?: string;
  reportContent?: string;
  inquiredDate?: string;
  orderedDate?: string;
  expectedCompleteDate?: string;
  completedDate?: string;
  isSupportedByMaker?: boolean;
  salesAmount?: number;
  tax?: number;
  totalCostAmount?: number;
  personName?: string;
}

export interface AttachmentTypes {
  id: string;
  name: string;
  attachments: AttachmentResponse[];
}

export interface ProjectUpdateResponse extends Project {}

export interface ProjectDestroyResponse {}

export interface ProjectDuplicateResponse extends Project {}

export interface ProjectDownloadRequest {
  projectTypeIds?: number[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
}

export interface RelatedProject {
  id: ProjectId;
  code: string;
  name: string;
  sales_amount: number;
  project_status_type: {
    id: ProjectStatusTypeId;
    name: string;
    color_number: string;
  };
  completed_date: string;
  manager: User;
  note: string;
}

export const initialProjectResponse: Project = {
  // 共通
  id: 0 as ProjectId,
  client: initialClientResponse,
  billing_precaution: "",
  project_status_type: initialProjectStatusType,
  requested_by_person_name: "",
  project_type: initProjectType,
  is_no_bill: false,
  inquiry_number: "",
  is_manage_billing_on_another_system: false,
  code: "",
  name: "",
  postal_code: "",
  address: "",
  address_second: "",
  email: "",
  phone_number: "",
  phone_number_second: "",
  fax_number: "",
  note: "",
  report_content: "",
  inquired_date: new Date().toString(),
  header_estimates: [],
  photo_info: {
    first_three_photos: [],
    total_count: 0,
  },
  attachment_types: [],
  manager: initialUser,
  inquired_by: initialUser,
  is_supported_by_maker: false,
  sales_amount: 0,
  sales_tax: 0,
  total_cost_amount: 0,
  decided_estimates_total_amount: 0,
  photo_url_key: "",
  person_name: "",
};

export const initialProjectRequest: ProjectUpdateRequest = {
  clientId: 0,
  buildingId: 0,
  managerId: 0,
  inquiredById: 0,
  inquiryNumber: "",
  isManageBillingOnAnotherSystem: false,
  isNoBill: false,
  name: "",
  code: "",
  projectTypeId: 0,
  requester: "",
  requestedByPersonName: "",
  projectStatusTypeId: 0 as ProjectStatusTypeId,
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  email: "",
  billingPrecaution: "",
  note: "",
  reportContent: "",
  inquiredDate: new Date().toString(),
  isSupportedByMaker: false,
  salesAmount: 0,
};
