import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Divider, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import FileDownload from "images/file_download_icon.svg";
import Logo from "images/logo.svg";
import { ErrorPage404 } from "pages/error/error-page-404";
import { useAppDispatch } from "store/hooks";
import { photosPreviewModalOperations } from "store/photos-preview-modal/operations";
import useSWR from "swr";
import { downloadImages } from "utils/downloadImages";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  lineHeight: "14px",
  letterSpacing: "0.2px",
  fontWeight: "500",
  padding: "1rem 0",
});

const CustomHeaderBox = styled(Box)({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.grayScale[0],
  zIndex: 9,
});

export const ShareWorkReport = () => {
  const dispatch = useAppDispatch();
  const pathParts = window.location.pathname.split("/");
  const { data, error } = useSWR(`/api/v1/work_reports/${pathParts[2]}/share`, () =>
    workReportRepository.shareUrl(pathParts[2]),
  );
  // リンクが不正な場合は404ページを表示
  if (error) {
    return (
      <>
        <ErrorPage404 />
      </>
    );
  }
  const [headerPhotoCheckItems, setHeaderPhotoCheckItems] = useState<
    { id: number; checked: boolean; url: string; name: string }[]
  >(
    data?.header_photos.map((photo) => ({
      id: photo.id,
      checked: false,
      url: photo.file_url,
      name: photo.file_name,
    })) || [],
  );

  useEffect(() => {
    const items: { id: number; checked: boolean; url: string; name: string }[] = [];
    if (data) {
      for (const photo of data.header_photos) {
        items.push({ id: photo.id, checked: false, url: photo.file_url, name: photo.file_name });
      }
      setHeaderPhotoCheckItems(items);
    }
  }, [data]);

  const clickDownloadImages = () => {
    const list = headerPhotoCheckItems
      .filter((item) => item.checked)
      .map((item) => {
        return {
          url: item.url,
          name: item.name,
        };
      });
    downloadImages(list, `作業日報写真(${data?.site_name})`);
  };

  const bulkChecked = (v: boolean) => {
    const items = headerPhotoCheckItems.map((item) => {
      return {
        ...item,
        checked: v,
      };
    });
    setHeaderPhotoCheckItems(items);
  };

  const handlePhotoPreviewClick = (photoId: number, recordType: string) => {
    if (!data) return;

    // 案件と作業日報の写真のidが重複しているときがあるため、recordTypeと合わせて検索する
    const createPhotoInfo = data.header_photos.map((photo) => {
      return {
        id: photo.id,
        type: photo.record_type,
      };
    });
    const clickedPhotoInfo = createPhotoInfo.filter(
      (photo) => photo.id === photoId && photo.type === recordType,
    );
    const clickedPhotoIndex = createPhotoInfo.indexOf(clickedPhotoInfo[0]);

    dispatch(photosPreviewModalOperations.open());
    dispatch(photosPreviewModalOperations.updateCurrentIndex(clickedPhotoIndex));
    dispatch(photosPreviewModalOperations.setPhotos(data.header_photos));
  };

  const clickPhotoCheck = (id: number, value: boolean) => {
    const items = headerPhotoCheckItems.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          checked: value,
        };
      } else {
        return item;
      }
    });
    setHeaderPhotoCheckItems(items);
  };

  return (
    <>
      <PhotoPreviewModal />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "4rem",
          backgroundColor: theme.palette.primary.main,
          pl: "1.5rem",
        }}
      >
        <img src={Logo} alt="logo" style={{ width: 150 }} />
      </Box>
      <CustomHeaderBox>
        <Box sx={{ display: "flex", alignItems: "center", p: "1.5rem" }}>
          <Grid
            container
            justifyContent="flex-start"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  wordBreak: "break-all",
                  flex: 1,
                }}
              >
                {data?.site_name}
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>作成企業:</Typography>
              <Typography sx={{ pl: "0.5rem" }}>{data?.company_name}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />
      </CustomHeaderBox>

      <Box sx={{ p: "1.5rem" }}>
        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            作業日時
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {timeRange(
              "yyyy年MM月dd日 HH:mm",
              data?.start_time.toString(),
              data?.end_time.toString(),
            )}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            現場責任者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {data?.site_manager ? data?.site_manager.name : "未設定"}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            参加者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {usersName(data?.users || [])}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作業内容・連絡事項
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                fontSize: "14px",
                color: theme.palette.grayScale[900],
                wordBreak: "break-all",
              }}
            >
              {data?.content.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </CustomBox>

        <Box>
          <Grid container direction="row" justifyContent="flex-start" sx={{ mb: "1rem" }}>
            <Grid item>
              <Typography
                fontWeight="500"
                sx={{
                  fontSize: "14px",
                  color: theme.palette.grayScale[700],
                  height: "35px",
                  lineHeight: "30px",
                }}
              >
                現場写真
              </Typography>
            </Grid>
            {!!data?.header_photos && (
              <>
                <Grid item>
                  <Box sx={{ ml: "20px" }}>
                    <Button
                      variant="contained"
                      onClick={() => clickDownloadImages()}
                      disabled={headerPhotoCheckItems.filter((item) => item.checked).length === 0}
                      sx={{
                        fontSize: "12px",
                        height: "30px",
                        lineHeight: "30px",
                        borderRadius: "20px",
                        fontWeight: "500",
                      }}
                      startIcon={
                        <Box
                          sx={{ color: theme.palette.grayScale[0] }}
                          component="img"
                          src={FileDownload}
                        />
                      }
                    >
                      {headerPhotoCheckItems.filter((item) => item.checked).length}件をダウンロード
                    </Button>
                  </Box>
                </Grid>
                <Typography
                  fontWeight="500"
                  onClick={() => bulkChecked(true)}
                  sx={{
                    fontSize: "12px",
                    ml: "1rem",
                    color: theme.palette.primary.main,
                    height: "30px",
                    lineHeight: "30px",
                    cursor: "pointer",
                  }}
                >
                  すべて選択
                </Typography>
                <Typography
                  fontWeight="500"
                  onClick={() => bulkChecked(false)}
                  sx={{
                    fontSize: "12px",
                    ml: "1rem",
                    height: "30px",
                    lineHeight: "30px",
                    cursor: "pointer",
                  }}
                >
                  選択を解除
                </Typography>
              </>
            )}
          </Grid>
          <Grid container sx={{ gap: "10px", mb: "2rem" }}>
            {data &&
              data.header_photos.map((photo) => {
                const item = headerPhotoCheckItems.find((item) => {
                  return photo.id === item.id;
                });
                return (
                  <Grid item key={photo.id}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "177px",
                        height: "114px",
                        textAlign: "center",
                        border: `1px solid ${theme.palette.grayScale[200]}`,
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        alt={`photo-${photo.id}`}
                        src={photo.thumbnail_url}
                        style={{ maxWidth: "100%" }}
                        height="100%"
                        loading="lazy"
                        onClick={() => handlePhotoPreviewClick(photo.id, photo.record_type)}
                      />
                      {item && (
                        <Checkbox
                          checked={item.checked}
                          onChange={(e) => clickPhotoCheck(photo.id, e.target.checked)}
                          sx={{
                            position: "absolute",
                            top: "12px",
                            left: "12px",
                            borderRadius: 0,
                            width: "20px",
                            height: "20px",
                            backgroundColor: theme.palette.grayScale[0],
                            color: theme.palette.grayScale[0],
                            border: `1px solid ${theme.palette.grayScale[300]}`,
                            "&.MuiCheckbox-root": {
                              "&:hover": {
                                backgroundColor: theme.palette.grayScale[0],
                              },
                            },
                          }}
                        />
                      )}
                      <Typography
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: "4px",
                          left: "4px",
                          backgroundColor: theme.palette.grayScale[0],
                          borderRadius: "4px",
                          p: "0.2rem 0.8rem",
                          fontSize: "11px",
                          border: `1px solid ${theme.palette.grayScale[300]}`,
                        }}
                      >
                        {photo.photo_type.value_i18n}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作成日
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                width: "75%",
                fontSize: "14px",
                color: theme.palette.grayScale[900],
              }}
            >
              {data?.created_date}
            </Typography>
          </Box>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作成者
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                width: "75%",
                fontSize: "14px",
                color: theme.palette.grayScale[900],
              }}
            >
              {data?.created_by.name}
            </Typography>
          </Box>
        </CustomBox>
      </Box>
    </>
  );
};
