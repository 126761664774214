import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    imageBoxBlock: {
      backgroundColor: theme.palette.customPrimary[50],
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "5px",
      marginTop: "1rem",
      padding: "1rem",
      "& .MuiIconButton-root": {
        position: "absolute",
        right: 0,
      },
      alignItems: "center",
    },
    name: {
      fontWeight: "500",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "2",
      height: "30px",
      textAlign: "left",
      marginBottom: "4px",
    },
  }),
);
